import React, { useContext, useState, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../auth/AuthContext'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { ref, uploadBytes } from 'firebase/storage'
import firebase from "firebase/compat/app";
import { firestore, storage } from '../../index.js'
import { nanoid } from 'nanoid'
import { useNavigate } from 'react-router-dom'

// Pages
import ChooseRole from './pages/chooserole.jsx';

import HostLocation from './pages/host/location.jsx';
import HostListingType from './pages/host/listingType.jsx';
import HostRoomCounts from './pages/host/roomCounts.jsx';
import HostWhen from './pages/host/when.jsx';
import HostPrice from './pages/host/price.jsx';
import HostPictures from './pages/host/pictures.jsx';
import HostRoommates from './pages/host/roommates.jsx';
import HostAmenities from './pages/host/amenities.jsx';
import HostAllSet from './pages/host/allset.jsx';

import GuestLocation from './pages/guest/location.jsx';
import GuestWhen from './pages/guest/when.jsx';
import GuestPrice from './pages/guest/price.jsx';
import GuestBedrooms from './pages/guest/bedrooms.jsx';
import GuestRoommates from './pages/guest/roommates.jsx';
import GuestSharedPref from './pages/guest/sharedPref.jsx';
import GuestAllSet from './pages/guest/allset.jsx';

const FormStepsContext = React.createContext()

export function useFormSteps() {
  const context = useContext(FormStepsContext);

  if (context === undefined) {
    throw new Error('useFormSteps must be used within a FormStepsProvider');
  }

  return context;
}

export function FormStepsProvider({ children }) {
  const { currentUser, logout } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState('');

  const [furthestPageHost, setFurthestPageHost] = useState(null);
  const [furthestPageGuest, setFurthestPageGuest] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);

  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(false);
  const [navigateAway, setNavigateAway] = useState(false);

  const [exiting, setExiting] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [doneSubmitting, setDoneSubmitting] = useState(false);

  // Form criteria
  const [data, setRealData] = useState({
    host: {
      address: '',
      coordinates: {},
      listingType: '',
      prices: [],
      roomCounts: {
        roomsToRent: 1,
        roomsTotal: 1,
        bathsTotal: 1,
      },
      dates: {
        start: '',
        end: '',
      },
      pictures: [],
      roommates: {
        male: 0,
        female: 0,
        nonbinary: 0,
      },
      amenities: [],
    },
    guest: {
      region: '',
      coordinates: {},
      radius: 2,
      dates: {
        start: '',
        end: '',
      },
      price: '1000',
      bedrooms: [],
      who: '',
      roommates: {
        male: 0,
        female: 0,
        nonbinary: 0,
      },
      sharedPref: [],
      genderPref: [],
      profilePic: '',
    }
  });

  const allRoomsAreRenting = data.host.roomCounts.roomsTotal == data.host.roomCounts.roomsToRent;

  const pages = allRoomsAreRenting ?
  [
    { page: 1, component: ChooseRole },

    // Host
    { page: 2, component: HostLocation, pageUser: 'Host' },
    { page: 3, component: HostWhen, pageUser: 'Host' },
    { page: 4, component: HostListingType, pageUser: 'Host' },
    { page: 5, component: HostRoomCounts, pageUser: 'Host' },
    { page: 6, component: HostPrice, pageUser: 'Host' },
    { page: 7, component: HostPictures, pageUser: 'Host' },
    { page: 8, component: HostAmenities, pageUser: 'Host' },
    { page: 9, component: HostAllSet, pageUser: 'Host' },

    // Guest
    { page: 2, component: GuestLocation, pageUser: 'Guest' },
    { page: 3, component: GuestWhen, pageUser: 'Guest' },
    { page: 4, component: GuestPrice, pageUser: 'Guest' },
    { page: 5, component: GuestBedrooms, pageUser: 'Guest' },
    { page: 6, component: GuestRoommates, pageUser: 'Guest' },
    { page: 7, component: GuestSharedPref, pageUser: 'Guest' },
    { page: 8, component: GuestAllSet, pageUser: 'Guest' },
  ] :
  [
    { page: 1, component: ChooseRole },

    // Host
    { page: 2, component: HostLocation, pageUser: 'Host' },
    { page: 3, component: HostWhen, pageUser: 'Host' },
    { page: 4, component: HostListingType, pageUser: 'Host' },
    { page: 5, component: HostRoomCounts, pageUser: 'Host' },
    { page: 6, component: HostPrice, pageUser: 'Host' },
    { page: 7, component: HostRoommates, pageUser: 'Host' },
    { page: 8, component: HostPictures, pageUser: 'Host' },
    { page: 9, component: HostAmenities, pageUser: 'Host' },
    { page: 10, component: HostAllSet, pageUser: 'Host' },

    // Guest
    { page: 2, component: GuestLocation, pageUser: 'Guest' },
    { page: 3, component: GuestWhen, pageUser: 'Guest' },
    { page: 4, component: GuestPrice, pageUser: 'Guest' },
    { page: 5, component: GuestBedrooms, pageUser: 'Guest' },
    { page: 6, component: GuestRoommates, pageUser: 'Guest' },
    { page: 7, component: GuestSharedPref, pageUser: 'Guest' },
    { page: 8, component: GuestAllSet, pageUser: 'Guest' },
  ];

  let maxPages = -1;
  if (user == 'Host' || user == 'Guest') {
    maxPages = pages.filter(page => page.pageUser === user).length + 1;
  }

  const setData = (newData) => {
    const handleBeforeUnload = (e) => {
      e.preventDefault()
      e.returnValue = 'You have unsaved changes, are you sure you want to leave?'
    }
    window.addEventListener('beforeunload', handleBeforeUnload)

    setRealData(newData);
  }

  // DEV - Log page number
  useEffect(() => {
    console.log('Current page:', currentPage);
  }, [currentPage])

  // DEV - Log form data
  useEffect(() => {
    console.log('Local form data:', data);
  }, [data])

  // Set the form data from database if it already exists
  useEffect(() => {
    const setInitialData = async () => {
      const userRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();
      if (userData.formData) {
        const pictureURLs = await Promise.all(
          userData.formData.host.pictures.map(async (pictureURL) => {
            // Construct the file path

            // Call the Cloud Function signed URL API
            const getSignedUrl = firebase.functions().httpsCallable('getSignedUrl');
            const result = await getSignedUrl({ filePath: pictureURL });

            const url = result.data.url;
            return url;
          })
        )

        if (userData.formData.host.pictures.length > 0) {
          const urls = pictureURLs;
          // Set pictures as the paths
          userData.formData.host.pictures = urls;
        }

        if (userData.formData.guest.profilePic) {
          const profilePic = userData.formData.guest.profilePic;
          // Call the Cloud Function signed URL API
          const getSignedUrl = firebase.functions().httpsCallable('getSignedUrl');
          const result = await getSignedUrl({ filePath: profilePic });

          const profilePicUrl = result.data.url;

          // Set profile picture as the path
          userData.formData.guest.profilePic = profilePicUrl;
        }
        setRealData(userData.formData);
      }
    }
    setInitialData();
  }, []);

  useEffect(() => {
    if (currentPage > maxPages) {
      setCurrentPage(1);
    }
  }, [maxPages])

  // Set current page to furthest page
  useEffect(() => {
    const fetchData = async () => {
      const userRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();
      setFurthestPageHost(userData.furthestPageHost);
      setFurthestPageGuest(userData.furthestPageGuest);

      // Get URL parameters
      const queryParams = new URLSearchParams(location.search);
      const pageParam = Number(queryParams.get('page'));
      const userParam = queryParams.get('user');

      // If a page is specified in the URL as a parameter
      if (pageParam) {
        // If a user type is specified and the page is greater than one, it's going to be a blank page 
        // so just set the page number to 1
        if (pageParam > 1 && !(userParam != "host" || userParam != "guest")) {
          setCurrentPage(1);
        } else {
          // If a user type is specified in the URL as a parameter
          if (userParam) {
            if (userParam == "host") {
              setUser("Host");
              // Set the current page to the minimum of what the URL says and furthest they've gone
              setCurrentPage(Math.min(pageParam, userData.furthestPageHost));
            } else if (userParam == "guest") {
              setUser("Guest");
              // Set the current page to the minimum of what the URL says and furthest they've gone
              setCurrentPage(Math.min(pageParam, userData.furthestPageGuest));
            } else {
              if (pageParam > 1) {
                setCurrentPage(1);
              }
            }
          }
        }
      }
      
      // If no parameters are present in the URL, proceed normally
      if (!pageParam && !userParam) {
        if (userData.furthestPageHost > 1 &&
            userData.furthestPageGuest > 1) {
              setCurrentPage(1);
        } else if (userData.furthestPageHost > userData.furthestPageGuest) {
          setUser("Host");
          setCurrentPage(userData.furthestPageHost);
        } else if (userData.furthestPageHost < userData.furthestPageGuest) {
          setUser("Guest");
          setCurrentPage(userData.furthestPageGuest);
        } else {
          setCurrentPage(1);
        }
      }
    }
    fetchData();
  }, []);

  const unloadDirtyState = () => {
    const handleBeforeUnload = (e) => {
      e.preventDefault()
      e.returnValue = 'You have unsaved changes, are you sure you want to leave?'
    }
    console.log("Removing event listener");
    window.removeEventListener('beforeunload', handleBeforeUnload)
  }

  // Exit helper to finish exiting
  const finishExiting = () => {
    saveFormData().then(async () => {
      
      unloadDirtyState();

      const userRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();

      if (currentUser && (userData.guestFormComplete || userData.hostFormComplete)) {
        navigate("/dashboard");
      } else {
        setTimeout(() => {
          logout();
          setNavigateAway(true);
        }, 200);
      }
    });
  }

  // Exit helper in the case of picture upload
  useEffect(() => {
    if (exiting) {
      finishExiting();
    }
  }, [data]);

  async function saveFormData() {
    const userRef = doc(firestore, "users", currentUser.uid);
    console.log("Sending to firestore:", data);
    await setDoc(userRef, { formData: data }, { merge: true });
  }

  async function uploadPictures() {
    try {

      // Listing photos
      const uploadedPictures = data.host.pictures.filter(image => image !== null);
      const picturePaths = await Promise.all(
        uploadedPictures.map(async (image) => {
          console.log("Image:", image);
          if (image instanceof Blob) {
            // Construct the file path
            const filePath = `${currentUser.uid}/listing/${image.name}-${nanoid()}`;

            const pictureRef = ref(storage, filePath);
            await uploadBytes(pictureRef, image);

            return filePath;
          } else {
            const filePath = `${currentUser.uid}/listing/${image.name}`;
            console.log("File path:", filePath);
            return filePath;
          }
        })
      );

      // Guest profile picture
      const getProfilePicturePath = async () => {
        const uploadedProfilePic = data.guest.profilePic;
        if (uploadedProfilePic) {
          if (uploadedProfilePic instanceof Blob) {
            // Construct the file path
            const filePath = `${currentUser.uid}/profile-picture-${uploadedProfilePic.name}-${nanoid()}`;

            const pictureRef = ref(storage, filePath);
            await uploadBytes(pictureRef, uploadedProfilePic);

            return filePath;
          } else {
            const filePath = `${currentUser.uid}/profile-picture-${uploadedProfilePic.name}`;
            console.log("File path:", filePath);
            return filePath;
          }
        } else {
          return '';
        }
      }

      console.log("Getting path")
      const profilePicturePath = await getProfilePicturePath();

      console.log("Setting data...");
      // Set pictures as the paths
      setRealData({
        ...data,
        host: {
          ...data.host,
          pictures: picturePaths,
        },
        guest: {
          ...data.guest,
          profilePic: profilePicturePath,
        }
      });
    } catch (error) {
      console.error("An unexpected error occurred while uploading to storage:", error);
    }
  }

  async function updateFurthestPage() {
    const userRef = doc(firestore, "users", currentUser.uid);
    if (user === "Host") {
      setFurthestPageHost(currentPage);
      await setDoc(userRef, { furthestPageHost: currentPage }, { merge: true });
    } else {
      setFurthestPageGuest(currentPage);
      await setDoc(userRef, { furthestPageGuest: currentPage }, { merge: true });
    }
  }

  useEffect(() => {
    async function finish() {
      // Save the form data
      saveFormData();

      // Set form complete for user type
      const userRef = doc(firestore, "users", currentUser.uid);
      const formCompleteField = user == "Host" ? { hostFormComplete: true } : { guestFormComplete: true };
      await setDoc(userRef, formCompleteField, { merge: true });

      setTimeout(() => {

        // Unload dirty state
        unloadDirtyState();

        // Replave route with root route to restrict going back
        window.history.replaceState({}, null, "/");

        // Set done submitting
        setDoneSubmitting(true);
        setSubmitting(false);
      }, 200);
    }

    if (submitting) {
      finish();
    }
  }, [data]);

  async function submit() {

    // Set submitting to true for other components' knowledge
    setSubmitting(true);

    // Update Firestore's furthest pages entries
    updateFurthestPage();

    // Upload pictures to Firestore
    await uploadPictures();
  }

  // Exit function
  async function exit() {
    setExiting(true);
    console.log("Exiting and saving...");

    // Update furthest page
    updateFurthestPage();

    // Upload image(s) to storage if they uploaded any
    if (data.host.pictures.length > 0 || data.guest.profilePic) {
      uploadPictures();
    } else {
      finishExiting();
    }
  }

  const value = {
    currentPage,
    setCurrentPage,
    pages,
    furthestPageHost,
    canGoBack,
    setCanGoBack,
    canGoNext,
    setCanGoNext,
    user,
    setUser,
    maxPages,
    data,
    setData,
    exit,
    exiting,
    submit,
    submitting,
    setSubmitting,
    doneSubmitting,
  }

  return (
    <FormStepsContext.Provider value={value}>
      {navigateAway &&
        <Navigate to="/login"/>
      }
      {children}
    </FormStepsContext.Provider>
  )
}
