import React, { useState, useEffect } from 'react'
import { useDashboard } from '../../DashboardContext'
import { useAuth } from '../../../auth/AuthContext'

function YourProfile() {

  // Get current user and logout from auth
  const { currentUser } = useAuth();

  return (
    <>
      <div className='w-max'>
        <h2 className='font-bold text-3xl'>Your Profile</h2>
        <p className='opacity-50'>View and edit your profile</p>
      </div>
    </>
  )
}

export default YourProfile
