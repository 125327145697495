import React, { useEffect, useState } from 'react';
import { Loader } from "@googlemaps/js-api-loader";

export const useGoogleMapsApi = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      libraries: ["places"],
    });

    loader.load().then(() => {
      setLoaded(true);
    });
  }, []);

  return loaded;
};
