import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom';
import firebase from "firebase/compat/app";
import { useAuth } from './AuthContext'
import { doc, setDoc, getDoc } from "firebase/firestore"

import PhoneDesign from '@images/PhoneEmail.png'
import Logo from '@components/logo'

function useVerificationHook(codeLength) {
  let inputStates = [];
  let inputClass = 'code-digit';

  for (let i = 0; i < codeLength; i++) {
    const [digit, setDigit] = useState('');
    inputStates.push({ digit, setDigit });
  }

  const resetInputStates = () => {
    inputStates.forEach(inputState => {
      inputState.setDigit('');
    });
    let prevInput = document.querySelectorAll(`.${inputClass}`)[0];
    prevInput.focus();
  }

  const handleChange = (e, index) => {
    let entry = e.target.value;

    if (entry.length <= 1 && !Number.isNaN(entry)) {
      inputStates[index].setDigit(e.target.value);

      if (entry.length === 1) {
        if (index < codeLength - 1) {
          let nextInput = document.querySelectorAll(`.${inputClass}`)[index + 1];
          if (nextInput.value === '') nextInput.focus();
        }
      } else if (entry.length === 0) {
        let prevInput = document.querySelectorAll(`.${inputClass}`)[index - 1];

        if (prevInput !== undefined) prevInput.focus();
      }
    } else return;
  };

  const handleKeyDown = (e, index, submitting) => {
    if (!submitting) {
      if (e.key.match(/^[a-zA-Z]$/) && !(e.key === 'v' && (e.ctrlKey || e.metaKey))) {
        e.preventDefault();
      } else if (e.key === 'Backspace') {
        if (index > 0 && e.target.value === '') {
          let prevInput = document.querySelectorAll(`.${inputClass}`)[index - 1];
          prevInput.focus();
        }
      } else if (e.key.match(/^\d$/)) {
        if (e.target.value !== '' && index < codeLength - 1) {
          let nextInput = document.querySelectorAll(`.${inputClass}`)[index + 1];
          nextInput.focus();
        }
      }
    } else {
      e.preventDefault();
    }
  };

  function handlePaste(e) {
    e.preventDefault(); // Prevent the default paste action
    const pastedText = e.clipboardData.getData('Text');
    // Check if the pasted text is a string of numbers with length equal to codeLength
    if (pastedText.length === codeLength && /^\d+$/.test(pastedText)) {
      // Split the pastedText into an array of individual characters
      const pastedDigits = pastedText.split('');

      // Set each inputState's value to the corresponding digit from the pastedDigits array
      pastedDigits.forEach((digit, index) => {
        inputStates[index].setDigit(digit);
      });

      let lastInput = document.querySelectorAll(`.${inputClass}`)[inputStates.length - 1];
      lastInput.focus();
    }
  }

  return { inputStates, resetInputStates, handleChange, handleKeyDown, handlePaste, inputClass };
}

function VerifyEmail({ firestore }) {

  const { currentUser, getValidation } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [invalidPin, setInvalidPin] = useState(false);
  const [verified, setVerified] = useState(null);
  const [pin, setPin] = useState('');
  const [userRef, setUserRef] = useState(null);

  const { inputStates, resetInputStates, handleChange, handleKeyDown, handlePaste, inputClass } = useVerificationHook(6);

  const [userData, setUserData] = useState({});

  useEffect(() => {
    async function sendVerificationEmail() {
      // Call the Cloud Function signed URL API
      const sendEmail = firebase.functions().httpsCallable('sendVerificationEmailHTTPS');
      const result = await sendEmail({
        name: userData.name,
        email: userData.email,
        pin: userData.verificationPin,
      });
      const status = result.status;
    }
    if (userData.name != null) {
      sendVerificationEmail();
    }
  }, [userData]);

  useEffect(() => {
    async function getUserData() {
      // See if pin matches
      const newUserRef = doc(firestore, "users", currentUser.uid);
      setUserRef(newUserRef);
      const userDoc = await getDoc(newUserRef);
      setUserData(userDoc.data());
    }

    async function checkValidation(uid) {
      const result = await getValidation(uid);
      setVerified(result);
    }

    getUserData();
    checkValidation(currentUser.uid);
  }, []);

  useEffect(() => {

    // Parse pin
    let newPin = '';
    inputStates.forEach((digit) => {
      newPin = newPin + digit["digit"];
    });

    // If pin's length is 6, submit
    if (!submitting) {
      if (newPin.length == 6) {
        setPin(newPin.toString());
        handleSubmit();
      }
    }
  }, [inputStates]);

  useEffect(() => {
    if (submitting && pin) {
      setTimeout(async () => {

        console.log("Pin:", pin);
        console.log("Verification pin:", userData.verificationPin);
        if (pin == userData.verificationPin) {
          console.log("Setting doc...")
          // Update flag on user's database
          setDoc(userRef, { verified: true }, { merge: true });
          console.log("Doc set")

          console.log("Setting verified to true...");
          setVerified(true);
          console.log("Setting verified to true...");
        } else {
          console.log("Pin does not match")
          setInvalidPin(true);
          resetInputStates();
        }

        setSubmitting(false);
      }, 400);
    }
  }, [submitting, pin]);

  const handleSubmit = async () => {
    // Set submitting to true
    setSubmitting(true);
  };

  return (
    <>

      {/* Navigate on log in after submit OR already logged in */}
      {(verified) ? <Navigate to="/setupaccount"/> : <></>}

      {verified != null &&
        <div className='w-screen h-screen flex flex-row'>
          {/* Left */}
          <div className="w-full lg:w-1/2 h-full p-10">

            {/* Logo */}
            <a className='absolute' href="/">
              <Logo textSize="text-2xl"
                    uColor="text-white"
                    bgWidth={9}/>
            </a>

            {/* Login content */}
            <div className='w-full h-full justify-center flex flex-col lg:px-16 py-24 gap-2 items-center'>

              {/* Welcome Back */}
              <div className="relative flex flex-col gap-4 justify-center p-2 text-center items-center">
                <h2 className="font-medium text-3xl">Verify your email address</h2>
                <div>
                  <p className='opacity-50'>Enter the 6-digit pin sent to</p>
                  <p className='font-semibold opacity-50'>{currentUser.email}</p>
                  <p className='opacity-50'>to create your account</p>
                </div>
              </div>

              <form className="w-full flex justify-center">
                {/* Inputs */}
                <div className='w-full sm:w-[600px] lg:max-w-[450px] py-4 px-4 sm:px-16 md:px-20 lg:px-4 flex flex-col gap-6'>

                  <div className={`w-full flex ${submitting && "opacity-50"} justify-center`}>
                    {inputStates.map((state, index) => (
                      <input
                        key={index}
                        type="number"
                        className={`code-digit w-16 h-24 mx-2 text-4xl text-center shadow-hostU-shadow-figma border-2 rounded-full border-gray-300 focus:outline-none ${inputClass}`}
                        maxLength="1"
                        value={state.digit}
                        onChange={(e) => handleChange(e, index, submitting)}
                        onKeyDown={(e) => handleKeyDown(e, index, submitting)}
                        onPaste={(e) => handlePaste(e)}
                        onFocus={(e) => e.target.style.borderColor = '#4BB6E3'}
                        onBlur={(e) => e.target.style.borderColor = 'rgb(209, 213, 219)'}
                      />
                    ))}
                  </div>
                </div>
              </form>
              {invalidPin &&
                <p className='text-red-500 w-full text-center'>Invalid pin. Please try again.</p>
              }
            </div>
          </div>
          {/* Already have an account */}
          <div className='w-full lg:w-1/2 text-center py-8 absolute bottom-0 mx-auto'>
            <p>Didn't get it? <button className="opacity-100 font-bold">Resend email</button></p>
          </div>

          {/* Right */}
          <div className="w-1/2 h-full pointer-events-none lg:flex hidden lg:fixed right-0 items-center bg-hostU-blue-400">
            <div className="px-10 m-auto">
              <img src={PhoneDesign} className="mx-auto w-[20rem]" alt="House" />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default VerifyEmail
