import React, { useEffect, useState } from 'react'
import { useFormSteps } from '../formStepsContext'

function ChooseRule(props) {
  const { currentPage, setCanGoNext, user, setUser } = useFormSteps()

  const { page } = props;

  useEffect(() => {
    if (currentPage == page) {
      if (user == "Host" || user == "Guest") {
        setCanGoNext(true);
      }
    }
  });

  function handleChoice(selectedChoice) {
    setUser(selectedChoice);
    setCanGoNext(true);
  }

  return (
    <>
      <div className='w-screen h-screen flex flex-col justify-center sm:flex-row'>
        {/* Left */}
        <button
          onClick={() => handleChoice("Host")}
          className={`w-full ${currentPage > 1 && "pointer-events-none"} sm:w-1/2 h-full ${user == "Host" ? "bg-hostU-blue-400 text-white" : "bg-white text-hostU-blue-400" } flex flex-col ${user == "Host" ? "text-[4.5rem]" : "text-[4rem]"} justify-center items-center hover:shadow-hostU-shadow transition-all duration-400 z-10 hover:z-20`}
        >
          <i className='text-[2rem] fa-solid fa-house'></i>
          <h3 className='font-bold'>Host</h3>
          <p className='opacity-75 text-[1rem]'>List your place and get cash</p>
        </button>

        {/* Right */}
        <button
          onClick={() => handleChoice("Guest")}
          className={`w-full ${currentPage > 1 && "pointer-events-none"} sm:w-1/2 h-full ${user == "Guest" ? "bg-hostU-green-400 text-white" : "bg-white text-hostU-green-400" } flex flex-col ${user == "Guest" ? "text-[4.5rem]" : "text-[4rem]"} justify-center items-center hover:shadow-hostU-shadow transition-all duration-400 z-10 hover:z-20`}
        >
          <i className='text-[2rem] fa-solid fa-user'></i>
          <h3 className='font-bold'>Guest</h3>
          <p className='opacity-75 text-[1rem]'>Rent out a verified student's place</p>
        </button>
      </div>
    </>
  )
}

export default ChooseRule
