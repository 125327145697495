import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from '@components/auth/AuthContext'

import LandingNavbar from '@components/landing/navbar'
import Footer from '@components/footer'
import Hero from '@components/landing/hero'
import Map from '@components/landing/map'
import Benefits from '@components/landing/benefits'
import StudentCounter from '@components/landing/studentCounter'
import LocationBanner from '@components/landing/locationBanner'
import Testimonials from '@components/landing/testimonials'
import FAQ from '@components/landing/faq'
import Terms from '@components/terms'
import Policy from '@components/policy'
import Logo from '@components/logo'
import Maintenance from '@components/maintenance/maintenance'
import MaintenanceNavbar from '@components/maintenance/navbar'

import ContactRequested from '@components/contactRequested'
import HostDecision from '@components/hostDecision'
import MatchImages from '@components/matchImages'

import Login from '@components/auth/login'
import VerifyEmail from '@components/auth/verifyEmail'
import SignUp from '@components/auth/signup'
import ForgotPassword from '@components/auth/forgotPassword'
import PrivateRoute from '@components/auth/PrivateRoute'

import { FormStepsProvider } from '@components/form/formStepsContext';
import { DashboardProvider } from '@components/dashboard/DashboardContext';

import Form from '@components/form/form'
import Dashboard from '@components/dashboard/dashboard'

// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator, collection, getDocs } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getAuth, connectAuthEmulator } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCsHuct0Y4E9yHw87rR_judc5tpc8DVpvQ",
  authDomain: "old.joinhostu.com",
  projectId: "hostu-b9b70",
  storageBucket: "hostu-b9b70.appspot.com",
  messagingSenderId: "1080444463105",
  appId: "1:1080444463105:web:f225ed446603c4eb523800",
  measurementId: "G-H7P8TW2936",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

// Configure Firebase products for emulator
const analytics = getAnalytics(app);
const database = getDatabase();
export const storage = getStorage(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);

// Set up emulators
if (window.location.hostname === "localhost") {

  // Functins
  firebase.functions().useEmulator("localhost", 5001);

  // Firestore
  connectFirestoreEmulator(firestore, "localhost", 8080);

  // Database
  connectDatabaseEmulator(database, "localhost", 9000);

  // Storage
  connectStorageEmulator(storage, "localhost", 9199);

  // Auth
  connectAuthEmulator(auth, "http://localhost:9099");

  // Give development warning
  if (!sessionStorage.getItem("givenWarning")) {
    alert(
      "Initializing in emulator mode. If you aren't a developer, contact support@joinhostu.com immediately."
    );
    sessionStorage.setItem("givenWarning", "true");
  }
}

// hostU under maintenance
const maintenance = false;

// Show announcement on top
const announcement = true;

function Stack() {
  const hostguestRef = useRef(null);
  const scrollToSection = () => {
    hostguestRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // How much to increment user counter by locally
  const [counterIncrement, setCounterIncrement] = useState(0);

  // Transit Matches Data
  const [transitMatchesData, setTransitMatchesData] = useState([]);

  // Continued Matches Data
  const [continuedMatchesData, setContinuedMatchesData] = useState([]);

  // useEffect(() => {

  //   // Get match data
  //   const fetchMatchesData = async () => {
  //     // Transit matches
  //     const transitMatches = [];
  //     const transitQuerySnapshot = await getDocs(collection(firestore, "transitMatches"));
  //     transitQuerySnapshot.forEach((doc) => {
  //       const data = doc.data();

  //       // Only extract first initial of last names
  //       const hostNameWords = data.host_data.name.split(' ');
  //       const hostNameStripped = hostNameWords[0] + ' ' + hostNameWords[hostNameWords.length - 1].charAt(0)  + '.';
  //       const guestNameWords = data.guest_data.name.split(' ');
  //       const guestNameStripped = guestNameWords[0];

  //       // Add data to matches
  //       transitMatches.push({
  //         ...data,
  //         hostsName: hostNameStripped,
  //         guestsName: guestNameStripped,
  //       });
  //     });
  //     setTransitMatchesData(transitMatches);

  //     // Continued matches
  //     const continuedMatches = [];
  //     const continuedQuerySnapshot = await getDocs(collection(firestore, "continuedMatches"));
  //     continuedQuerySnapshot.forEach((doc) => {
  //       const data = doc.data();

  //       // Only extract first initial of last names
  //       const hostNameWords = data.host_data.name.split(' ');
  //       const hostNameStripped = hostNameWords[0] + ' ' + hostNameWords[hostNameWords.length - 1].charAt(0)  + '.';
  //       const guestNameWords = data.guest_data.name.split(' ');
  //       const guestNameStripped = guestNameWords[0];

  //       // Add data to matches
  //       continuedMatches.push({
  //         ...data,
  //         hostsName: hostNameStripped,
  //         guestsName: guestNameStripped,
  //       });
  //     });
  //     setContinuedMatchesData(continuedMatches);
  //   };
  //   fetchMatchesData();
  // }, []);

  return (
    <div>
      <AuthProvider>

        <Router>
          <Routes>

            {!maintenance ? (
              <>
                <Route path="/" element={
                  <div className="bg-white overflow-y-hidden">
                    <LandingNavbar/>
                    <Hero scrollToSection={scrollToSection}/>
                    {/* <HostGuest firebase={firebase}
                              firestore={firestore}
                              storage={storage}
                              database={database}
                              ref={hostguestRef}
                              incrementCounter={() => (setCounterIncrement(counterIncrement + 1))}/> */}
                    {/* <LocationBanner database={database}/> */}
                    <Benefits/>
                    <Map/>
                    {/* <StudentCounter database={database}
                                    counterIncrement={counterIncrement}/> */}
                    <Testimonials/>
                    <FAQ/>
                    <Footer scrollToSection={scrollToSection}/>
                  </div>
                }/>

                {/* Login path */}
                <Route path="/login" element={
                  <Login firestore={firestore}/>
                }/>
                  <Route path="/forgotpassword" element={
                    <ForgotPassword/>
                  }/>

                {/* Signup path */}
                <Route path="/signup" element={
                  <SignUp firestore={firestore}/>
                }/>
                <Route path="/verifyemail" element={
                  <PrivateRoute>
                    <VerifyEmail firestore={firestore}/>
                  </PrivateRoute>
                }/>

                {/* Form path */}
                  <Route path="/setupaccount" element={
                    <FormStepsProvider>
                      <PrivateRoute>
                        <Form firestore={firestore}/>
                      </PrivateRoute>
                    </FormStepsProvider>
                  }/>

                {/* Dashboard */}
                <Route path="/dashboard" element={
                  <DashboardProvider>
                    <PrivateRoute>
                      <Dashboard auth={auth}/>
                    </PrivateRoute>
                  </DashboardProvider>
                }/>

                {/* Terms & Policy */}
                <Route path="/terms" element={
                  <div>
                    <LandingNavbar/>
                    <Terms/>
                    <Footer/>
                  </div>
                }/>
                <Route path="/policy" element={
                  <div>
                    <LandingNavbar/>
                    <Policy/>
                    <Footer/>
                  </div>
                }/>

                {/* Matches Routes */}
                {/* {transitMatchesData.map((match) => (
                  <>
                    <Route
                      key={`${match.host_id}-matching-list-route`}
                      path={`/${[match.host_id, match.guest_id].join("&")}`}
                      element={
                        <div>
                          <MatchImages
                            oppositeUID={match.host_id}
                            firebase={firebase}
                            database={database}
                            guestName={match.guest_data ? match.guest_data.name : "Loading..."}
                            address={match.host_data ? match.host_data.address : "Loading..."}
                            hostCoords={match.host_data ? match.host_data.coordinates : "Loading..."}
                            hostName={match.host_data ? match.host_data.name : "Loading..."}
                            imageLinks={match.host_data ? match.host_data.images : "Loading..."}
                            hostSelfie={match.host_data ? match.host_data.selfie : "Loading..."}
                            guestSelfie={match.guest_data ? match.guest_data.selfie : "Loading..."}
                            path={`/${[match.host_id, match.guest_id].join("&")}`}
                            hostsData={match.host_data}
                          />
                        </div>
                      }
                    />
                    <Route
                      key={`${match.host_id}-request-route`}
                      path={`/${match.host_id}&${match.guest_id}/request`}
                      element={
                        <div>
                          <ContactRequested
                            database={database}
                            hostsId={match.host_id}
                            guestsId={match.guest_id}
                          />
                          <Footer />
                        </div>
                      }
                    />
                  </>
                ))} */}

                {/* Matches Routes */}
                {/* {continuedMatchesData.map((match) => (
                  <>
                    <Route
                      key={`${match.host_id}-accept-route`}
                      path={`/${match.host_id}&${match.guest_id}/accept`}
                      element={
                        <div>
                          <HostDecision
                            accept
                            database={database}
                            hostsId={match.host_id}
                            guestsId={match.guest_id}/>
                          <Footer />
                        </div>
                      }
                    />
                    <Route
                      key={`${match.host_id}-deny-route`}
                      path={`/${match.host_id}&${match.guest_id}/deny`}
                      element={
                        <div>
                          <HostDecision
                            deny
                            database={database}
                            hostsId={match.host_id}
                            guestsId={match.guest_id}/>
                          <Footer />
                        </div>
                      }
                    />
                  </>
                ))} */}
              </>
            ) : (
              <>
                {/* Maintenance */}
                <Route path="/" element={
                  <div className="overflow-y-hidden">
                    <MaintenanceNavbar/>
                    <Maintenance/>
                  </div>
                }/>
              </>
            )
            }
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <Stack/> );
