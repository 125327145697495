import React, { useContext, useState, useEffect } from 'react'
import { doc, getDoc } from "firebase/firestore"
import { auth, firestore } from '../../index.js'
import { Navigate } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth';

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    signOut(auth);

    return setCurrentUser(null);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  async function getValidation(uid) {
    const userRef = doc(firestore, "users", uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      console.warning("User document not found");
      return false;
    }

    const userData = userDoc.data();

    if (!userData) {
      console.warning("User data not found");
      return false;
    }

    if (userData.verified) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    getValidation
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
