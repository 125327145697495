import React, { useEffect, useState } from 'react'
import { useFormSteps } from '../../formStepsContext'

function Roommates(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data, setData } = useFormSteps();
  const { page } = props;

  const initialRoommateDetails = [
    {
      id: 'male',
      label: 'Male',
      value: data.guest.roommates.male,
    },
    {
      id: 'female',
      label: 'Female',
      value: data.guest.roommates.female,
    },
    {
      id: 'nonbinary',
      label: 'Non-binary',
      value: data.guest.roommates.nonbinary,
    },
  ];

  const genderOptions = ["Female", "Male", "Non-binary"];
  const [gender, setGender] = useState('');

  const peopleDetails = [
    {
      name: "Just Myself",
    },
    {
      name: "Myself & Others",
    }
  ];

  const [roommateDetails, setRoommateDetails] = useState(initialRoommateDetails);
  const [who, setWho] = useState(null);

  // If fetched from firestore, update it
  useEffect(() => {
    if (data.guest.roommates && data.guest.who) {
      setRoommateDetails(initialRoommateDetails);
      setCanGoNext(true);
      setWho(data.guest.who);
    }
  }, [data]);

  // Re-enable buttons on component mount
  useEffect(() => {
    if (currentPage == page) {
      setCanGoBack(true);
    }
  }, []);

  // Update form data
  const updateData = (updatedRoommateDetails) => {
    const newRoommateCounts = updatedRoommateDetails.reduce((acc, item) => {
      return { ...acc, [item.id]: item.value };
    }, {});

    setData({
      ...data,
      guest: {
        ...data.guest,
        roommates: newRoommateCounts,
      },
    });
  };

  // Add 1 to value if + or subtract 1 to value if -
  const handleOperation = (id, operation) => {
    setCanGoNext(true);
    const updatedRoommateDetails = roommateDetails.map(item => {
      if (item.id === id) {
        let newValue = operation === '+' ? item.value + 1 : item.value - 1;
        newValue = newValue < 0 ? 0 : newValue;
        return { ...item, value: newValue };
      }
      return item;
    });
    setRoommateDetails(updatedRoommateDetails);
    updateData(updatedRoommateDetails);
  };

  // Render buttons
  const OperationButton = ({ id, operation }) => {
    return (
      <>
        <button
          className='rounded-full w-8 h-8 border border-hostU-green-400'
          onClick={() => handleOperation(id, operation)}
        >
          <p className='pointer-events-none -translate-y-[1px]'>{operation}</p>
        </button>
      </>
    )
  }

  // Enable next button depending on
  useEffect(() => {
    if (who == "Myself & Others") {
      if (initialRoommateDetails.reduce((acc, detail) => acc + detail["value"], 0) > 1) {
        setCanGoNext(true);
      } else {
        setCanGoNext(false);
      }
    }
  }, [who, roommateDetails]);

  const handleChange = (type) => {
    setWho(type);
    setData({
      ...data,
      guest: {
        ...data.guest,
        who: type,
      },
    });
    setCanGoNext(true);
  };

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center sm:justify-center justify-start pt-[80px] sm:pt-0 pb-[700px] sm:pb-0'>
        <div className='w-full sm:w-[600px] pt-10 px-5 sm:px-0'>
          <div className='w-full text-left py-0'>
            <h2 className='text-[2rem] my-0 py-2 leading-tight font-medium'>Who is looking to rent?</h2>
            <p className='opacity-50 pb-3'>If you're looking to rent with others, please disclose everyone's genders (including yourself)</p>
          </div>
          <div className='w-full py-5 flex flex-col text-xl gap-2 sm:flex-row'>
            {peopleDetails.map((type) => {
              return (
                <button
                  className={`w-full sm:w-1/2 flex flex-col justify-center items-center py-5 gap-2 rounded-full ${who == type["name"] ? "bg-hostU-green-400 text-white font-semibold" : "bg-white border-2 border-opacity-50"} transition duration-400`}
                  onClick={() => handleChange(type["name"])}
                  key={type["name"]}
                >
                  <p>{type["name"]}</p>
                </button>
              )
            })}
          </div>
          {who == "Myself & Others" &&
            <div className='w-full flex pb-20 sm:pb-0 flex-col text-xl'>
              {roommateDetails.map(item => (
                <div key={item.id} className='flex flex-row justify-between py-8 border-b border-black border-opacity-10 w-full items-center'>
                  <p>{item.label}</p>
                  <div className='flex flex-row gap-2 items-center'>
                    <OperationButton
                      id={item.id}
                      operation="-"
                    />
                    <p className='w-8 text-center'>{item.value}</p>
                    <OperationButton
                      id={item.id}
                      operation="+"
                    />
                  </div>
                </div>
              ))}
            </div>
          }
          {who == "Just Myself" &&
            <>
              <div className='w-full text-left py-0'>
                <h2 className='text-[2rem] my-0 py-2 leading-tight font-medium'>What is your gender?</h2>
                <p className='opacity-50 pb-3'>Hosts value this information for accomodation</p>
              </div>
              <div className='w-full py-5 flex flex-col text-xl gap-2 sm:flex-row'>
                {genderOptions.map((option, index) => {
                  return (
                    <button
                      className={`w-full sm:w-1/2 flex flex-col justify-center items-center py-5 gap-2 rounded-full ${gender == option ? "bg-hostU-green-400 text-white font-semibold" : "bg-white border-2 border-opacity-50"} transition duration-400`}
                      onClick={() => setGender(option)}
                      key={index}
                    >
                      <p>{option}</p>
                    </button>
                  )
                })}
              </div>
            </>
          }
        </div>
      </div>
    </>
  )
}

export default Roommates
