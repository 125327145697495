import React from 'react';
import Navbar from './navbar';
import Buttons from './navigationButtons';
import { useFormSteps } from './formStepsContext';
import { AnimatePresence, motion } from 'framer-motion';

function Form() {

  const { currentPage, user, exiting, submitting, pages } = useFormSteps();

  return (
    <>
      <Navbar />

      <div className={`${(exiting || submitting) && "pointer-events-none"} relative`}>
        <AnimatePresence mode="wait">
          {pages.map(({ page, component: Component, pageUser }) => {
            const shouldShow = currentPage === page && (!pageUser || user === pageUser);

            return (
              shouldShow && (
                <motion.div
                  key={page}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.35 }}
                  className="absolute inset-0"
                >
                  <Component page={page}/>
                </motion.div>
              )
            );
          })}
        </AnimatePresence>
      </div>

      <Buttons />
    </>
  );
}

export default Form;
