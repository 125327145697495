import React, { useEffect, useState, useRef } from 'react'
import { useFormSteps } from '../../formStepsContext'
import GoogleMapReact from 'google-map-react';
import axios from 'axios';
import { useGoogleMapsApi } from "../../../GoogleMaps"

function Location(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data, setData } = useFormSteps()
  const { page } = props;
  const [address, setAddress] = useState(data.host.address || '');
  const [validAddress, setValidAddress] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: 41.8781,
    lng: -87.6298,
  });

  // Google maps hook
  const gmapsHostRef = useRef();
  const loaded = useGoogleMapsApi();

  useEffect(() => {
    // Re-enable the back button
    if (currentPage == page) {
      setCanGoBack(true);
    }
    // If the page just loaded and nothing has been interacted with,
    // disable the next button
    if (currentPage == page && !address) {
      setCanGoNext(false);
      console.log("Next disabled")
    }
    // If the user has already clicked a valid address from the dropdown
    // but they may have changed the address after, make sure it's valid still
    // and then enable the next button
    else if (currentPage == page && data.host.address && validAddress) {
      setCanGoNext(true);
    }
  });

  // If fetched from firestore, update it
  useEffect(() => {
    if (data.host.address && data.host.address != '') {
      setAddress(data.host.address);
      setCanGoNext(true);
      setValidAddress(true);
      const updateCoordinates = async () => {
        setCoordinates(await getCoordinates(data.host.address));
      }
      updateCoordinates();
    }
  }, [data]);

  // Out of territory state
  const [oot, setOot] = useState(false);

  // Handle input change
  const handleInputChange = (e) => {
    setAddress(e.target.value);
    setCanGoNext(false);
    setValidAddress(false);
  };

  // Geocode coordinates
  const getCoordinates = async (address) => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    const geocodeResponse = await axios.get(geocodeUrl);
    const location = geocodeResponse.data.results[0].geometry.location;
    return {
      lat: location.lat,
      lng: location.lng,
    };
  };

  useEffect(() => {
    if (loaded && gmapsHostRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(gmapsHostRef.current, {
        types: ['address'],
        componentRestrictions: { country: 'us' },
      });

      autocomplete.addListener('place_changed', async () => {
        const place = autocomplete.getPlace();

        // The rest of your onPlaceSelected callback goes here...
        if (!place.formatted_address.includes(', IL')) {
          setOot(true);
        } else {
          setOot(false);

          // Get coordinates
          const coordinates =  await getCoordinates(place.formatted_address);
          setCoordinates(coordinates);

          // Set address
          setAddress(place.formatted_address);

          // Set form data
          setData({
            ...data,
            host: {
              ...data.host,
              address: place.formatted_address,
              coordinates: {
                lat: coordinates.lat,
                lng: coordinates.lng,
              },
            },
          });

          // Enable next button
          setCanGoNext(true);
          setValidAddress(true);
        }
      });
    }
  }, [loaded])

  const MapPin = ({ className, alt }) => (
      <svg
      className={className}
      viewBox="0 0 173 266"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      <path d="M172.368 86.184C172.368 38.5859 133.782 0 86.184 0C38.5859 0 0 38.5859 0 86.184C0 133.782 38.5859 172.368 86.184 172.368C133.782 172.368 172.368 133.782 172.368 86.184Z" fill="currentColor"/>
      <path d="M87.913 266C87.913 200.697 0 152.817 0 86.8491H87.913V266Z" fill="currentColor"/>
      <path d="M88.0462 266C88.0462 200.648 172.368 152.733 172.368 86.7158H85.3862L88.0462 266Z" fill="currentColor"/>
      <g clipPath="url(#clip0_140_11)">
      <path d="M124.973 85.4326C124.973 87.8584 122.942 89.7586 120.64 89.7586H116.306L116.401 111.348C116.401 111.712 116.374 112.076 116.333 112.44V114.609C116.333 117.588 113.909 120 110.917 120H108.75C108.601 120 108.452 120 108.303 119.987C108.114 120 107.924 120 107.734 120H103.333H100.083C97.0906 120 94.6667 117.588 94.6667 114.609V111.375V102.75C94.6667 100.365 92.7302 98.4375 90.3333 98.4375H81.6667C79.2698 98.4375 77.3333 100.365 77.3333 102.75V111.375V114.609C77.3333 117.588 74.9094 120 71.9167 120H68.6667H64.3469C64.1438 120 63.9406 119.987 63.7375 119.973C63.575 119.987 63.4125 120 63.25 120H61.0833C58.0906 120 55.6667 117.588 55.6667 114.609V99.5156C55.6667 99.3943 55.6667 99.2596 55.6802 99.1383V89.7586H51.3333C48.8958 89.7586 47 87.8719 47 85.4326C47 84.2197 47.4063 83.1416 48.3542 82.1982L83.075 52.0781C84.0229 51.1348 85.1063 51 86.0542 51C87.0021 51 88.0854 51.2695 88.8979 51.9434L123.483 82.1982C124.567 83.1416 125.108 84.2197 124.973 85.4326Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_140_11">
      <rect width="78" height="69" fill="white" transform="translate(47 51)"/>
      </clipPath>
      </defs>
    </svg>
  )

  const Pin = () => (
    <div style={{
      color: 'white',
      display: 'inline-flex',
      justifyContent: 'center',
      top: '0',
      borderRadius: '100%',
      transform: 'translate(-50%, -100%)',
    }}>
      <MapPin className="text-hostU-blue-400 w-8" alt="Pin"/>
    </div>
  );

  const mapProps = {
    center: {
      lat: coordinates.lat,
      lng: coordinates.lng,
    },
    zoom: 11
  };

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center sm:justify-center justify-start pt-[80px] sm:pt-0 mb-[300px]'>
        <div className='w-full sm:w-[600px] pt-10 px-5 sm:px-0'>
          <div className='w-full py-2 text-left'>
            <h2 className='text-[2rem] my-0 py-0 font-medium'>Where is your listing?</h2>
            <p className='opacity-50 pb-3'>Your address will only be shared after both parties request contact information</p>
          </div>
          <div className='w-full h-[375px] rounded-3xl flex items-center justify-center relative overflow-hidden'>
            <div className='absolute flex w-[90%] top-4 bg-white z-20 shadow-hostU-shadow-small px-5 py-1 rounded-3xl justify-center'>
              <input
                className="w-full h-full px-2 py-4 outline-none border-0"
                placeholder="Enter an address"
                value={address}
                onChange={handleInputChange}
                ref={gmapsHostRef}
              />
            </div>
            <div className='hidden sm:flex w-full h-full'>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                  libraries: ['places'],
                }}
                center={mapProps.center}
                defaultZoom={mapProps.zoom}
                options={{
                  fullscreenControl: false,
                }}
              >
                <Pin
                  lat={mapProps.center.lat}
                  lng={mapProps.center.lng}
                />
              </GoogleMapReact>
            </div>
          </div>
          {oot &&
            <div className='w-full text-center py-2'>
              <p className='text-red-500'>We are currently only serving greater Chicago</p>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default Location
