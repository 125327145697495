import React, { useEffect } from 'react'
import Logo from '@components/logo'
import { useDashboard } from './DashboardContext.jsx'

function Sidebar() {

  const { currentTab, setCurrentTab, tabs } = useDashboard();

  return (
    <div className='w-full h-full relative z-50'>

      {/* U by hostU */}
      <a
        className='w-max mx-auto bg-white flex mt-10 mb-5 justify-center'
        href="/"
      >
        <Logo textSize="text-3xl"
              uColor="text-white"
              bgWidth={12}/>
        {/* <div className='w-[12rem] p-5 m-5 relative'>
          <div className="w-20 h-20 flex justify-center items-center rounded-[50%] bg-hostU-blue-gradient">
            <p className="text-white font-black text-5xl">U</p>
          </div>
          <div>
            <p className='translate-x-20 font-bold absolute bottom-0'>by hostU</p>
          </div>
        </div> */}
      </a>

      <div className='w-full flex flex-col pt-4 gap-4 px-5'>
        {tabs && currentTab && tabs.map((tab, index) => (
          <div key={index}>
            {index == tabs.length - 1 &&
              <hr className='pb-3'/>
            }
            <button
              className={`${currentTab["name"] == tab["name"] ? "font-semibold text-black" : "text-black text-opacity-40"}
                flex flex-row gap-2 items-center transition duration-400 text-left px-4 py-3 rounded-full`}
              onClick={() => setCurrentTab(tab)}
            >
              <div className='w-[25px]'>
                <i className={`fa-solid ${tab["icon"]}`}></i>
              </div>
              <p>{tab["name"]}</p>
            </button>
          </div>
        ))}
      </div>

      <div className='rounded-2xl flex flex-col text-center gap-5 bg-gray-100 absolute bottom-0 m-5 pt-8 pb-5 px-5'>
        <p className='opacity-75'>Having problems? Get in touch with our customer support</p>
        <a
          className='font-semibold px-4 py-2 rounded-full shadow-hostU-shadow-figma bg-white'
          href="mailto:support@joinhostu.com"
        >
          <p>Contact Us</p>
        </a>
      </div>
    </div>
  )
}

export default Sidebar
