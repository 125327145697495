import React, { useEffect, useState } from 'react'
import { useFormSteps } from '../../formStepsContext'
import { ImSpinner8 } from 'react-icons/im'
import dayjs from 'dayjs'
import { useGoogleMapsApi } from "../../../GoogleMaps";

function AllSet(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data } = useFormSteps()
  const { page } = props;
  const [loadingFromFirebase, setLoadingFromFirebase] = useState(false);

  const [coverPhoto, setCoverPhoto] = useState(data.host.pictures[0] || null);

  const [neighborhood, setNeighborhood] = useState('');

  const [trueAmenities, setTrueAmenities] = useState('')
  const [lowestPrice, setLowestPrice] = useState('');
  const [lowestPriceRoomDuration, setLowestPriceRoomDuration] = useState('');

  useEffect(() => {
    // Re-enable the back button
    if (currentPage == page) {
      setCanGoBack(true);
    }
  });

  // Extract file name from signed URL
  function extractFilenameFromUrl(url) {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    return decodeURIComponent(filename).split("?")[0];
  }

  const [listingDetails, setListingDetails] = useState([
    {
      title: "Listing",
      address: data.host.address,
      renting: data.host.roomCounts.roomsToRent,
      total: data.host.roomCounts.roomsTotal,
      bathrooms: data.host.roomCounts.bathsTotal,
      amenities: data.host.amenities,
      icon: "fa-house",
    },
    {
      title: "Stay",
      prices: data.host.prices,
      start: '',
      end: '',
      otherIndividuals: Object.values(data.host.roommates).reduce((acc, value) => acc + value, 0),
      icon: "fa-money-bill-wave",
    },
  ]);

  // Fetch existing images from signed URLs
  useEffect(() => {

    if (currentPage == page) {

      // Update listing details, so create a copy of the current
      // listingDetails state
      if (data.host) {
        let newListingDetails = [...listingDetails];

        newListingDetails[0].address = data.host.address;
        newListingDetails[0].renting = data.host.roomCounts.roomsToRent;
        newListingDetails[0].total = data.host.roomCounts.roomsTotal;
        newListingDetails[0].bathrooms = data.host.roomCounts.bathsTotal;
        newListingDetails[0].amenities = data.host.amenities;

        newListingDetails[1].prices = data.host.prices
        newListingDetails[1].otherIndividuals = Object.values(data.host.roommates).reduce((acc, value) => acc + value, 0)

        // Check if start date exists and format it
        if (data.host.dates.start) {
          let startDate;
          if (data.host.dates.start instanceof Date) {
            startDate = dayjs(data.host.dates.start).format('MMM D, YYYY');
          } else {
            startDate = dayjs(new Date(data.host.dates.start.toDate())).format('MMM D, YYYY');
          }

          // Set the start date in the copy of listingDetails
          newListingDetails[1].start = startDate;
        }

        // Check if end date exists and format it
        if (data.host.dates.end) {
          let endDate;
          if (data.host.dates.end instanceof Date) {
            endDate = dayjs(data.host.dates.end).format('MMM D, YYYY');
          } else {
            endDate = dayjs(new Date(data.host.dates.end.toDate())).format('MMM D, YYYY');
          }

          // Set the end date in the copy of listingDetails
          newListingDetails[1].end = endDate;
        }

        setListingDetails(newListingDetails);
      }

      if (data.host.pictures.length > 0 && data.host.pictures.some(picture => typeof picture === 'string')) {
        if (data.host.pictures.some(url => url.startsWith("https"))) {
          console.log("Fetching pictures from firebase IN ALL SET...");
          setLoadingFromFirebase(true);

          const convertSignedUrlsToBlobs = async () => {
            let url = data.host.pictures[0];
            const filename = extractFilenameFromUrl(url);
            let response;
            try {
              response = await fetch(url);
              if (!response.ok) {
                throw new Error('Image not found');
              }
            } catch (error) {
              // Also for emulator!
              console.log('ERROR:', error);
              url = "https://i.ibb.co/183KsnR/Group-1-9.png";
              response = await fetch(url);
            }
            const blob = await response.blob();
            const file = new File([blob], filename, { type: blob.type });
            const pseudoFile = {
              file,
              name: file.name,
              size: file.size,
              type: file.type,
              lastModified: file.lastModified,
              lastModifiedDate: file.lastModifiedDate,
              preview: URL.createObjectURL(file),
            };
            return pseudoFile;
          };

          convertSignedUrlsToBlobs()
            .then((pseudoFile) => {
              console.log("Setting loading from firebase to false...");
              setLoadingFromFirebase(false);
              setCoverPhoto(pseudoFile);
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        }
      }
    }
  }, [data]);

  const isGoogleMapsApiLoaded = useGoogleMapsApi();

  // Load neighborhood
  useEffect(() => {

    if (listingDetails[1].prices.length > 0) {
      // Get room with lowest price
      let lowestPriceRoom = listingDetails[1].prices.reduce((prev, current) => {
        return (prev.price < current.price) ? prev : current;
      });
      setLowestPriceRoomDuration(lowestPriceRoom.duration);
      setLowestPrice(lowestPriceRoom.price);

      setTrueAmenities(Object.entries(listingDetails[0]["amenities"])
        .filter(([key, value]) => value === true)
        .map(([key, value]) => key));
    }

    if (!isGoogleMapsApiLoaded) return;
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ 'address': listingDetails[0].address }, (results, status) => {
      if (status === 'OK') {
        const components = results[0].address_components;
        console.log(components);

        // Parse location components
        const neighborhoodComponent = components.find(component => component.types.includes("neighborhood"));
        const cityComponent = components.find(component => component.types.includes("locality") || component.types.includes("administrative_area_level_3"));
        const stateComponent = components.find(component => component.types.includes("administrative_area_level_1"));

        if (neighborhoodComponent) {
          setNeighborhood(`${neighborhoodComponent.long_name}, ${cityComponent.long_name}`);
        } else {
          setNeighborhood(`${cityComponent.long_name}, ${stateComponent.short_name}`);
        }
        setCanGoNext(true);
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  }, [listingDetails, isGoogleMapsApiLoaded]);

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center lg:justify-center justify-start pt-[80px] lg:pt-0 mb-[300px]'>
        <div className='w-full lg:w-[1000px] lg:max-h-[550px] pb-[80px] lg:pb-0 pt-10 px-5 lg:px-0'>
          <div className='w-full h-full rounded-3xl flex flex-col items-center justify-center relative overflow-hidden'>

            <div className='w-full py-2 text-left'>
              <h2 className='text-[2rem] my-0 py-0 font-medium'>All set?</h2>
              <p className='opacity-50 pb-3'>Make sure everything looks correct</p>
            </div>

            <div className='flex flex-col lg:flex-row lg:overflow-y-auto gap-8'>
              {/* Left */}
              <div className='w-full lg:w-3/5 h-full flex justify-center items-center flex-col'>

                <div className='rounded-2xl overflow-hidden h-full w-full relative'>
                  {!(loadingFromFirebase || !coverPhoto) &&
                    <>
                      <div className='bg-hostU-dimmed z-30 absolute w-full h-full'></div>
                      <div className="p-5 absolute z-40 text-white bottom-0">
                        <p className='text-2xl font-bold'>{neighborhood}</p>
                        <p>{listingDetails[1]["start"]} to {listingDetails[1]["end"]}</p>
                        <p>{listingDetails[1].prices.length > 1 ? "From ": ""}${lowestPrice} per {lowestPriceRoomDuration}</p>
                      </div>
                    </>
                  }
                  <div className='relative w-full h-full z-20'>
                    {(loadingFromFirebase || !coverPhoto) ? (
                      <div className='w-full h-full flex justify-center items-center'>
                        <ImSpinner8 className="animate-spin text-gray-400 text-4xl" />
                      </div>
                    ) : (
                      <img className="w-full h-full object-cover" src={coverPhoto["preview"]} alt="Cover Photo"/>
                    )}
                  </div>
                </div>
              </div>

              {/* Right */}
              <div className='w-full lg:w-2/5 h-full flex lg:overflow-y-auto max-h-[calc(100vh-80px)]'>
                <div className='h-max'>
                  {listingDetails.map((detail, index) => {
                    return (
                      <div className='w-full pt-2' key={index}>
                        <div className="w-full flex bg-gray-100 rounded-full px-4 py-2 flex-row items-center gap-2">
                          <i className={`bg-hostU-blue-gradient pointer-events-none bg-clip-text text-transparent fa-solid ${detail["icon"]}`}></i>
                          <p className="font-semibold">{detail["title"]}</p>
                        </div>
                        <div className='p-2'>
                          {detail["title"] == "Listing" &&
                            <>
                              <p>{detail["address"]}</p>
                              <p>Renting: {detail["renting"]} bedrooms of {detail["total"]}</p>
                              <p>Bathrooms: {detail["bathrooms"]}</p>
                              {trueAmenities.length > 0 &&
                                <>
                                  <p className='opacity-0 pointer-events-none'>.</p>
                                  <p>Amenities</p>
                                  <p>{trueAmenities.join(', ')}</p>
                                </>
                              }
                            </>
                          }
                          {detail["title"] == "Stay" &&
                            <>
                              <p>Available {detail["start"]} to {detail["end"]}</p>
                              {listingDetails[1]["otherIndividuals"] == 1 ? (
                                <p>{listingDetails[1]["otherIndividuals"]} other individual during this stay</p>
                                ) : (
                                <p>{listingDetails[1]["otherIndividuals"]} other individuals during this stay</p>
                              )}
                              <p className='opacity-0 pointer-events-none'>.</p>
                              {detail["prices"].map((priceDetail, index) => (
                                <p key={index}>Room {priceDetail.roomNumber}: ${priceDetail.price} per {priceDetail.duration}</p>
                              ))}
                            </>
                          }
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AllSet
