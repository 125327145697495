import React, { useEffect, useState, useRef } from 'react'
import { useFormSteps } from '../../formStepsContext'

function Bedrooms(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data, setData } = useFormSteps()
  const { page } = props;

  const [bedrooms, setBedrooms] = useState([]);

  const bedroomOptions = ['1', '2', '3', '4', '5+'];

  useEffect(() => {
    // Re-enable the back button
    if (currentPage == page) {
      setCanGoBack(true);
    }
  });

  // If fetched from firestore, update it
  useEffect(() => {
    setCanGoNext(false);
    if (data.guest.bedrooms.length > 0) {
      setCanGoNext(true);
      setBedrooms(data.guest.bedrooms);
    }
  }, [data]);

  const handleClick = (roomCount) => {
    const bedroomsCopy = [...bedrooms];

    if (bedroomsCopy.includes(roomCount)) {
      bedroomsCopy.splice(bedroomsCopy.indexOf(roomCount), 1);
    } else {
      bedroomsCopy.push(roomCount);
    }

    setBedrooms(bedroomsCopy);

    setData({
      ...data,
      guest: {
        ...data.guest,
        bedrooms: bedroomsCopy,
      }
    });

    if (bedroomsCopy.length > 0) {
      setCanGoNext(true);
    } else {
      setCanGoNext(false);
    }
  }

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center sm:justify-center justify-start pt-[80px] sm:pt-0 mb-[300px]'>
        <div className='w-full sm:w-[675px] pt-10 px-5 sm:px-0'>
          <div className='w-full py-2 text-left'>
            <h2 className='text-[2rem] my-0 py-0 font-medium'>How many bedrooms are you looking to rent?</h2>
            <p className='opacity-50 pb-3'>Select all that apply</p>
          </div>
          <div className='w-full py-8 rounded-3xl flex flex-wrap gap-2 items-center justify-center sm:justify-between relative overflow-hidden'>
            {bedroomOptions.map((roomCount, index) => (
              <button
                key={index}
                className={`${bedrooms.includes(roomCount) && "bg-hostU-green-400 font-semibold text-white border-hostU-green-400"} text-2xl transition duration-400 w-[7rem] h-[7rem] rounded-full flex justify-center items-center border-2 border-opacity-20`}
                onClick={() => handleClick(roomCount)}
              >
                <p>{roomCount}</p>
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Bedrooms
