import React, { useEffect, useState } from 'react'
import { useAuth } from '../auth/AuthContext'
import { useDashboard } from './DashboardContext'
import { motion } from 'framer-motion';

function UserToggle() {
  const { currentUser } = useAuth();
  const { userData, userType, setUserType, setCurrentTab, hostTabs, guestTabs, availableTypes } = useDashboard();

  const [order, setOrder] = useState([0, 1]); // State to hold the order of elements

  // Listen for changes in userType and update order
  useEffect(() => {
    setOrder(userType === 'Host' ? [1, 0] : [0, 1]);
  }, [userType]);

  const handleChange = () => {
    setUserType(userType === "Host" ? "Guest" : "Host")
    setCurrentTab(userType === "Host" ? hostTabs[0] : guestTabs[0]);
  }

  return (
    <>
      <button
        className={`flex transition duration-400 z-10 flex-row gap-1 ${userType === "Host" ? "pl-4 bg-hostU-blue-400" : "pr-4 bg-hostU-green-400"} h-[3rem] w-max rounded-full items-center`}
        onClick={() => handleChange()}
      >
        <motion.div
          className='bg-white z-20 w-[2.4rem] m-1 h-[2.4rem] rounded-full'
          style={{ order: order[0] }}
          layout
          transition={{ type: "spring", stiffness: 350, damping: 30 }}
        />
        <motion.p
          className={`text-semibold text-white font-semibold ${userType == "Host" && "px-1"}`}
          style={{ order: order[1] }}
          layout
          transition={{ type: "spring", stiffness: 350, damping: 30 }}
        >
          {userType}
        </motion.p>
      </button>
    </>
  )
}

export default UserToggle;
