import React from 'react'

function terms() {

  const text = `Last updated: April 11, 2023
  HostU is a community of University students, graduates and faculty who all have differing backgrounds, beliefs and cultures. It is an incredibly diverse community. What makes HostU different from other platforms is our belief that the diversity of our community, and the respect that we all share for honoring our differences, is the essence of why our platform and experience is meaningfully better than other shared economy platforms.
  Our HostU core team, and hopefully our whole community, is committed an environment where people from every background feel welcome and respected. This commitment rests on two foundational principles of inclusion and respect. Our shared commitment to these principles enables all members of our community to feel welcome on the HostU platform no matter who they are, where they come from, how they worship, or whom they love.
  While we can’t mandate harmony, we do believe that our community can promote empathy and understanding. We strive to help eliminate all forms of unlawful bias, discrimination, and intolerance from our platform. We want to promote a culture within the HostU community—hosts, guests and people just considering whether to use our platform—that goes above and beyond mere compliance. To that end, all of us, HostU employees, hosts and guests alike, agree to read and act in accordance with the following policy to strengthen our community and realize our mission of ensuring that everyone can belong, and feels welcome, anywhere.
  • Inclusion – We welcome people of all backgrounds with authentic hospitality and open minds. Joining HostU, as a host or guest, means becoming part of a community of inclusion. Bias, prejudice, racism, and hatred have no place on our platform or in our community. While hosts are required to follow all applicable laws that prohibit discrimination based on such factors as race, religion, national origin, and others listed below, we commit to do more than comply with the minimum requirements established by law.
  • Respect – We are respectful of each other in our interactions and encounters. HostU appreciates that local laws and cultural norms vary around the world and expects hosts and guests to abide by local laws, and to engage with each other respectfully, even when views may not reflect their beliefs or upbringings. HostU’s members bring to our community an incredible diversity of background experiences, beliefs, and customs. By connecting people from different backgrounds, HostU fosters greater understanding and appreciation for the common characteristics shared by all human beings and undermines prejudice rooted in misconception, misinformation, or misunderstanding.
  Hosts are required to comply with all applicable federal, state, and local laws that apply to housing and places of public accommodation. Hosts should contact HostU customer service if they have any questions about their obligations to comply with this HostU Nondiscrimination Policy. HostU will release further discrimination policy guidance for jurisdictions outside the United States if and when we enter into new markets.
  Race, Color, Ethnicity, National Origin, Religion, Sexual Orientation, Gender Identity, or Marital Status
  HostU hosts may not:
  • Decline a booking based on race, color, ethnicity, national origin, religion, sexual orientation, gender identity, or marital status.
  • Impose any different terms or conditions based on race, color, ethnicity, national origin, religion, sexual orientation, gender identity, or marital status.
  • Post any listing or make any statement that discourages or indicates a preference for or against any guest on account of race, color, ethnicity, national origin, religion, sexual orientation, gender identity, or marital status.
  Gender Identity
  HostU does not assign a gender identity to our users. We consider the gender of individuals to be what they identify and/or designate on their user profiles, and we expect our HostU community to do the same. This includes respecting the pronouns (he/him, she/her, they/them, etc.) any users within the community identify themselves with.
  HostU hosts may not:
  • Decline a booking from a guest based on gender identity unless the host shares living spaces (for example, bathroom, kitchen, or common areas) with the guest.
  HostU hosts may:
  • Make a unit available to guests of the host’s gender and not the other, where the host shares living spaces with the guest.
  Age and Familial Status
  HostU hosts may not:
  • Impose any different terms or conditions or decline a reservation based on the guest’s age or familial status, where prohibited by law.
  HostU hosts may:
  • Provide factually accurate information about their listing’s features (or lack of them) that could make the listing unsafe or unsuitable for guests of a certain age or families with children or infants.
  • Note in their listings applicable community restrictions (e.g., senior housing), regulations, or laws that prohibit guests under a particular age or families with children or infants.
  Disability
  HostU hosts may not:
  • Decline a guest based on any actual or perceived disability.
  • Impose any different terms or conditions based on the fact that the guest has a disability.
  • Substitute their own judgment about whether a unit meets the needs of a guest with a disability for that of the prospective guest.
  • Inquire about the existence or severity of a guest’s disability, or the means used to accommodate any disability. If, however, a potential guest raises his or her disability, a host may, and should, discuss with the potential guest whether the listing meets the potential guest’s needs.
  • Prohibit or limit the use of mobility devices.
  • Charge more in fees for guests with disabilities, including pet fees when the guest has a service animal.
  • Post any listing or make any statement that discourages or indicates a preference for or against any guest on account of the fact that the guest has a disability.
  • Refuse to communicate with guests through accessible means that are available, including relay operators (for people with hearing impairments) and e-mail (for people with vision impairments using screen readers).
  HostU hosts may:
  • Provide factually accurate information about the unit’s accessibility features (or lack of them), allowing for guests with disabilities to assess for themselves whether the unit is appropriate to their individual needs.
  Personal Preferences
  HostU hosts may:
  • Except as noted above, HostU hosts may decline a booking based on factors that are not prohibited by law. For example, except where prohibited by law, HostU hosts may decline a booking with pets, or to guests who smoke.
  • Require guests to respect restrictions on foods consumed in the listing (e.g., a host who maintains a Kosher or vegetarian kitchen may require guests to respect those restrictions). These restrictions should be stated clearly in your house rules. For example, an HostU host may turn down a guest who wants to smoke in a unit, or place limits on the number of guests in a unit.
  When guests are turned down. Hosts should keep in mind that no one likes to be turned down. While a host may have, and articulate, lawful and legitimate reasons for turning down a potential guest, it may cause that member of our community to feel unwelcome or excluded. Hosts should make every effort to be welcoming to guests of all backgrounds. Hosts who demonstrate a pattern of rejecting guests from a protected class (even while articulating legitimate reasons) undermine the strength of our community by making potential guests feel unwelcome, and HostU may suspend hosts who have demonstrated such a pattern from the HostU platform.
  What happens when a host does not comply with our policies in this area?
  If a particular listing contains language contrary to this nondiscrimination policy, the host will be asked to remove the language and affirm his or her understanding and intent to comply with this policy and its underlying principles. HostU may also, in its discretion, take steps up to and including suspending the host from the HostU platform.
  If the host improperly rejects guests on the basis of protected class, or uses language demonstrating that his or her actions were motivated by factors prohibited by this policy, HostU will take steps to enforce this policy, up to and including suspending the host from the platform.
  As the HostU community grows, we will continue to ensure that HostU’s policies and practices align with our most important goal: To ensure that guests and hosts feel welcome and respected in all of their interactions using the HostU platform. The public, our community, and we ourselves, expect no less than this.`

  const paragraphs = text.split('\n');

  const applyStyles = (line) => {
    const shouldBold = line.includes('What happens when a host does not comply with our policies in this area');
    const shouldBoldAndItalic = line.includes(`Race, Color, Ethnicity, National Origin, Religion, Sexual Orientation, Gender Identity, or Marital Status`) ||
                       line.includes('Gender Identity') ||
                       line.includes('Age and Familial Status') ||
                       line.includes('Disability') ||
                       line.includes('Personal Preferences');
    if (shouldBold) {
      return <strong className='text-2xl'>{line}</strong>
    } else if (shouldBoldAndItalic) {
      return <strong className='text-2xl italic'>{line}</strong>
    } else {
      return line;
    }
  };

  return (
    <div className="mx-auto border-b px-6 pt-8 pb-6 flex flex-col bg-white justify-center">
      <div className="max-w-[800px] mx-auto py-16">

        {/* Header */}
        <div className="text-4xl font-bold py-8">
          <p>hostU Nondiscrimination Policy</p>
        </div>

        {/* Text */}
        <div className="text-xl space-y-6 leading-10">

          {paragraphs.map((paragraph, index) => (
            <p key={index}>{applyStyles(paragraph)}</p>
          ))}

        </div>
      </div>
    </div>
  )
}

export default terms
