import React, { useEffect, useState, useRef } from 'react'
import { useFormSteps } from '../../formStepsContext'
import GoogleMapReact from 'google-map-react';
import { useGoogleMapsApi } from "../../../GoogleMaps"
import axios from 'axios';
import { Slider as RangeSlider, Typography } from "@mui/material";

function Location(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data, setData } = useFormSteps()
  const { page } = props;
  const [region, setRegion] = useState(data.guest.region || '');
  const [validRegion, setValidRegion] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: 41.8781,
    lng: -87.6298,
  });

  const [radius, setRadius] = useState(data.guest.radius);
  const radiusRef = useRef(radius);

  // Google maps hook
  const gmapsGuestRef = useRef();
  const loaded = useGoogleMapsApi();

  const [circleInstance, setCircleInstance] = useState();

  useEffect(() => {
    // Re-enable the back button
    if (currentPage == page) {
      setCanGoBack(true);
    }
    // If the page just loaded and nothing has been interacted with,
    // disable the next button
    if (currentPage == page && !region) {
      setCanGoNext(false);
    }
    // If the user has already clicked a valid region from the dropdown
    // but they may have changed the region after, make sure it's valid still
    // and then enable the next button
    else if (currentPage == page && data.guest.region && validRegion) {
      setCanGoNext(true);
    }
  });

  // If fetched from firestore, update it
  useEffect(() => {
    if (data.guest.region && data.guest.region != '' && data.guest.radius) {
      setRegion(data.guest.region);
      setRadius(data.guest.radius);
      setCanGoNext(true);
      setValidRegion(true);
      const updateCoordinates = async () => {
        const coords = await getCoordinates(data.guest.region)
        setCoordinates(coords);
      }
      updateCoordinates();
    }
  }, [data]);

  // Out of territory state
  const [oot, setOot] = useState(false);

  // Handle input change
  const handleInputChange = (e) => {
    setRegion(e.target.value);
    setCanGoNext(false);
    setValidRegion(false);
  };

  // Geocode coordinates
  const getCoordinates = async (address) => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    const geocodeResponse = await axios.get(geocodeUrl);
    const location = geocodeResponse.data.results[0].geometry.location;
    return {
      lat: location.lat,
      lng: location.lng,
    };
  };

  useEffect(() => {
    if (loaded && gmapsGuestRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(gmapsGuestRef.current, {
        types: ['geocode'],
        componentRestrictions: { country: 'us' },
      });

      autocomplete.addListener('place_changed', async () => {
        const place = autocomplete.getPlace();

        // The rest of your onPlaceSelected callback goes here...
        if (!place.formatted_address.includes(', IL')) {
          setOot(true);
        } else {
          setOot(false);

          // Get coordinates
          const coordinates =  await getCoordinates(place.formatted_address);
          setCoordinates(coordinates);

          // Set address
          setRegion(place.formatted_address);

          // Set form data
          setData({
            ...data,
            guest: {
              ...data.guest,
              region: place.formatted_address,
              coordinates: {
                lat: coordinates.lat,
                lng: coordinates.lng,
              },
            },
          });

          // Enable next button
          setCanGoNext(true);
          setValidRegion(true);
        }
      });
    }
  }, [loaded])

  const MapPin = ({ className, alt }) => (
    <svg
    className={className}
    viewBox="0 0 173 266"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label={alt}
  >
    <path d="M172.368 86.184C172.368 38.5859 133.782 0 86.184 0C38.5859 0 0 38.5859 0 86.184C0 133.782 38.5859 172.368 86.184 172.368C133.782 172.368 172.368 133.782 172.368 86.184Z" fill="#2EBDB5"/>
    <path d="M87.913 266C87.913 200.697 0 152.817 0 86.8491H87.913V266Z" fill="#2EBDB5"/>
    <path d="M88.0462 266C88.0462 200.648 172.368 152.733 172.368 86.7158H85.3862L88.0462 266Z" fill="#2EBDB5"/>
    <g clipPath="url(#clip0_140_11)">
      <path d="M86 86C91.0012 86 95.7976 83.9982 99.334 80.435C102.87 76.8718 104.857 72.0391 104.857 67C104.857 61.9609 102.87 57.1282 99.334 53.565C95.7976 50.0018 91.0012 48 86 48C80.9988 48 76.2024 50.0018 72.666 53.565C69.1296 57.1282 67.1429 61.9609 67.1429 67C67.1429 72.0391 69.1296 76.8718 72.666 80.435C76.2024 83.9982 80.9988 86 86 86ZM79.2674 93.125C64.7563 93.125 53 104.97 53 119.591C53 122.026 54.9594 124 57.3754 124H114.625C117.041 124 119 122.026 119 119.591C119 104.97 107.244 93.125 92.7326 93.125H79.2674Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_140_11">
        <rect width="66" height="76" fill="white" transform="translate(53 48)"/>
      </clipPath>
    </defs>
  </svg>
)

  const Pin = () => (
    <div style={{
      color: 'white',
      display: 'inline-flex',
      justifyContent: 'center',
      top: '0',
      borderRadius: '100%',
      transform: 'translate(-50%, -100%)',
    }}>
      <MapPin className="text-hostU-green-400 w-8" alt="Pin"/>
    </div>
  );

  const mapProps = {
    center: {
      lat: coordinates.lat,
      lng: coordinates.lng,
    },
    zoom: 11
  };

  useEffect(() => {
    // Convert radius from miles to meters
    radiusRef.current = radius;
    updateCircle();
  }, [radius, region, coordinates]);

  const updateCircle = () => {
    if (circleInstance) {
      circleInstance.setCenter(coordinates);
      circleInstance.setRadius(radius * 1609.34);
    }
  };

  const handleApiLoaded = ({ map, maps }) => {
    const circle = new maps.Circle({
      strokeColor: "#2ebeb5",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#2ebeb5",
      fillOpacity: 0.3,
      map,
      center: { lat: coordinates.lat, lng: coordinates.lng },
      radius: radius * 1609.34,
    });
    setCircleInstance(circle);
  };

  const handleRadiusChange = (e) => {
    setRadius(e.target.value);
    setData({...data, guest: { ...data.guest, radius: e.target.value }});
  }

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center overflow-y-auto justify-start sm:justify-center sm:pt-0'>
        <div className='w-full sm:w-[600px] mt-5 py-5 overflow-y-auto px-5 sm:px-0'>
          <div className='w-full py-2 text-left'>
            <h2 className='text-[2rem] my-0 py-0 font-medium'>Where are you looking to stay?</h2>
            <p className='opacity-50 pb-3'>Set a radius by dragging the slider</p>
          </div>
          <div className='w-full h-[100px] sm:h-[375px] rounded-3xl flex items-center justify-center relative overflow-hidden'>
            <div className='absolute flex w-[95%] sm:w-[90%] top-4 bg-white z-20 shadow-hostU-shadow-small px-5 py-1 rounded-3xl justify-center'>
              <input
                className="w-full h-full py-4 outline-none border-0"
                placeholder="Enter an address, neighborhood, city, or ZIP code"
                value={region}
                onChange={handleInputChange}
                ref={gmapsGuestRef}
              />
            </div>
            <div className='hidden sm:flex w-full h-full'>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                  libraries: ['places'],
                }}
                center={mapProps.center}
                defaultZoom={mapProps.zoom}
                options={{
                  fullscreenControl: false,
                }}
                onGoogleApiLoaded={handleApiLoaded}
                yesIWantToUseGoogleMapApiInternals={true}
              >
                <Pin
                  lat={mapProps.center.lat}
                  lng={mapProps.center.lng}
                />
              </GoogleMapReact>
            </div>
          </div>
          {oot &&
            <div className='w-full text-center py-2'>
              <p className='text-red-500'>We are currently only serving greater Chicago</p>
            </div>
          }
          <div className='pt-4 w-[95%] mx-auto'>
            <RangeSlider
              value={radius}
              min={0.01}
              max={20}
              onChange={handleRadiusChange}
              getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
              step={0.05}
              sx={{
                color: '#2ebeb5',
                height: 3,
                padding: '13px 0',
                '& .MuiSlider-thumb': {
                  height: 27,
                  width: 27,
                  backgroundColor: '#fff',
                  border: '1px solid currentColor',
                  '&:hover': {
                    boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
                  },
                  '& .airbnb-bar': {
                    height: 9,
                    width: 1,
                    backgroundColor: 'currentColor',
                    marginLeft: 1,
                    marginRight: 1,
                  },
                },
                '& .MuiSlider-track': {
                  height: 3,
                },
                '& .MuiSlider-rail': {
                  color: '#d8d8d8',
                  opacity: 1,
                  height: 3,
                },
              }}
            />
          </div>
          <div className='w-full flex gap-2 items-baseline justify-center pt-2'>
            <input
              onChange={handleRadiusChange}
              value={radius}
              className="border-b border-black border-opacity-25 text-3xl py-2 w-20 text-center outline-none"
            />
            <p>mile radius</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Location
