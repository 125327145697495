import React, { useEffect, useState, useRef } from 'react'
import { useFormSteps } from './formStepsContext'
import { Navigate } from 'react-router-dom'
import { ImSpinner8 } from 'react-icons/im'

function NavigationButtons() {
  const { canGoBack, setCanGoBack, user, pages, canGoNext, setCanGoNext, currentPage, setCurrentPage, submitting, submit, doneSubmitting } = useFormSteps()

  // Filter the pages array to only include pages for the current user
  const userPages = pages.filter(p => p.pageUser === user || !p.pageUser);

  // Check if the current page is the last one for the current user
  const isLastPage = currentPage === userPages[userPages.length - 1].page;

  const backButtonRef = useRef();
  const nextButtonRef = useRef();

  useEffect(() => {
    const handler = function (event) {
      if (event.key === 'Enter') {
        if (!canGoBack && event.target === backButtonRef.current) {
          event.preventDefault();
        }
        if (!canGoNext && event.target === nextButtonRef.current) {
          event.preventDefault();
        }
      }
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [canGoBack, canGoNext]);

  return (
    <>

      {doneSubmitting &&
        <Navigate to="/dashboard"/>
      }

      <div className='fixed bg-white md:bg-transparent bottom-0 z-40 w-full pointer-events-none font-semibold flex justify-between px-10 py-5 border-t md:border-none md:p-16'>
        <button
          className={`${canGoBack ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"} transition duration-400 flex flex-row items-center justify-center gap-2`}
          ref={backButtonRef}
          onClick={() => {
            setCanGoBack(false);
            setCurrentPage(currentPage - 1);
          }}
        >
          <i className='fa-solid fa-arrow-left'></i>
          <p>Back</p>
        </button>
        <button
          className={`${canGoNext ? "opacity-100 pointer-events-auto" : "opacity-50 pointer-events-none"} transition duration-400 ${user == 'Guest' && currentPage == 1 ? "bg-white text-hostU-green-400" : "bg-hostU-blue-gradient text-white"} flex flex-row gap-2 items-center rounded-full px-5 py-2`}
          ref={nextButtonRef}
          onClick={() => {
            setCanGoNext(false);
            if (isLastPage) {
              submit();
            } else {
              setCurrentPage(currentPage + 1);
            }
          }}
        >
          <p>{(isLastPage && currentPage != 1) ? "Finish" : "Next"}</p>
          {submitting &&
            <ImSpinner8 className="text-white animate-submit-spin" />
          }
        </button>
      </div>
    </>
  )
}

export default NavigationButtons
