import React, { useEffect, useState } from 'react'
import { useFormSteps } from '../../formStepsContext'

function SharedPref(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data, setData } = useFormSteps();
  const { page } = props;

  let sharedOptions;
  if (data.guest.who == "Just Myself") {
    sharedOptions = ["My own place", "Shared place"];
  } else {
    sharedOptions = ["Our own place", "Shared place"];
  }
  const genderOptions = ["Female", "Male", "Non-binary"];

  const [sharedPref, setSharedPref] = useState([]);
  const [genderPref, setGenderPref] = useState([]);

  // If fetched from firestore, update it
  useEffect(() => {
    if (data.guest.sharedPref) {
      setSharedPref(data.guest.sharedPref);
      if (data.guest.sharedPref.includes("My own place") || data.guest.sharedPref.includes("Our own place")) {

      }
    }
    if (data.guest.genderPref) {
      setGenderPref(data.guest.genderPref);
      if (data.guest.genderPref.length > 0) {
        setCanGoNext(true);
      }
    }
    if (data.guest.sharedPref && data.guest.genderPref) {
      console.log(data.guest.sharedPref);
      console.log(data.guest.genderPref);
      if (data.guest.sharedPref.includes("Shared place") && data.guest.genderPref.length == 0) {
        setCanGoNext(false);
      } else if ((data.guest.sharedPref.includes("My own place") || data.guest.sharedPref.includes("Our own place")) && !data.guest.sharedPref.includes("Shared place")) {
        setCanGoNext(true);
      }
    }
  }, [data]);

  // Re-enable buttons on component mount
  useEffect(() => {
    if (currentPage == page) {
      setCanGoBack(true);
    }
  }, []);

  useEffect(() => {
    console.log("Shared pref:", sharedPref);
    console.log("Gender pref:", genderPref);
    if (sharedPref.length == 0 || ((sharedPref == ["My own place"] || sharedPref == ["Our own place"]) && genderPref.length == 0)) {
      setCanGoNext(false);
    } else if ((sharedPref.includes("Shared place") && genderPref.length > 0) || (data.guest.sharedPref.includes("My own place") || data.guest.sharedPref.includes("Our own place")) && !data.guest.sharedPref.includes("Shared place")) {
      setCanGoNext(true);
    }
  }, [sharedPref, genderPref])

  const handleChange = (option, type) => {
    if (type == "shared") {
      let nextPref;
      if (sharedPref.includes(option)) {
        nextPref = sharedPref.filter(item => item !== option);
      } else {
        nextPref = [...sharedPref, option];
      }
      setSharedPref(nextPref);
      setData({
        ...data,
        guest: {
          ...data.guest,
          sharedPref: nextPref,
        },
      });
    } else {
      setGenderPref([...genderPref, option]);
      let nextPref;
      if (genderPref.includes(option)) {
        nextPref = genderPref.filter(item => item !== option);
      } else {
        nextPref = [...genderPref, option];
      }
      setGenderPref(nextPref);
      setData({
        ...data,
        guest: {
          ...data.guest,
          genderPref: nextPref,
        },
      });
    }
  };

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center sm:justify-center justify-start pt-[80px] sm:pt-0 pb-[700px] sm:pb-0'>
        <div className='w-full sm:w-[600px] pt-10 px-5 sm:px-0'>
          <div className='w-full text-left py-0'>
            <h2 className='text-[2rem] my-0 py-2 leading-tight font-medium'>What type of living arrangement are you looking for?</h2>
            <p className='opacity-50 pb-3'>Select all that apply</p>
          </div>
          <div className='w-full py-2 flex flex-col text-xl gap-2 sm:flex-row'>
            {sharedOptions.map((option, index) => {
              return (
                <button
                  className={`w-full sm:w-1/2 flex flex-col justify-center items-center py-5 gap-2 rounded-full ${sharedPref.includes(option) ? "bg-hostU-green-400 text-white font-semibold" : "bg-white border-2 border-opacity-50"} transition duration-400`}
                  onClick={() => handleChange(option, "shared")}
                  key={index}
                >
                  <p>{option}</p>
                </button>
              )
            })}
          </div>
          {sharedPref.includes("Shared place") &&
            <>
              <div className='mt-5 w-full text-left py-0'>
                <h2 className='text-[2rem] my-0 py-2 leading-tight font-medium'>Who do you want to live with?</h2>
                <p className='opacity-50 pb-3'>Select all that apply</p>
              </div>
              <div className='w-full py-2 flex pb-20 sm:pb-0 gap-2 text-xl'>
                {genderOptions.map((option, index) => {
                  return (
                    <button
                      className={`w-full sm:w-1/2 flex flex-col justify-center items-center py-5 gap-2 rounded-full ${genderPref.includes(option) ? "bg-hostU-green-400 text-white font-semibold" : "bg-white border-2 border-opacity-50"} transition duration-400`}
                      onClick={() => handleChange(option, "gender")}
                      key={index}
                    >
                      <p>{option}</p>
                    </button>
                  )
                })}
              </div>
            </>
          }
        </div>
      </div>
    </>
  )
}

export default SharedPref
