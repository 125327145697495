import React, { useState, useEffect, useRef } from 'react'
import { useDashboard } from '../../DashboardContext'
import { useAuth } from '../../../auth/AuthContext'
import GuestCard from './guestCard'
import Alexis from '@images/Alexis2.png'

function Matches() {

  // Get current user and logout from auth
  const { currentUser } = useAuth();

  // Carousel platter ref
  const platterRequested = useRef(null);
  const platterConnected = useRef(null);

  const guestsRequested = [
    {
      "headshot": Alexis,
      "name": "Alexis R.",
      "gradYear": 2025,
      "dateRange": "Sept 2 - Dec 23, 2023",
      "roommateCount": 1,
      "school": "Northwestern University"
    },
    {},
    {},
    {},
    {},
    {},
    {},
  ]
  
  const guestsConnected = [
    {},
    {},
    {},
  ]

  // TODO: Get matches from backend
  const getMatches = () => {

    // Get a reference to the user document
    const userDocRef = firebase.firestore().collection('users').doc(userId);
  
    // Get the user document
    userDocRef.get().then((userDoc) => {
        if (userDoc.exists) {
            // Get match references
            const matchReferences = userDoc.data().matchReferences;
  
            // Loop through match references and get each match document
            matchReferences.forEach((matchRef) => {
                matchRef.get().then((matchDoc) => {
                    // Do something with match document
                    console.log(matchDoc.data());
                });
            });
        }
    });
  }

  const scrollAmount = 600;
  const handleScrollLeft = (platter) => {
    const currentPosition = platter.current.scrollLeft;
    const newPosition = currentPosition - scrollAmount;

    platter.current.scrollTo({
      left: newPosition,
      behavior: 'smooth',
    });
  };
  const handleScrollRight = (platter) => {
    const currentPosition = platter.current.scrollLeft;
    const newPosition = currentPosition + scrollAmount;

    platter.current.scrollTo({
      left: newPosition,
      behavior: 'smooth',
    });
  };

  const [showLeftRequestedButton, setShowLeftRequestedButton] = useState(false);
  const [showRightRequestedButton, setShowRightRequestedButton] = useState(true);
  
  const [showLeftConnectedButton, setShowLeftConnectedButton] = useState(false);
  const [showRightConnectedButton, setShowRightConnectedButton] = useState(true);
  
  useEffect(() => {
    const checkButtonVisibility = () => {
      const scrollWidthRequested = platterRequested.current.scrollWidth;
      const clientWidthRequested = platterRequested.current.clientWidth;
      const scrollLeftRequested = platterRequested.current.scrollLeft;
      
      setShowRightRequestedButton(scrollWidthRequested - clientWidthRequested - scrollLeftRequested > 0);
      setShowLeftRequestedButton(scrollLeftRequested > 0);
      
      const scrollWidthConnected = platterConnected.current.scrollWidth;
      const clientWidthConnected = platterConnected.current.clientWidth;
      const scrollLeftConnected = platterConnected.current.scrollLeft;

      setShowRightConnectedButton(scrollWidthConnected - clientWidthConnected - scrollLeftConnected > 0);
      setShowLeftConnectedButton(scrollLeftConnected > 0);
    };

    // Attach scroll event listener to the platter div
    platterRequested.current.addEventListener('scroll', checkButtonVisibility);
    platterConnected.current.addEventListener('scroll', checkButtonVisibility);

    // Attach resize event listener to the window object
    window.addEventListener('resize', checkButtonVisibility);

    // Initial check for button visibility
    checkButtonVisibility();

    return () => {
      if (platterRequested.current) {
        platterRequested.current.removeEventListener('scroll', checkButtonVisibility);
      }

      if (platterConnected.current) {
        platterConnected.current.removeEventListener('scroll', checkButtonVisibility);
      }

      window.removeEventListener('resize', checkButtonVisibility);
    };
  }, []);

  return (
    <>
      <div className='w-full'>
        <div>
          <h2 className='font-bold text-3xl'>Matches</h2>
          <p className='opacity-50'>Guests curated for you</p>
        </div>

        {/* Requested You */}
        <div className='relative py-10'>
          <div>
            <h3 className='text-2xl'>Requested you</h3>
          </div>

          {/* Cards Scroller Platter */}
          <div ref={platterRequested} className="py-4 hide-scrollbar overflow-x-auto scrollbar-hide overscroll-x-none -mx-10">
            <div className="flex flex-nowrap items-center gap-5 w-fill px-10">
              {Object.keys(guestsRequested).map((guest, index, array) => (
                  <div
                    key={index} 
                    className={index === array.length - 1 ? 'pr-4' : ''}
                  >
                    <GuestCard
                      headshot={guestsRequested[index].headshot}
                      name={guestsRequested[index].name}
                      gradYear={guestsRequested[index].gradYear}
                      dateRange={guestsRequested[index].dateRange}
                      roommateCount={guestsRequested[index].roommateCount}
                      school={guestsRequested[index].school}
                    />
                  </div>
              ))}
            </div>

            {/* Left Button */}
            <div className={`${!showLeftRequestedButton && "pointer-events-none opacity-0"} pl-5 transition absolute flex items-center top-0 bottom-0`}>
              <button
                className='my-auto z-50 ml-3 w-16 h-16 flex items-center justify-center bg-black opacity-20 hover:opacity-40 transition duration-400 rounded-full p-4'
                onClick={() => handleScrollLeft(platterRequested)}
              >
                <i className='fa-solid fa-arrow-right rotate-180 text-2xl text-white'></i>
              </button>
            </div>

            {/* Right Button */}
            <div className={`${!showRightRequestedButton && "pointer-events-none opacity-0"} transition absolute flex items-center top-0 bottom-0 right-0`}>
              <button
                className='my-auto z-50 mr-3 w-16 h-16 flex items-center justify-center bg-black opacity-20 hover:opacity-40 transition duration-400 rounded-full p-4'
                onClick={() => handleScrollRight(platterRequested)}
              >
                <i className='fa-solid fa-arrow-right text-2xl text-white'></i>
              </button>
            </div>
          </div>
        </div>
        
        {/* Connected */}
        <div className='relative pb-10 overscroll-x-none'>
          <div>
            <h3 className='text-2xl'>Connected</h3>
          </div>

          {/* Cards Scroller Platter */}
          <div ref={platterConnected} className="py-4 hide-scrollbar overflow-x-auto scrollbar-hide overscroll-x-none -mx-10">
            <div className="flex flex-nowrap items-center gap-5 w-fill px-10">
              {Object.keys(guestsConnected).map((guest, index, array) => (
                  <div
                    key={index} 
                    className={index === array.length - 1 ? 'pr-4' : ''}
                  >
                    <GuestCard
                      headshot={guestsConnected[index].headshot}
                      name={guestsConnected[index].name}
                      gradYear={guestsConnected[index].gradYear}
                      dateRange={guestsConnected[index].dateRange}
                      roommateCount={guestsConnected[index].roommateCount}
                      school={guestsConnected[index].school}
                    />
                  </div>
              ))}
            </div>

            {/* Left Button */}
            <div className={`${!showLeftConnectedButton && "pointer-events-none opacity-0"} pl-5 transition absolute flex items-center top-0 bottom-0`}>
              <button
                className='my-auto z-50 ml-3 w-16 h-16 flex items-center justify-center bg-black opacity-20 hover:opacity-40 transition duration-400 rounded-full p-4'
                onClick={() => handleScrollLeft(platterConnected)}
              >
                <i className='fa-solid fa-arrow-right rotate-180 text-2xl text-white'></i>
              </button>
            </div>

            {/* Right Button */}
            <div className={`${!showRightConnectedButton && "pointer-events-none opacity-0"} transition absolute flex items-center top-0 bottom-0 right-0`}>
              <button
                className='my-auto z-50 mr-3 w-16 h-16 flex items-center justify-center bg-black opacity-20 hover:opacity-40 transition duration-400 rounded-full p-4'
                onClick={() => handleScrollRight(platterConnected)}
              >
                <i className='fa-solid fa-arrow-right text-2xl text-white'></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Matches
