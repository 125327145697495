import React from 'react'
import Logo from '@components/logo'
import { useFormSteps } from './formStepsContext'
import { ImSpinner8 } from 'react-icons/im'

function Navbar() {

  const { currentPage, maxPages, exit, exiting } = useFormSteps();

  return (
    <>
      <header className="bg-white z-50 fixed w-full">
        <div className='z-50 border-b'>

          {/* Desktop Navbar */}
          <nav className="max-w-[1500px] h-[80px] mx-auto font-semibold flex py-10 relative justify-center sm:px-4 md:px-4 lg:px-4 items-center">
            {/* Exit button */}
            <div className='absolute flex justify-center flex-row gap-3 bg-white items-center left-0 mx-6'>
              <button onClick={exit} className={`${exiting && "pointer-events-none opacity-50"} bg-white border border-opacity-50 hover:opacity-50 transition duration-400 z-50 flex flex-row gap-2 justify-center items-center px-5 py-2 shadow-hostU-shadow-figma rounded-full`}>
                <p>Exit</p>
                {exiting &&
                  <ImSpinner8 className="text-black opacity-50 animate-submit-spin" />
                }
              </button>
            </div>

            {/* Logo */}
            <div className='w-full flex justify-end sm:justify-center px-4 items-center absolute'>
              <a href="/" tabIndex={1}>
                <Logo textSize="text-2xl"
                      uColor="text-white"
                      bgWidth={9}/>
              </a>
            </div>
          </nav>
        </div>
        <div className={`${currentPage ? 'opacity-100' : 'opacity-0'} transition-all duration-700`}>
          <ProgressBar currentPage={currentPage} maxPages={maxPages} />
        </div>
      </header>
    </>
  )
}

function ProgressBar({ currentPage, maxPages }) {
  const progressPercentage = ((currentPage) / maxPages) * 100;
  const { user } = useFormSteps()

  return (
    <div className="absolute w-full h-2">
      <div
        className={`h-2 ${user == 'Host' ? "bg-hostU-blue-400" : "bg-hostU-green-400"} transition-all duration-700`}
        style={{ width: `${progressPercentage}%` }}
      ></div>
    </div>
  )
}

export default Navbar
