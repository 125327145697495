import React, { useEffect, useState } from 'react'
import { useFormSteps } from '../../formStepsContext'

function ListingType(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data, setData } = useFormSteps();
  const { page } = props;

  const [accomodation, setAccomodation] = useState('');

  useEffect(() => {
    // Re-enable the back button
    if (currentPage == page) {
      setCanGoBack(true);
    }
    // If the page just loaded and nothing has been interacted with,
    // disable the next button
    if (currentPage == page && accomodation == '') {
      setCanGoNext(false);
    }
    // If the user has already clicked a valid address from the dropdown
    // but they may have changed the address after, make sure it's valid still
    // and then enable the next button
    else if (currentPage == page && data.host.listingType) {
      setCanGoNext(true);
    }
  });

  // If fetched from firestore, update it
  useEffect(() => {
    if (data.host.listingType && data.host.listingType != '') {
      setAccomodation(data.host.listingType);
      setCanGoNext(true);
    }
  }, [data]);

  // Handle input change
  const handleChange = (type) => {
    console.log(type)
    setAccomodation(type);
    setData({
      ...data,
      host: {
        ...data.host,
        listingType: type,
      },
    });
    setCanGoNext(true);
  };

  const types = [
    {
      name: "House",
      icon: "fa-house",
    },
    {
      name: "Apartment",
      icon: "fa-building",
    }
  ];

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center sm:justify-center justify-start pt-[80px] sm:pt-0 mb-[300px]'>
        <div className='w-full sm:w-[600px] pt-10 px-5 sm:px-0'>
          <div className='w-full text-left py-4'>
            <h2 className='text-[2rem] my-0 py-0 font-medium'>What kind of place are we talking about?</h2>
            <p className='opacity-50 pb-3'>You'll give us information on roommates later</p>
          </div>
          <div className='w-full flex flex-col text-xl gap-2 sm:flex-row'>
            {types.map((type) => {
              return (
                <button
                  className={`w-full sm:w-1/2 flex flex-col justify-center items-center py-5 gap-2 rounded-full ${accomodation == type["name"] ? "bg-hostU-blue-400 font-semibold text-white" : "bg-white border-2 border-opacity-50"} transition duration-400`}
                  onClick={() => handleChange(type["name"])}
                  key={type["name"]}
                >
                  <p>{type["name"]}</p>
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default ListingType
