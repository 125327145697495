import React from 'react'
import Logo from './logo'
import { useLocation } from 'react-router-dom';

function Footer(props) {

  const location = useLocation();
  const isHome = location.pathname === '/';

  const handleClick = () => {
    if (isHome) {
      props.scrollToSection();
    } else {
      window.location.href = 'https://www.joinhostu.com';
    }
  };

  return (
    <div className="w-full mx-auto py-12 md:py-16">
      <div className="flex flex-col gap-10 md:gap-0 md:flex-row max-w-[1200px] px-4 md:px-6 mx-auto justify-between flex-wrap">

        {/* hostU Logo & Copyright */}
        <div className="flex flex-col gap-2">
          <Logo textSize="text-2xl"
                hostColor="text-black"
                uColor="text-white"
                bgWidth={9}/>
          <p className="text-gray-500">©2023 All Rights Reserved.</p>
        </div>

        {/* Join */}
        <div className="flex flex-col gap-2">
          <p className="font-bold text-lg">Join</p>
          <div>
            <button onClick={handleClick} tabIndex={13}>
              <p className="text-gray-500">Become a host</p>
            </button>
          </div>
          <div>
            <button onClick={handleClick} tabIndex={14}>
              <p className="text-gray-500">Become a guest</p>
            </button>
          </div>
        </div>

        {/* Legal */}
        <div className="flex flex-col gap-2">
          <p className="font-bold text-lg">Legal</p>
          <a href="/terms" tabIndex={17}>
            <p className="text-gray-500">Terms</p>
          </a>
          <a href="/policy" tabIndex={18}>
            <p className="text-gray-500">Policy</p>
          </a>
        </div>

        {/* Contact */}
        <div className="flex flex-col gap-2">
          <p className="font-bold text-lg">Contact</p>
          <a href="mailto:hello@joinhostu.com" tabIndex={17}>
            <p className="text-gray-500">Get in Touch</p>
          </a>
          <a target="_blank"
             href="https://thegarage.northwestern.edu/"
             tabIndex={18}>
            <p className="text-gray-500">Garage@Northwestern</p>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
