import React from 'react'
import MapImage from '@images/map.png'
import MapCard from '@components/landing/mapCard.jsx'

import SkylerHeadshot from '@images/skyler.png'
import SkylerApt from '@images/apartment2.png'
import JoshHeadshot from '@images/josh.png'
import JoshApt from '@images/apartment3.jpeg'
import Columbia from '@images/columbia.png'
import NYU from '@images/nyu.png'

import { motion } from 'framer-motion'

const ShowcaseInfo = [
  {
    "studentName": "Skyler",
    "headshot": SkylerHeadshot,
    "school": "Columbia",
    "schoolStatus": "Class of '24",
    "schoolLogo": Columbia,
    "apartment": SkylerApt,
    "location": "Greenwich Village, NYC",
    "dates": "Available May - September 2023",
    "marginTop": '60px',
  },
  {
    "studentName": "Josh",
    "headshot": JoshHeadshot,
    "school": "NYU Tisch",
    "schoolStatus": "Grad School",
    "schoolLogo": NYU,
    "apartment": JoshApt,
    "location": "Astor Place, NYC",
    "dates": "Available June - August 2023",
  }
]

function map() {
  return (
    <div className='w-full mt-12 mb-12 bg-white py-10 md:py-12 lg:py-20'>
      <div className="px-2 sm:px-4 flex flex-col mx-auto max-w-[1200px] justify-center">
        <div className='font-bold w-full py-4 flex flex-col md:flex-row items-center gap-2'>
          <p className="text-xl text-center md:text-left">🔍 Results for</p>
          <p className="bg-white text-lg sm:text-xl py-2 px-4 text-center md:text-left mx-1 w-max mx-auto md:mx-0 shadow-hostU-shadow-small rounded-2xl border">NYC Housing Near Greenwich Village</p>
        </div>
        <div className="h-[500px] md:h-[575px] rounded-3xl bg-cover bg-no-repeat overflow-hidden" style={{ backgroundImage: `url(${MapImage})` }}>

          {/* Map Cards */}
          <div className="flex flex-row justify-evenly items-center h-full">

            <motion.div
                  className="hidden md:flex hover:scale-[1.02] relative z-0 transition md:translate-y-[30px] duration-500"
                  initial={{ y: 200, opacity: 0 }}
                  whileInView={{
                    y: `30px`,
                    opacity: 1,
                    transition: {
                      delay: 0,
                      duration: 0.5,
                      ease: "easeOut",
                    },
                  }}
                  viewport={{ once: true }}>
              <MapCard studentName={ShowcaseInfo[0]["studentName"]}
                        headshot={ShowcaseInfo[0]["headshot"]}
                        school={ShowcaseInfo[0]["school"]}
                        schoolStatus={ShowcaseInfo[0]["schoolStatus"]}
                        schoolLogo={ShowcaseInfo[0]["schoolLogo"]}
                        apartment={ShowcaseInfo[0]["apartment"]}
                        location={ShowcaseInfo[0]["location"]}
                        dates={ShowcaseInfo[0]["dates"]}/>
            </motion.div>

            {/* Mobile view */}
            <motion.div
                  className="md:hidden md:flex hover:scale-[1.02] relative z-0 md:translate-y-[-30px] transition duration-500"
                  initial={{ y: 200, opacity: 0 }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0,
                      duration: 0.5,
                      ease: "easeOut",
                    },
                  }}
                  viewport={{ once: true }}>
              <MapCard studentName={ShowcaseInfo[0]["studentName"]}
                        headshot={ShowcaseInfo[0]["headshot"]}
                        school={ShowcaseInfo[0]["school"]}
                        schoolStatus={ShowcaseInfo[0]["schoolStatus"]}
                        schoolLogo={ShowcaseInfo[0]["schoolLogo"]}
                        apartment={ShowcaseInfo[0]["apartment"]}
                        location={ShowcaseInfo[0]["location"]}
                        dates={ShowcaseInfo[0]["dates"]}/>
            </motion.div>

            <motion.div
                  className="hidden md:flex hover:scale-[1.02] relative z-0 md:translate-y-[-30px] transition duration-500"
                  initial={{ y: 200, opacity: 0 }}
                  whileInView={{
                    y: `-30px`,
                    opacity: 1,
                    transition: {
                      delay: 0,
                      duration: 0.5,
                      ease: "easeOut",
                    },
                  }}
                  viewport={{ once: true }}>
              <MapCard studentName={ShowcaseInfo[1]["studentName"]}
                        headshot={ShowcaseInfo[1]["headshot"]}
                        school={ShowcaseInfo[1]["school"]}
                        schoolStatus={ShowcaseInfo[1]["schoolStatus"]}
                        schoolLogo={ShowcaseInfo[1]["schoolLogo"]}
                        apartment={ShowcaseInfo[1]["apartment"]}
                        location={ShowcaseInfo[1]["location"]}
                        dates={ShowcaseInfo[1]["dates"]}/>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default map
