import React, { useEffect, useState, useCallback } from 'react'
import { useFormSteps } from '../../formStepsContext'
import { ImSpinner8 } from 'react-icons/im'
import dayjs from 'dayjs'
import imageCompression from 'browser-image-compression';
import { useDropzone } from 'react-dropzone';

function AllSet(props) {
  const { setCanGoNext, setCanGoBack, currentPage, setData, data } = useFormSteps()
  const { page } = props;

  // Add a state variable for the uploaded file
  const [profilePicture, setProfilePicture] = useState(data.guest.profilePic || null);
  const [tooSmallWarning, setTooSmallWarning] = useState(false);
  const [loading, setLoading] = useState(false);

  // Turn off too small warning after a few seconds
  useEffect(() => {
    setTimeout(() => {
      setTooSmallWarning(false);
    }, 7000);
  }, [tooSmallWarning]);

  // Handle image upload
  async function handleImageUpload(imageFile) {

    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const compressedPreviewFile = Object.assign(compressedFile, {
        preview: URL.createObjectURL(compressedFile),
      });
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      return compressedPreviewFile;
    } catch (error) {
      console.error("An unexpected error occured while compressing your image(s):", error);
    }
  }

  // Extract file name from signed URL
  function extractFilenameFromUrl(url) {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    return decodeURIComponent(filename).split("?")[0];
  }

  // Fetch existing profile pic from signed URL
  useEffect(() => {
    if (currentPage == page && data.guest.profilePic != '' && typeof data.guest.profilePic === 'string') {
      if (data.guest.profilePic.startsWith("https")) {
        console.log("Fetching profile picture from firebase...");
        setLoading(true);

        const convertSignedUrlToBlobs = async () => {
          let url = data.guest.profilePic;
          const filename = extractFilenameFromUrl(url);
          let response;
          try {
            response = await fetch(url);
            if (!response.ok) {
              throw new Error('Image not found');
            }
          } catch (error) {
            // Also for emulator!
            console.log('ERROR:', error);
            url = "https://i.ibb.co/183KsnR/Group-1-9.png";
            response = await fetch(url);
          }
          const blob = await response.blob();
          const file = new File([blob], filename, { type: blob.type });
          const pseudoFile = {
            file,
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            preview: URL.createObjectURL(file),
          };
          return pseudoFile;
        };

        convertSignedUrlToBlobs()
          .then((pseudoFile) => {
            console.log("Setting loading from firebase to false...");
            setProfilePicture(pseudoFile);
            console.log(pseudoFile);
            setLoading(false);
            setCanGoNext(true);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    }
  }, [data]);

  // Set up the dropzone
  const onDrop = useCallback(async (acceptedFiles) => {

    setLoading(true);

    const file = acceptedFiles[0];

    const checkImageDimensions = (file) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width < 500 || img.height < 300) {
            console.warn("Image too small");
            setTooSmallWarning(true);
            resolve(false);
          } else {
            resolve(true);
          }
        };
      });
    };

    const isValid = await checkImageDimensions(file);
    if (isValid) {
      const compressedPreviewFile = await handleImageUpload(file);
      setProfilePicture(compressedPreviewFile);
      setData({
        ...data,
        guest: {
          ...data.guest,
          profilePic: compressedPreviewFile,
        },
      });
      setCanGoNext(true);
      setLoading(false);
    }
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const [listingDetails, setListingDetails] = useState(
    [
      { name: 'Dates', value: '' },
      { name: 'Region', value: '' },
      { name: 'Price', value: '' },
      { name: 'Who', value: '' },
      { name: 'Roommates', value: '' },
      { name: 'Preferences', value: '' },
    ]
  );

  useEffect(() => {
    // Re-enable the back button
    if (currentPage == page) {
      setCanGoBack(true);
    }
  });

  // Get data from firestore
  useEffect(() => {

    // Create a copy of the current listingDetails state
    let newListingDetails = [...listingDetails];

    // Get other details
    if (data.guest) {
      // Region and radius
      newListingDetails[1].value = "Looking for " + data.guest.bedrooms.join(", ") + " bedrooms within " + data.guest.radius + " miles of " + data.guest.region;

      // Price
      newListingDetails[2].value = "Up to $" + data.guest.price.toLocaleString();

      // Who
      if (data.guest.who == "Just Myself") {
        newListingDetails[3].value = "For just myself";
      } else {
        const males = data.guest.roommates.male;
        const females = data.guest.roommates.female;
        const nonbinaries = data.guest.roommates.nonbinary;
        const total = (males + females + nonbinaries).toString();
        if (total > 1) {
          newListingDetails[3].value = "For myself and others, totaling " + total + " people";
        } else {
          newListingDetails[3].value = "For myself and others, totaling " + total + " person";
        }
      }

      // Roommates
      if (data.guest.sharedPref.includes("Shared place")) {

        const selectedGenders = [];
        if (data.guest.genderPref.includes("Male")) {
          selectedGenders.push("males")
        }
        if (data.guest.genderPref.includes("Female")) {
          selectedGenders.push("females")
        }
        if (data.guest.genderPref.includes("Non-binary")) {
          selectedGenders.push("nonbinaries")
        }

        if (data.guest.genderPref.length == 1) {
          newListingDetails[4].value = "Looking to live with " + selectedGenders[0]
        } else if (data.guest.genderPref.length == 2) {
          newListingDetails[4].value = "Looking to live with " + selectedGenders[0] + " and " + selectedGenders[1]
        } else if (data.guest.genderPref.length == 3) {
          newListingDetails[4].value = "Looking to live with " + selectedGenders[0] + ", " + selectedGenders[1] + ", and " + selectedGenders[2]
        }
      }
    }

    // Check if start date exists and format it
    let startDate;
    if (data.guest.dates.start) {
      if (data.guest.dates.start instanceof Date) {
        startDate = dayjs(data.guest.dates.start).format('MMM D, YYYY');
      } else {
        startDate = dayjs(new Date(data.guest.dates.start.toDate())).format('MMM D, YYYY');
      }
    }

    // Check if end date exists and format it
    let endDate;
    if (data.guest.dates.end) {
      if (data.guest.dates.end instanceof Date) {
        endDate = dayjs(data.guest.dates.end).format('MMM D, YYYY');
      } else {
        endDate = dayjs(new Date(data.guest.dates.end.toDate())).format('MMM D, YYYY');
      }
    }

    newListingDetails[0].value = "From " + startDate + ' to ' + endDate;

    // Set the updated listingDetails state
    setListingDetails(newListingDetails);
  }, [data]);

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center md:justify-center justify-start pt-[80px] md:pt-0 mb-[300px]'>
        <div className={`fixed mt-[95px] ${tooSmallWarning ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"} z-50 bg-white flex flex-row items-center justify-center gap-2 border-2 border-yellow-500 rounded-full transition-all duration-600 top-0 py-3 px-5 `}>
          <i className='text-yellow-500 fa-solid fa-triangle-exclamation'></i>
          <p>Your image was too small and could not be uploaded</p>
        </div>
        <div className='w-full md:w-[600px] md:max-h-[550px] pb-[80px] md:pb-0 pt-10 px-5 md:px-0'>
          <div className='w-full h-full rounded-3xl flex flex-col items-center justify-center relative overflow-hidden'>

            <div className='w-full py-2 text-left'>
              <h2 className='text-[2rem] my-0 py-0 font-medium'>Add a profile picture and wrap up</h2>
              <p className='opacity-50 pb-3'>Make sure everything looks correct</p>
            </div>

            <div className='w-full flex flex-col md:flex-row md:overflow-y-auto gap-8'>

              {/* Left */}
              <div className='w-full sm:w-2/5 h-full flex md:overflow-y-auto max-h-[calc(100vh-80px)]'>
                <div className={`cursor-pointer overflow-hidden flex justify-center items-center text-center ${(!profilePicture || loading) && "border-2"} w-[12rem] h-[12rem] rounded-full`} {...getRootProps()}>
                  {loading ? (
                    <ImSpinner8 className="animate-spin text-gray-400 text-4xl" />
                  ) : profilePicture ? (
                    <img className="w-full object-cover h-full" src={profilePicture.preview} alt="Profile picture"/>
                  ) : (
                    <>
                      <i className="text-4xl fa-solid fa-image"></i>
                      <input {...getInputProps()} />
                    </>
                  )}

                </div>
                {
                  isDragActive &&
                    <p>Drag 'n' drop profile picture here, or click to select picture</p>
                }
              </div>

              {/* Right */}
              <div className='w-full sm:w-3/5 h-full flex md:overflow-y-auto max-h-[calc(100vh-80px)]'>
                <div className='h-max w-full'>
                  <div className="w-full text-center flex bg-gray-100 rounded-full px-4 py-2 my-1 flex-row items-center gap-2">
                    <p className="font-semibold w-full">Your details</p>
                  </div>
                  {listingDetails.map((detail, index) => (
                    <div className='w-full' key={index}>
                      <div className='p-1'>
                        <p>{detail["value"]}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AllSet
