import React, { useState, useEffect } from 'react'
import Sidebar from './sidebar.jsx'
import { Navigate } from 'react-router-dom';
import { useDashboard } from './DashboardContext'
import { useAuth } from '../auth/AuthContext'
import Logo from '@components/logo'
import { ImSpinner8 } from 'react-icons/im'
import { AnimatePresence, motion } from 'framer-motion'
import AvatarMenu from './avatarMenu'
import UserToggle from './userToggle'

import HostMatches from './pages/host/matches.jsx'

import GuestMatches from './pages/guest/matches.jsx'

function dashboard() {

  // Get current user and logout from auth
  const { currentUser, getValidation } = useAuth();
  const { currentTab, availableTypes, userType, userData, readyToRender, tabs } = useDashboard();
  const [loggedOut, setLoggedOut] = useState(false);
  const [verified, setVerified] = useState(null);

  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    if (availableTypes) {
      setShowToggle(availableTypes.includes("Host") && availableTypes.includes("Guest"));
    }
  }, [availableTypes]);

  useEffect(() => {
    async function checkValidation(uid) {
      const result = await getValidation(uid);
      setVerified(result);
    }

    if (currentUser) {
      checkValidation(currentUser.uid);
    }
  }, [currentUser]);

  return (
    <>

      {/* Navigate on log out */}
      {loggedOut ? <Navigate to="/login"/> : <></>}
      {verified == false ? <Navigate to="/verifyemail"/> : <></>}

      {verified != null &&
        <AnimatePresence mode="wait">
          {readyToRender ? (
            // Dashboard
            <motion.div
              key="ready"
              className='bg-gray-100 relative h-screen w-screen'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.35 }}
            >

              {/* Toggle to change from host to guest or vise versa */}
              {showToggle &&
                <div className='absolute p-10 right-0 bottom-0'>
                  <UserToggle/>
                </div>
              }

              <>
                {/* Sidebar */}
                <div className='fixed bg-white w-[225px] h-full shadow-hostU-shadow-apple'>
                  <Sidebar/>
                </div>

                {/* Avatar Menu */}
                <div className='absolute p-10 z-50 right-0'>
                  <AvatarMenu/>
                </div>

                {/* Dashboard */}
                {tabs.map(({ name, component: Component }) => {
                  if (name == currentTab["name"]) {
                    return (
                      <motion.div
                        className='ml-[225px] p-10'
                        key={name}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.35 }}
                      >
                        <Component/>
                      </motion.div>
                    )
                  }
                })}
              </>
            </motion.div>
          ) : (
            // Loading screen
            <motion.div
              key="loading"
              className='bg-white flex justify-center flex-col gap-4 items-center h-screen w-screen'
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.35 }}
            >
              <Logo textSize="text-3xl"
                    uColor="text-white"
                    bgWidth={12}/>
              <ImSpinner8 className="animate-spin text-black text-2xl" />
            </motion.div>
          )}
        </AnimatePresence>
      }
    </>
  )
}

export default dashboard
