import React, { useEffect, useState } from 'react';
import { useFormSteps } from '../../formStepsContext';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import { ImSpinner8 } from 'react-icons/im'

function Pictures(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data, setData } = useFormSteps();
  const { page } = props;

  const [pictures, setPictures] = useState([]);

  const [gridDropzones, setGridDropzones] = useState(new Array(6).fill(null));

  const [tooSmallWarning, setTooSmallWarning] = useState(false);
  const [loadingIndices, setLoadingIndices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [loadingFromFirebase, setLoadingFromFirebase] = useState(false);

  // Turn off too small warning after a few seconds
  useEffect(() => {
    setTimeout(() => {
      setTooSmallWarning(false);
    }, 7000);
  }, [tooSmallWarning]);

  // Handle image upload
  async function handleImageUpload(imageFile) {

    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const compressedPreviewFile = Object.assign(compressedFile, {
        preview: URL.createObjectURL(compressedFile),
      });
      // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      return compressedPreviewFile;
    } catch (error) {
      console.error("An unexpected error occured while compressing your image(s):", error);
    }
  }

  const { getRootProps,
          getInputProps,
          acceptedFiles,
          isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
    },
    onDrop: async (acceptedFiles) => {
      const validFiles = [];

      const checkImageDimensions = (file) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            if (img.width < 500 || img.height < 300) {
              console.warn("Image too small");
              setTooSmallWarning(true);
              resolve(false);
            } else {
              resolve(true);
            }
          };
        });
      };

      for (let file of acceptedFiles) {
        const isValid = await checkImageDimensions(file);
        if (isValid) {
          const previewFile = Object.assign(file, {
            preview: URL.createObjectURL(file),
          });

          validFiles.push(previewFile);

          setLoadingIndices((prevLoadingIndexes) => [
            ...prevLoadingIndexes,
            pictures.length + validFiles.length - 1,
          ]);

          const compressedPreviewFile = await handleImageUpload(file);

          validFiles.pop();
          validFiles.push(compressedPreviewFile);

          setLoadingIndices((prevLoadingIndexes) =>
            prevLoadingIndexes.filter(
              (index) => index !== pictures.length + validFiles.length - 1
            )
          );
          setPictures((prevPictures) => [...prevPictures, compressedPreviewFile]);
        }
      }
    },
  });

  // Set data
  useEffect(() => {
    console.log("Pictures:", pictures);
    setData({
      ...data,
      host: {
        ...data.host,
        pictures: pictures,
      },
    });

    if (pictures.length > 3) {
      setCanGoNext(true);
    } else {
      setCanGoNext(false);
      setIsLoading(false);
      setGridDropzones(new Array(6).fill(null));
    }
  }, [pictures]);

  // Set can go back to true
  useEffect(() => {
    if (currentPage === page) {
      setCanGoBack(true);
    }
  });

  // If
  useEffect(() => {
    if (!data.host.pictures.some(picture => typeof picture === 'string')) {
      setPictures(data.host.pictures);
    }
  }, []);

  // Extract file name from signed URL
  function extractFilenameFromUrl(url) {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    return decodeURIComponent(filename).split("?")[0];
  }

  const convertSignedUrlsToBlobs = () => {
    const promises = data.host.pictures.map(async (url) => {
      const filename = extractFilenameFromUrl(url);
      let response;
      try {
        response = await fetch(url);
        if (!response.ok) {
          throw new Error('Image not found');
        }
      } catch (error) {
        // Also for emulator!
        console.log('ERROR:', error);
        url = "https://i.ibb.co/183KsnR/Group-1-9.png";
        response = await fetch(url);
      }
      const blob = await response.blob();
      const file = new File([blob], filename, { type: blob.type });
      const pseudoFile = {
        file,
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        preview: URL.createObjectURL(file),
      };
      return pseudoFile;
    });

    Promise.all(promises)
      .then((pseudoFiles) => {
        console.log("Setting loading from firebase to false...");
        setLoadingFromFirebase(false);
        setPictures((prevPictures) => [...prevPictures, ...pseudoFiles]);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  // Fetch existing images from signed URLs
  useEffect(() => {
    if (data.host.pictures.length > 0 && data.host.pictures.some(picture => typeof picture === 'string')) {
      if (data.host.pictures.some(url => url.startsWith("https"))) {
        console.log("Fetching pictures from firebase...");
        setLoadingFromFirebase(true);
        convertSignedUrlsToBlobs();
      }
    }
  }, [data]);

  // If pictures are added, add dropzones
  useEffect(() => {
    const allDropzonesHaveImages = pictures.length >= gridDropzones.length;
    const lastDropzoneHasImage = Boolean(pictures[gridDropzones.length - 1]);
    const extraDropzonesNeeded = pictures.length - gridDropzones.length + 1;
    if (allDropzonesHaveImages && lastDropzoneHasImage) {
      setGridDropzones((prevGridDropzones) => [
        ...prevGridDropzones,
        ...Array(extraDropzonesNeeded).fill(null),
      ]);
    }
  }, [pictures]);

  // Add dropzone
  const addDropzone = (e) => {
    const emptyDropzoneIndex = gridDropzones.findIndex((_, index) => !pictures[index]);

    if (emptyDropzoneIndex !== -1) {
      const emptyDropzoneInput = document.querySelector(`input[name="dropzone-${emptyDropzoneIndex}"]`);

      // Add change event listener to wait for the file upload
      emptyDropzoneInput.addEventListener("change", () => {
        // Execute the rest of the function after the file is uploaded
        if (allDropzonesHaveImages && lastDropzoneHasImage) {
          setGridDropzones((prevGridDropzones) => [...prevGridDropzones, null]);
        }
      });

      emptyDropzoneInput?.click();
    }
  };

  // Remove images
  const removeImage = (index) => {
    const newPictures = pictures.filter((_, i) => i !== index);
    setPictures(newPictures);

    // Remove any empty dropzones if the length of dropzones > 6
    if (gridDropzones.length > 6) {
      const newGridDropzones = gridDropzones.filter((_, i) => i !== gridDropzones.length - 1);
      setGridDropzones(newGridDropzones);
    }
  };

  // Grid of dropzones, as JSX
  const dropzoneGrid = gridDropzones.map((_, index) => {
    const imagePreview = pictures[index]?.preview;
    const imageName = pictures[index]?.name;
    const isAddMoreDropzone = index === gridDropzones.length - 1;
    const currentLoading = loadingIndices.includes(index);
    if (currentLoading && !isLoading) {
      setIsLoading(true);
    }

    return (
      <div
        key={index}
        className={`${
          index === 0 ? 'lg:col-span-2 lg:row-span-2' : ''
        } w-full h-full`}
      >

        {

        // Loading wheel
        currentLoading ? (
          <div className={`w-full ${index == 0 ? "h-[180px] lg:h-[375px]" : "h-[180px]"} rounded-lg border-2 border-black border-opacity-20 flex items-center justify-center`}>
            <ImSpinner8 className="animate-spin text-gray-400 text-4xl" />
          </div>
        ) :

        // Images
        imagePreview ? (
          <div className="relative">
            <img
              src={imagePreview}
              alt={imageName}
              className={`w-full ${index == 0 ? "h-[180px] lg:h-[375px]" : "h-[180px]"} object-cover rounded-lg`}
            />
            <button
              onClick={() => removeImage(index)}
              className="absolute bottom-0 right-0 bg-black bg-opacity-50 font-semibold px-3 py-2 text-white text-sm rounded-tl rounded-br"
            >
              Remove
            </button>
          </div>
        ) :

        // Plus button
        isAddMoreDropzone ? (
          <div className='border-2 w-full h-[180px] border-black border-opacity-20 rounded-lg'>
            <div
              {...getRootProps({
                className: `${isDragActive
                  ? 'border-hostU-blue-400'
                  : 'border-black'} w-full h-full flex cursor-pointer justify-center`,
              })}
            >
              <input {...getInputProps({ name: `dropzone-${index}` })} />
              <button onClick={addDropzone} className='w-full h-full -translate-y-[1px] flex items-center justify-center text-4xl text-gray-400 rounded-lg'>
                +
              </button>
            </div>
          </div>
        )

        // Empty dropzones
        : (
          <div
            {...getRootProps({
              className: `${isDragActive
                ? 'border-hostU-blue-400'
                : 'border-black border-opacity-20'} border-2 w-full h-[180px] rounded-lg flex cursor-pointer justify-center`,
            })}
          >
            <input {...getInputProps({ name: `dropzone-${index}` })} />
            <div
              className={`${isDragActive &&
                'text-hostU-blue-400'} w-full h-[180px] flex justify-center items-center`}
            >
                {isDragActive
                  ? <p>Release to drop the photo(s) here</p>
                  : <i className='text-gray-400 fa-solid fa-image text-4xl'></i>
                }
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <>
      <div className={`w-screen flex flex-col items-center ${(pictures.length > 0 || isLoading || loadingFromFirebase) ? "mt-[80px]" : "h-screen justify-center"} px-10 pt-10 pb-24 md:pb-10`}>
        <div className={`fixed mt-[95px] ${tooSmallWarning ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"} z-50 bg-white flex flex-row items-center justify-center gap-2 border-2 border-yellow-500 rounded-full transition-all duration-600 top-0 py-3 px-5 `}>
          <i className='text-yellow-500 fa-solid fa-triangle-exclamation'></i>
          <p>One or more of your images were too small and could not be uploaded</p>
        </div>
        <div className={`w-full ${(pictures.length > 0 || isLoading || loadingFromFirebase) ? "lg:w-[800px]" : "lg:w-[600px]" } pt-10 px-5 lg:px-0`}>
          <div className='w-full text-left py-4'>
            <h2 className='text-[2rem] my-0 py-0 font-medium'>Add some photos</h2>
            <p className='opacity-50 pb-3'>
              Upload at least 4 images. High-quality, well-lit, and in-focus images attract more guests.
            </p>
          </div>
          <div className='w-full flex flex-col justify-center'>
            {(pictures.length < 1 && !isLoading && !loadingFromFirebase) ? (
              // Initial Dropzone
              <div
                {...getRootProps({ className: `${isDragActive ? 'border-hostU-blue-400' : 'border-black border-opacity-20'} border-2 w-full rounded-lg flex cursor-pointer justify-center py-24 dropzone` })}
              >
                <input {...getInputProps()} />
                <div className={`${isDragActive && 'text-hostU-blue-400'} w-full flex justify-center flex-col gap-4 items-center`}>
                  <i className='fa-solid fa-images text-4xl'></i>
                  <p className='p-4 text-center'>
                    {isDragActive
                      ? 'Release to drop the photo(s) here'
                      : 'Drag some photos here, or click to select from your device'
                    }
                  </p>
                </div>
              </div>
            ) : loadingFromFirebase ? (
              <div className='w-full h-full flex justify-center items-center'>
                <ImSpinner8 className="animate-spin text-gray-400 text-4xl" />
              </div>
            ) : (
              // Individual dropzones
              <div className="w-full grid grid-cols-2 lg:grid-cols-3 auto-rows-min gap-4">
                {dropzoneGrid}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Pictures;
