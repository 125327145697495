import React, { useEffect, useState } from 'react'
import { useFormSteps } from '../../formStepsContext'

function Amenities(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data, setData } = useFormSteps();
  const { page } = props;

  const [selected, setSelected] = useState(data.host.amenities || {});
  const [options, setOptions] = useState([
    "Furnished", "Private Bathroom", "Air Conditioning", "Heater", "Ceiling Fan",
    "WiFi", "Washer", "Dryer", "Refrigerator", "Dishwasher", "Freezer", "Oven",
    "Patio", "Backyard", "Closet Space", "Pool Access", "Entry Security",
    "Security System"
  ]);

  useEffect(() => {
    if (currentPage === page) {
      setCanGoBack(true);
      setCanGoNext(true);
    }
  });

  useEffect(() => {
    setData({
      ...data,
      host: {
        ...data.host,
        amenities: selected,
      }
    })
  }, [selected]);

  const handleSelected = (option) => {
    setSelected(prevSelected => ({
      ...prevSelected,
      [option]: !prevSelected[option]
    }));
  }

    // If fetched from firestore, update it
    useEffect(() => {
      if (data.host.amenities) {
        setSelected(data.host.amenities);
      }
    }, [data]);

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center sm:justify-center justify-start pt-[80px] sm:pt-0 mb-[300px]'>
        <div className='w-full sm:w-[600px] pt-10 px-5 sm:px-0'>
          <div className='w-full text-left py-6'>
            <h2 className='text-[2rem] my-0 py-0 font-medium'>Show off your listing with amenities</h2>
            <p className='opacity-50 pb-3'>Attract more guests and boost your ratings</p>
          </div>
          <div className='w-full flex-wrap flex justify-center flex-row gap-4'>
            {options.map((option, index) => (
              <button
                className={`px-6 py-3 ${selected[option] ? 'bg-hostU-blue-400 text-white font-semibold' : 'bg-white border border-black border-opacity-20'} transition duration-200 w-max rounded-full text-center`}
                onClick={() => handleSelected(option)}
                key={index}
              >
                <p>{option}</p>
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Amenities;
