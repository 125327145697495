import React, { useState } from 'react';
import { ref, set, get } from "firebase/database";

function LocationBanner(props) {
  const [email, setEmail] = useState('');

  const [invalidEmail, setInvalidEmail] = useState(false);

  const [city, setCity] = useState('');

  const [success, setSuccess] = useState(false);

  const cityOptions = [
    'New York',
    'Boston',
    'Washington DC',
    'Los Angeles',
    'San Francisco',
  ];

  // Update database when component is mounted
  const handleSubmit = async () => {

    // Verify email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      console.error('Invalid email address');
      setInvalidEmail(true);
      return;
    } else {
      setInvalidEmail(false);
    }

    const uidRef = ref(props.database, `cityRequests/${city}`);
    const snapshot = await get(uidRef);
    const currentValue = snapshot.val() || [];
    console.log(currentValue);
    const updatedValue = [...currentValue, email];
    set(uidRef, updatedValue)
      .then(() => {
        console.log('Match UID object updated successfully');
        setSuccess(true);
        setEmail('');
        setCity(city);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      })
      .catch((error) => {
        console.error('Error updating opposite UID object: ', error);
      });
  };

  return (
    <div className="w-full sm:px-4 py-12 flex bg-hostU-blue-400-opacity-20 justify-center">
      <div className="w-full max-w-[1200px] h-full px-4">
        <div className='pt-5 flex flex-row flex-wrap items-center justify-center mx-auto px-12 rounded-3xl'>

          {/* Heading */}
          <div className="font-bold text-center text-2xl sm:text-4xl sm:px-4 pt-2 pb-4">
            <p>Choose our next city</p>
          </div>

          {/* Second line */}
          <div className="flex flex-col lg:flex-row flex-wrap items-center w-max px-4">

            {/* Inputs */}
            <div className="rounded-3xl flex flex-col sm:flex-row flex-wrap gap-[2px]">

              {/* City */}
              <div className='sm:w-max relative flex flex-row items-center border-2 rounded-t-3xl sm:rounded-tr-none sm:rounded-l-3xl'>

                {/* Drop down */}
                <select
                  className="appearance-none outline-none w-full sm:w-[12rem] rounded-t-3xl sm:rounded-tr-none sm:rounded-l-3xl px-4 pr-4 py-2"
                  tabIndex={8}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder='Select a city'
                  >
                  <option disabled selected value="" className='text-gray-500'>
                    Select a city
                  </option>
                  {cityOptions.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>

                {/* Arrow */}
                <svg
                  className="h-4 w-4 fill-current text-black absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 13l6-6H4z"
                  />
                </svg>
              </div>

              {/* Email */}
              <input
                className="border-2 outline-none rounded-b-3xl sm:rounded-b-none px-4 py-2"
                type="email"
                placeholder="Email"
                value={email}
                tabIndex={8}
                onChange={(e) => setEmail(e.target.value)}
              />

              {/* Button */}
              <button
                className="bg-hostU-blue-400 hover:opacity-80 mt-4 sm:mt-0 transition text-white font-bold rounded-3xl sm:rounded-l-none sm:rounded-r-3xl px-5 py-2"
                type="submit"
                tabIndex={9}
                onClick={handleSubmit}
              >
                Get Notified
              </button>
            </div>
          </div>
          <p className={`mt-0 font-bold text-hostU-green-400 ${success ? "opacity-100" : "opacity-0"} transition-opacity duration-500 ease-in-out`}>Thanks! We'll notify you when we expand to your city!</p>
          <p className={`mt-0 font-bold text-red-500 transition ${invalidEmail ? "opacity-100" : "opacity-0"} transition-opacity duration-500 ease-in-out`}>Invalid email. Please try again</p>
        </div>
      </div>
    </div>
  );
}

export default LocationBanner;
