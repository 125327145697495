import React, { useEffect, useState } from 'react'
import { useFormSteps } from '../../formStepsContext'
import { Slider as RangeSlider } from "@mui/material";
import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);
import { Bar } from 'react-chartjs-2'

function Price(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data, setData } = useFormSteps()
  const { page } = props;

  const [price, setPrice] = useState(1000);
  const min = 10;
  const max = 5000;

  useEffect(() => {
    // Re-enable the back button
    if (currentPage == page) {
      setCanGoBack(true);

      // If the page just loaded and nothing has been interacted with,
      // disable the next button
      if (!price) {
        setCanGoNext(false);
      } else {
        setCanGoNext(true);
      }
    }
  });

  // Total bins for graph
  const bins = 20;

  // Total distribution (light and dark)
  const [fullDistribution, setFullDistribution] = useState([]);
  const [colors, setColors] = useState([]);

  // Simulate a price distribution
  useEffect(() => {
    console.log("Creating distribution");
    const updatedDistribution = [];

    // Proportion of the total height for each bin
    const proportions = [0.21, 0.51, 0.68, 0.72, 0.87,
                         0.87, 0.73, 0.63, 0.32, 0.27,
                         0.24, 0.23, 0.12, 0.07, 0.04,
                         0.01, 0.01, 0.05, 0.01, 0.00];

    for (let i = 0; i < bins; i++) {
      updatedDistribution.push(proportions[i]);
    }

    setFullDistribution((prevDistribution) => [...prevDistribution, ...updatedDistribution]);
  }, []);

  useEffect(() => {
    if (data.guest.price) {
      setPrice(data.guest.price);
    }
  }, [data]);

  useEffect(() => {
    const updatedColors = [];
    for (let i = 0; i < bins; i++) {
      const priceRatio = price / max;
      const binsRatio = i / bins;
      updatedColors.push(binsRatio < priceRatio ? '#DBDBDB' : '#EFEFEF');
    }
    setColors(updatedColors);
  }, [price, fullDistribution]);

  // Data for chart
  const dataForChart = {
    labels: Array.from({ length: bins }, (_, i) => (i + 1) * min),
    datasets: [
      {
        data: fullDistribution,
        backgroundColor: colors,
      }
    ],
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
    setData({...data, guest: { ...data.guest, price: e.target.value }});
  }

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center overflow-y-auto justify-start mt-[80px] sm:mt-0 sm:justify-center sm:pt-0'>
        <div className='w-full sm:w-[600px] mt-5 py-5 overflow-visible px-5 sm:px-0'>
          <div className='w-full py-2 text-left'>
            <h2 className='text-[2rem] my-0 py-0 font-medium'>What are you looking to pay?</h2>
            <p className='opacity-50 pb-3'>Set a maximum price by dragging the slider</p>
          </div>
          <div className='w-full translate-y-[15px] mx-auto'>
            <Bar
              data={dataForChart}
              options={{
                animation: false,
                maintainAspectRatio: true,
                aspectRatio: 4,
                responsive: true,
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                  legend: {
                    display: false,
                  },
                },
                interaction: {
                  mode: 'nearest',
                  intersect: false
                },
                elements: {
                  bar: {
                    borderWidth: 0,
                    borderRadius: 0,
                    maxBarThickness: max / bins,
                  },
                },
              }}
            />
          </div>
          <div className='w-full mx-auto'>
            <RangeSlider
              value={price}
              min={min}
              max={max}
              onChange={handlePriceChange}
              getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
              step={1}
              sx={{
                color: '#2ebeb5',
                height: 3,
                padding: '13px 0',
                '& .MuiSlider-thumb': {
                  height: 27,
                  width: 27,
                  backgroundColor: '#fff',
                  border: '1px solid currentColor',
                  '&:hover': {
                    boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
                  },
                  '& .airbnb-bar': {
                    height: 9,
                    width: 1,
                    backgroundColor: 'currentColor',
                    marginLeft: 1,
                    marginRight: 1,
                  },
                },
                '& .MuiSlider-track': {
                  height: 3,
                },
                '& .MuiSlider-rail': {
                  color: '#d8d8d8',
                  opacity: 1,
                  height: 3,
                },
              }}
            />
          </div>
          <div className='w-full flex flex-col gap-2 items-center justify-center py-5'>
            <div className='w-max'>
              <p className='opacity-50'>Up to</p>
              <div className="flex flex-row border-b border-black border-opacity-25 justify-left items-center gap-2">
                <p>$</p>
                <input
                  onChange={handlePriceChange}
                  value={price.toLocaleString()}
                  className="text-3xl py-2 w-20 text-center outline-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Price
