import React from "react";

function FAQs() {

  const faqs = [
    {
      question: "What is hostU?",
      answer:
        "hostU is a platform that facilitates medium-term shared housing rentals within the university community, connecting students and faculty in need of housing with those looking to rent out their space. Our matching algorithm pairs guests with hosts based on preferences and needs, notifying them of potential matches. Once connected, guests and hosts can communicate and make arrangements through the platform.",
    },
    {
      question: "Who is hostU for?",
      answer:
        "hostU is exclusively curated for university undergraduates, graduates, and faculty with a valid .edu email address who are looking for medium-term housing solutions, such as when studying abroad or interning in a new city. Both hosts and guests can use hostU to connect with each other and facilitate their housing needs.",
    },
    {
      question: "How does it work?",
      answer:
        'To join hostU, click on the "Host" or "Guest" button and fill out the short survey with your housing needs. Our algorithm will verify university affiliation and connect Hosts and Guests with matching criteria. Guests will receive an Email with all matching listings and have the option to request contact information for listings, and hosts will receive requests and contact information to get in touch with guests.',
    },
    {
      question: "How much does hostU cost?",
      answer:
        "At the moment, hostU is completely free to use. As we continue to develop and grow the platform, we may introduce fees to cover our operational costs and make further improvements. However, users will neither be charged nor asked for payment information at this time. We will always strive to keep our fees reasonable and transparent, and will inform our users about any changes to our pricing structure.",
    },
  ];

  return (
    <section id="faq" className="bg-gray-100 border-b">
      <div className="max-w-[1200px] mx-auto">
        <div className="mx-auto max-w-7xl py-12 sm:py-20 px-4 sm:px-4">
          <h2 className="text-3xl font-bold tracking-tight">
            Frequently asked questions
          </h2>
          <p className="pt-1 leading-6 text-gray-500">
            For further questions, email us at{" "}
            <a href="mailto:hello@joinhostu.com"
               className="font-semibold"
               tabIndex={12}>
              hello@joinhostu.com
            </a>
          </p>
          <div className="mt-6 border-t border-hostU-blue-400 border-opacity-75 pt-10">
            <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 md:space-y-0">
              {faqs.map((faq, index) => (
                <div key={index}>
                  <dt className="text-lg font-medium leading-6">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQs;
