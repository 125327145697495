import React from 'react'

function Logo(props) {

  const widths = {
    0: "w-0",
    1: "w-1",
    2: "w-2",
    3: "w-3",
    4: "w-4",
    5: "w-5",
    6: "w-6",
    7: "w-7",
    8: "w-8",
    9: "w-9",
    10: "w-10",
    11: "w-11",
    12: "w-12",
    14: "w-14",
    16: "w-16",
    20: "w-20",
    24: "w-24",
    28: "w-28",
    32: "w-32",
    36: "w-36",
  }

  const heights = {
    0: "h-0",
    1: "h-1",
    2: "h-2",
    3: "h-3",
    4: "h-4",
    5: "h-5",
    6: "h-6",
    7: "h-7",
    8: "h-8",
    9: "h-9",
    10: "h-10",
    11: "h-11",
    12: "h-12",
    14: "h-14",
    16: "h-16",
    20: "h-20",
    24: "h-24",
    28: "h-28",
    32: "h-32",
    36: "h-36",
  }

  return (
    <>
      <div className={`font-black flex flex-row ${props.textSize} items-center`}>
        <p className={`${props.hostColor}`}>host</p>
        <div className={`${widths[props.bgWidth]} ${heights[props.bgWidth]} ml-[0.4rem] flex justify-center items-center rounded-[50%] bg-hostU-blue-gradient`}>
          <p className={`${props.uColor}`}>U</p>
        </div>
      </div>
    </>
  )
}

export default Logo
