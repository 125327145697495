import React, { useEffect, useState } from 'react'
import BG from '@images/skyline.png'
import { motion } from 'framer-motion'
import { ref, get, set } from "firebase/database";
import LandingNavbar from '@components/landing/navbar'
import { MdError } from "react-icons/md";

function hostDecision(props) {

  const [alreadySubmitted, setAlreadySubmitted] = useState(false);

  // Update database when component is mounted
  useEffect(() => {
    const uidRef = ref(props.database, `hostDecisions/${props.hostsId}/${props.guestsId}`);
    get(uidRef).then((snapshot) => {
      // If the entry doesn't exist yet, add it
      if (!snapshot.exists()) {
        const accept = props.accept ? true : false;
        set(uidRef, accept)
          .then(() => {
            console.log('Match UID object updated successfully');
          })
          .catch((error) => {
            console.error('Error updating opposite UID object: ', error);
          });
      } else {
        // If the entry already exists, don't send new email, and render "You already submitted"
        setAlreadySubmitted(true);
      }
    })
  }, []);

  return (
    <section className="w-full bg-white flex justify-center bg-cover bg-center bg-norepeat h-screen overflow-hidden"
             style={{ backgroundImage: `url(${BG})`}}>

      <div className='fixed w-full'>
        <LandingNavbar />
      </div>

      <div className="px-2 md:px-10 z-10 relative w-[1350px] justify-center mt-[12%] mb-[10%] items-center flex flex-wrap">

        {/* Text */}
        <div className="w-full px-4 sm:px-8 lg:px-12 text-center relative h-max">

          <motion.div className='pb-4 mx-auto w-max'
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0 }}>
            {!alreadySubmitted ? (
              // <Checkmark size="large" color="#4BB6E3"/>
              <></>
            ) : (
              <MdError size={70} color="red"/>
            )}
          </motion.div>

          {/* Heading */}
          <motion.div className="w-full flex flex-row justify-center gap-3 items-center pb-3 text-3xl sm:text-4xl"
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.05 }}>
                        {!alreadySubmitted ? (
                            <>
                              {props.accept &&
                                <p className='font-bold'>Guest accepted!</p>
                              }
                              {props.deny &&
                                <p className='font-bold'>Got it</p>
                              }
                            </>
                          ) : (
                            <>
                              <p className='font-bold'>Too many submissions</p>
                            </>
                          )
                        }
          </motion.div>

          {/* Subheading */}
          <motion.div className='w-full md:w-3/4 max-w-[600px] px-4 mx-auto flex flex-col text-gray-500 pb-10 text-lg'
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.1 }}>
                        {!alreadySubmitted ? (
                            <>
                              {props.accept &&
                                <>
                                  <p>We'll get you in touch</p>
                                  <p>You can close this tab</p>
                                </>
                              }
                              {props.deny &&
                                <>
                                  <p className='md:w-1/2 mx-auto'>Your contact information won't be shared, and we'll get to finding you more matches</p>
                                  <p className='mt-5'>You can close this tab</p>
                                </>
                              }
                            </>
                          ) : (
                            <>
                              <p className='md:w-3/4 mx-auto'>Seems like you already submitted a previous version of this form.</p>
                              <p className='mt-5 md:w-3/4 mx-auto'>If you changed your mind or need to contact us, please email us at support@joinhostu.com</p>
                              <p className='mt-5'>You can close this tab</p>
                            </>
                          )
                        }
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default hostDecision
