import React from 'react'

import Check from '@images/check.png'
import Star from '@images/star.svg'
import Triangle from '@images/down-triangle.png'

function mapCard(props) {
  return (
    <div className='m-4 flex flex-col items-center'>
      <div className="bg-white rounded-3xl">
        <div className="p-2">

          {/* Header */}
          <div className="p-4 flex items-center flex-row justify-between">
            <div className="flex flex-row gap-2 items-center justify-center">

              {/* Headshot */}
              <img src={props.headshot} alt="Headshot" className="w-[50px] h-[50px]"/>

              {/* Info */}
              <div>
                <div className='flex flex-row gap-1 items-center'>
                  <p className="font-bold">{props.studentName}</p>
                  <img src={Check} className="w-[20px] h-[20px]" alt="Verified"/>
                </div>
                <div className='flex flex-row gap-2 text-gray-500'>
                  <p>{props.school}</p>
                  <img src={Star} alt="&#x2022;" className='inline-block w-[8px]'/>
                  <p>{props.schoolStatus}</p>
                </div>
              </div>
            </div>

            {/* School Logo */}
            <img src={props.schoolLogo} alt="" className="hidden sm:inline-block rounded-xl w-[50px] h-[50px]"/>
          </div>

          {/* Apt Picture */}
          <div className="px-4 pb-4">
            <img className="rounded-xl w-[320px] h-[210px]" src={props.apartment} alt="Apartment"/>
          </div>

          {/* Loc & Date */}
          <div className="text-center pb-4">
            <p className="font-bold">{props.location}</p>
            <p className="text-gray-500">{props.dates}</p>
          </div>
        </div>
      </div>
      <img src={Triangle} alt="" className="select-none w-[30px]"/>
    </div>
  )
}

export default mapCard
