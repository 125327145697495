import React, { useState, useEffect } from 'react'
import { useAuth } from '../auth/AuthContext'
import { firestore } from '../../index.js'
import firebase from "firebase/compat/app";
import { doc, getDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { useDashboard } from './DashboardContext'

function AvatarMenu() {

  const navigate = useNavigate();

  const { currentUser, logout } = useAuth();

  const { availableTypes } = useDashboard();
  const [loggingOut, setLoggingOut] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [dropdown, setDropdown] = useState(false);

  const hostFilled = availableTypes.includes("Host");
  const guestFilled = availableTypes.includes("Guest");

  let dropdownOptions;
  if (hostFilled && guestFilled) {
    dropdownOptions = [
      { name: "Log out", action: () => setLoggingOut(!loggingOut) },
    ]
  } else if (hostFilled) {
    dropdownOptions = [
      { name: "Become a guest", action: () => navigate('/setupaccount?page=2&user=guest') },
      { name: "Log out", action: () => setLoggingOut(!loggingOut) },
    ]
  } else if (guestFilled) {
    dropdownOptions = [
      { name: "Become a host", action: () => navigate('/setupaccount?page=2&user=host') },
      { name: "Log out", action: () => setLoggingOut(!loggingOut) },
    ]
  }

  // Log out
  useEffect(() => {
    const handleLogout = async () => {
      window.history.replaceState({}, null, "/login");
      await logout();
    }
    if (loggingOut) {
      handleLogout();
    }
  }, [loggingOut]);

  // Extract file name from signed URL
  function extractFilenameFromUrl(url) {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    return decodeURIComponent(filename).split("?")[0];
  }

  useEffect(() => {
    if (typeof profilePicture === 'string' && profilePicture.startsWith("https")) {
      console.log("Fetching profile picture from firebase...");

      const convertSignedUrlToBlobs = async () => {
        const filename = extractFilenameFromUrl(profilePicture);
        let response;
        let url;
        try {
          response = await fetch(profilePicture);
          if (!response.ok) {
            throw new Error('Image not found');
          }
        } catch (error) {
          // Also for emulator!
          console.log(error);
          url = "https://i.ibb.co/dQVm0H7/Group-1-10.png";
          response = await fetch(url);
        }
        const blob = await response.blob();
        const file = new File([blob], filename, { type: blob.type });
        const pseudoFile = {
          file,
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          preview: URL.createObjectURL(file),
        };
        return pseudoFile;
      };

      convertSignedUrlToBlobs()
        .then((pseudoFile) => {
          setProfilePicture(pseudoFile);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      const convertSignedUrlToBlobs = async () => {
        const url = "https://i.ibb.co/dQVm0H7/Group-1-10.png";
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], 'filename', { type: blob.type });
        const pseudoFile = {
          file,
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          preview: URL.createObjectURL(file),
        };
        return pseudoFile;
      }

      convertSignedUrlToBlobs()
        .then((pseudoFile) => {
          setProfilePicture(pseudoFile);
        })
        .catch((error) => {
          console.error('Error:', error);
        })
    }
  }, [profilePicture])

  // Call the Cloud Function signed URL API
  useEffect(() => {
    const setInitialData = async () => {
      const userRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();

      if (userData.formData.guest.profilePic) {
        const profilePic = userData.formData.guest.profilePic;
        // Call the Cloud Function signed URL API
        const getSignedUrl = firebase.functions().httpsCallable('getSignedUrl');
        const result = await getSignedUrl({ filePath: profilePic });

        const profilePicUrl = result.data.url;

        // Set profile picture as the path
        setProfilePicture(profilePicUrl);
      }
    }
    if (currentUser) {
      setInitialData();
    }
  }, [currentUser]);

  return (
    <div className={`${loggingOut && "pointer-events-none"} z-50 flex flex-row gap-5 items-center`}>
      <div>
        <button
          className='w-max hover:opacity-50 transition duration-400 p-1 h-max relative flex flex-row items-center'
          onClick={() => setDropdown(!dropdown)}
        >
          <p className='pr-2'>{currentUser.displayName}</p>
          <svg width="12" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.79289 8.20711C7.18342 8.59763 7.81658 8.59763 8.20711 8.20711L14.5711 1.84315C14.9616 1.45262 14.9616 0.819457 14.5711 0.428932C14.1805 0.0384078 13.5474 0.0384078 13.1569 0.428932L7.5 6.08579L1.84315 0.428932C1.45262 0.0384078 0.819457 0.0384078 0.428932 0.428932C0.0384078 0.819457 0.0384078 1.45262 0.428932 1.84315L6.79289 8.20711ZM6.5 6.5V7.5H8.5V6.5H6.5Z" fill="#AEAEAE"/>
          </svg>
        </button>
        {dropdown &&
          <div className={`absolute min-w-[120px] bottom-0 flex flex-col ${!(hostFilled && guestFilled) && "translate-y-8"} bg-white`}>
            {dropdownOptions.map((option, index) => {
              return (
                <button
                  className='px-3 hover:opacity-50 transition duration-400 border-b border-black border-opacity-10 py-2'
                  onClick={option["action"]}
                  key={index}
                >
                  <p>{option["name"]}</p>
                </button>
              )
            })}
          </div>
        }
      </div>
      <div className='rounded-full flex flex-col items-center overflow-hidden bg-gray-100'>
        {profilePicture &&
          <img src={profilePicture.preview} className="bg-gray-200 w-16 h-16 object-cover" alt="Profile pic"/>
        }
      </div>
    </div>
  )
}

export default AvatarMenu
