import React from 'react'

function guestCard(props) {
  
  return (
    <div className='min-w-[300px] w-max flex flex-col shadow-hostU-shadow-apple rounded-3xl p-5 bg-white'>

      {/* Heading */}
      <div className='flex flex-row items-center'>
        <div className='rounded-full overflow-hidden bg-gray-200'>
          <img className='w-16 h-16' src={props.headshot} alt="Guest profile pic"/>
        </div>
        <div className='flex flex-col p-4 text-left justify-center items-center'>
          <p className='font-bold w-full text-xl'>{props.name}</p>
          <p className='opacity-75 w-full'>Class of {props.gradYear}</p>
        </div>
      </div>

      {/* Details */}
      <div className='w-full justify-center opacity-75 py-4 flex gap-2 flex-col'>
        <div className='flex flex-row gap-2 items-center'>
          <div className='w-8 flex justify-center'>
            <i className='fa-solid fa-calendar-days'></i>
          </div>
          <p>{props.dateRange}</p>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          <div className='w-8 flex justify-center'>
            <i className='fa-solid fa-users'></i>
          </div>
          <p>{props.roommateCount} roommate{props.roommateCount != 1 && "s"}</p>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          <div className='w-8 flex justify-center'>
            <i className='fa-solid fa-graduation-cap'></i>
          </div>
          <p>{props.school}</p>
        </div>
      </div>

      {/* Buttons */}
      <div className='w-full flex flex-row items-center mt-3 justify-center gap-2'>
        <a
          className="hover:opacity-75 transition-opacity duration-600 text-center w-1/2 px-7 py-2 rounded-full border-2 "
          href=""
        >
          View
        </a>
        <a
          className="hover:opacity-75 transition-opacity duration-600 w-1/2 px-7 py-2 rounded-full text-center border-2 border-hostU-blue-400 bg-hostU-blue-400 text-white font-semibold"
          href=""
        >
          Connect
        </a>
      </div>
    </div>
  )
}

export default guestCard
