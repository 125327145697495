import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';

import { useAuth } from './AuthContext'

import { ImSpinner8 } from 'react-icons/im'
import MailGraphic from '@images/UndrawMail.svg'
import HouseDesign from '@images/UndrawHome.svg'
import Logo from '@components/logo'

function ForgotPassword() {

  // Get signup
  const { resetPassword } = useAuth();

  // Submitting
  const [submitting, setSubmitting] = useState(false);

  // User not found
  const [userNotFound, setUserNotFound] = useState(false);

  // One-time password
  const [otp, setOtp] = useState(nanoid());

  // Success
  const [success, setSuccess] = useState(false);

  const handleSubmit = (values) => {

    // Set submitting to true
    setSubmitting(true);

    // Create account
    resetPassword(values.email)
      .then(() => {
        // Signed in
        setTimeout(() => {
          setSuccess(true);
        }, 400);
        setUserNotFound(false);
      })
      .catch((error) => {
        console.error("Error", error.code, error.message);
        setSubmitting(false);
        if (error.code == "auth/user-not-found") {
          setUserNotFound(true);
        }
      });
  };

  return (
    <>

      <div className='w-screen h-screen flex flex-row'>

        {/* Left */}
        <div className="w-full lg:w-1/2 h-full p-10">

          {/* Logo */}
          <a className='absolute' href="/">
            <Logo textSize="text-2xl"
                  uColor="text-white"
                  bgWidth={9}/>
          </a>

          {/* Sign up content */}
          <div className='flex flex-col h-full justify-center lg:px-16 py-24 gap-2 items-center'>

            {!success ? (
              <>
                {/* Welcome Back */}
                <div className="relative flex justify-center p-2 text-center items-center">
                  <h2 className="font-medium text-3xl">Reset Password</h2>
                </div>

                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .email('Invalid email address')
                      .required('Required'),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    handleSubmit(values);
                  }}
                >
                  <Form>
                    {/* Inputs */}
                    <div className='sm:w-[600px] lg:max-w-[450px] pt-10 px-0 sm:px-16 md:px-20 lg:px-4 flex flex-col gap-6'>

                      {/* Email */}
                      <div className='w-full'>
                        <label htmlFor='email'>Email</label>
                        <div className="mb-2 relative border-b border-gray-400 border-opacity-60">
                          <Field name="email"
                                type="text"
                                className='w-full outline-none py-2'
                                placeholder='student@school.edu'
                                aria-label='Enter email'
                                />
                        </div>
                        <ErrorMessage name="email">{msg => <p className='text-red-500'>{msg}</p>}</ErrorMessage>
                      </div>

                      {userNotFound &&
                        <p className='text-red-500 w-full text-center'>User not found</p>
                      }

                      {/* Sign up */}
                      <button type="submit" className='w-full p-3 flex text-white flex-row items-center gap-2 justify-center text-center rounded-full hover:opacity-75 transition duration-100 bg-hostU-blue-gradient'>
                        <p className="font-semibold">Send Reset Email</p>
                        {submitting &&
                          <ImSpinner8 className="animate-submit-spin" />
                        }
                      </button>

                    </div>
                  </Form>
                </Formik>
                {/* Already have an account */}
                <div className='w-full text-center pt-4 mx-auto'>
                  <p>Remembered your password? <a href="/login" className="opacity-100 font-bold">Log in here</a></p>
                </div>
              </>
            ) : (
              <>
                <div className='w-max flex justify-center'>
                  <img src={MailGraphic} alt="" className='w-[10rem]'/>
                </div>

                {/* Welcome Back */}
                <div className="relative flex justify-center p-2 text-center items-center">
                  <h1 className="font-medium text-3xl">Email Sent</h1>
                </div>

                <div className='w-full text-center mx-auto'>
                  <p>Check your email to reset your password, then log in with your new password</p>
                </div>

                <a href="/login" className='py-4'>
                  <div className="transition duration-500 bg-hostU-blue-gradient text-white font-bold px-8 py-2 rounded-full">
                    <p>Log in</p>
                  </div>
                </a>
              </>
            )}

          </div>
        </div>

        {/* Right */}
        <div className="w-1/2 h-full lg:flex hidden lg:fixed right-0 items-center bg-hostU-blue-400">
          <div className="px-10 m-auto">
            <img src={HouseDesign} className="mx-auto w-[25rem]" alt="House" />
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
