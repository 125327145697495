import React, { useEffect, useState } from 'react'
import { useFormSteps } from '../../formStepsContext'

function RoomCounts(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data, setData } = useFormSteps();
  const { page } = props;

  const initialRoomDetails = [
    {
      id: 'roomsTotal',
      label: 'Bedrooms total',
      value: data.host.roomCounts.roomsTotal,
      step: 1,
    },
    {
      id: 'roomsToRent',
      label: 'Bedrooms available to rent',
      value: data.host.roomCounts.roomsToRent,
      step: 1,
    },
    {
      id: 'bathsTotal',
      label: 'Bathrooms',
      value: data.host.roomCounts.bathsTotal,
      step: 0.5,
    },
  ];

  const [roomDetails, setRoomDetails] = useState(initialRoomDetails);

  // If fetched from firestore, update it
  useEffect(() => {
    if (data.host.roomCounts) {
      setRoomDetails(initialRoomDetails);
    }
  }, [data]);

  // Re-enable buttons on component mount
  useEffect(() => {
    if (currentPage == page) {
      setCanGoBack(true);
      setCanGoNext(true);
    }
  }, []);

  // Update form data
  const updateData = (updatedRoomDetails) => {
    const newRoomCounts = updatedRoomDetails.reduce((acc, item) => {
      return { ...acc, [item.id]: item.value };
    }, {});

    setData({
      ...data,
      host: {
        ...data.host,
        roomCounts: newRoomCounts,
      },
    });
  };

  // Add 1 to value if + or subtract 1 to value if -
  const handleOperation = (id, operation) => {
    const updatedRoomDetails = roomDetails.map(item => {
      if (item.id === id) {
        let newValue = operation === '+' ? item.value + item.step : item.value - item.step;
        newValue = newValue < 0 ? 0 : newValue;

        // Ensure that roomsToRent <= roomsTotal
        if (id === 'roomsToRent' && newValue > roomDetails.find(room => room.id === 'roomsTotal').value) {
          return item;
        }
        if (id === 'roomsTotal' && newValue < roomDetails.find(room => room.id === 'roomsToRent').value) {
          return item;
        }

        return { ...item, value: newValue };
      }
      return item;
    });
    setRoomDetails(updatedRoomDetails);
    updateData(updatedRoomDetails);
  };

  // Render buttons
  const OperationButton = ({ id, operation }) => {
    return (
      <>
        <button
          className='rounded-full w-8 h-8 border border-hostU-blue-400'
          onClick={() => handleOperation(id, operation)}
        >
          <p className='pointer-events-none -translate-y-[1px]'>{operation}</p>
        </button>
      </>
    )
  }

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center sm:justify-center justify-start pt-[80px] sm:pt-0 pb-[700px] sm:pb-0'>
        <div className='w-full sm:w-[600px] pt-10 px-5 sm:px-0'>
          <div className='w-full text-left py-4'>
            <h2 className='text-[2rem] my-0 py-0 font-medium'>What about beds and baths?</h2>
            {/* <p className='opacity-50 pb-3'>You'll give us information on roommates later</p> */}
          </div>
          <div className='w-full flex flex-col text-xl'>
            {roomDetails.map(item => (
              <div key={item.id} className='flex flex-row justify-between py-10 border-b border-black border-opacity-10 w-full items-center'>
                <p>{item.label}</p>
                <div className='flex flex-row gap-2 items-center'>
                  <OperationButton
                    id={item.id}
                    operation="-"
                  />
                  <p className='w-8 text-center'>{item.value}</p>
                  <OperationButton
                    id={item.id}
                    operation="+"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default RoomCounts
