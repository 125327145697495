import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { useAuth } from './AuthContext'
import { doc, getDoc } from 'firebase/firestore'

import { ImSpinner8 } from 'react-icons/im'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import HouseDesign from '@images/UndrawHome.svg'
import Logo from '@components/logo'

function Login({ firestore }) {

  const [showPassword, setShowPassword] = useState(false);
  const { currentUser, getValidation, login } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [invalidCreds, setInvalidCreds] = useState(false);
  const [user, setUser] = useState(null);
  const [verified, setVerified] = useState(null);
  const [formComplete, setFormComplete] = useState(false);

  useEffect(() => {
    const getUserCreds = async () => {
      // See if pin matches
      const userRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();
      if (userData.hostFormComplete || userData.guestFormComplete) {
        setFormComplete(true);
        console.log("Completed");
      } else {
        console.log("Not completed");
      }

      setVerified(getValidation(currentUser.uid));
    }
    if (currentUser) {
      getUserCreds();
    }
  })

  useEffect(() => {
    async function checkValidation(uid) {
      const result = await getValidation(uid);
      setVerified(result);
    }

    if (currentUser) {
      checkValidation(currentUser.uid);
    }
  }, [currentUser]);

  const handleSubmit = async (values) => {

    // Set submitting to true
    setSubmitting(true);

    // Sign in with Firebase auth
    login(values.email, values.password)
    .then(async (userCredential) => {

        // See if pin matches
        const userRef = doc(firestore, "users", userCredential.user.uid);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();
        if (userData.hostFormComplete || userData.guestFormComplete) {
          setFormComplete(true);
          console.log("Completed");
        } else {
          console.log("Not completed");
        }

        // Signed in
        // See if user has validated their email
        setVerified(getValidation(userCredential.user.uid));

        setUser(userCredential.user);
        setInvalidCreds(false);
      })
      .catch((error) => {
        console.error("Error", error.code, error.message);
        setSubmitting(false);
        if (error.code) {
          setInvalidCreds(true);
        }
      });
  };

  return (
    <>

      {/* Navigate on log in after submit OR already logged in */}
      {((currentUser || user) && verified == true && formComplete) ? <Navigate to="/dashboard"/> : <></>}
      {((currentUser || user) && verified == true && !formComplete) ? <Navigate to="/setupaccount"/> : <></>}
      {((currentUser || user) && verified == false) ? <Navigate to="/verifyemail"/> : <></>}

      <div className='w-screen h-screen flex flex-row'>
        {/* Left */}
        <div className="w-full lg:w-1/2 h-full p-10">

          {/* Logo */}
          <a className='absolute' href="/">
            <Logo textSize="text-2xl"
                  uColor="text-white"
                  bgWidth={9}/>
          </a>

          {/* Login content */}
          <div className='w-full flex flex-col lg:px-16 py-24 pb-12 gap-2 items-center'>

            {/* Welcome Back */}
            <div className="relative flex justify-center p-2 text-center items-center">
              <h2 className="font-medium text-3xl">Welcome Back</h2>
            </div>

            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .required('Required'),
                password: Yup.string()
                  .required('Required')
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                handleSubmit(values);
              }}
            >
              <Form className="w-full flex justify-center">
                {/* Inputs */}
                <div className='w-full sm:w-[600px] lg:max-w-[450px] pt-10 px-4 sm:px-16 md:px-20 lg:px-4 flex flex-col gap-6'>

                  {/* Email */}
                  <div className='w-full'>
                    <label htmlFor='email'>Email</label>
                    <div className="mb-2 relative border-b border-gray-400 border-opacity-60">
                      <Field name="email"
                            type="text"
                            className='w-full outline-none py-2'
                            placeholder='student@school.edu'
                            aria-label='Enter email'
                            />
                    </div>
                    <ErrorMessage name="email">{msg => <p className='text-red-500'>{msg}</p>}</ErrorMessage>
                  </div>

                  {/* Password */}
                  <div className='w-full'>
                    <label htmlFor='password'>Password</label>
                    <div className="relative mb-2 border-b border-gray-400 border-opacity-60">
                      <Field name="password"
                            type={showPassword ? 'text' : 'password'}
                            className='w-full outline-none py-2'
                            placeholder='Password'
                            aria-label='Create Password'
                            aria-describedby='password-help'
                            />
                      <button
                        className='absolute top-0 right-0 h-full flex opacity-50 items-center mr-2 cursor-pointer'
                        onClick={() => setShowPassword(prevState => !prevState)}
                        aria-label='Toggle Password Visibility'
                        type="button"
                        >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                    <div className='w-full flex flex-row justify-between'>
                      <ErrorMessage name="password">{msg => <p className='text-red-500'>{msg}</p>}</ErrorMessage>
                      <div className='w-full flex justify-end'>
                        <a href="/forgotpassword" className='opacity-50'>Forgot password?</a>
                      </div>
                    </div>
                  </div>
                  {invalidCreds &&
                    <p className='text-red-500 w-full text-center'>Invalid username or password. Please try again.</p>
                  }

                  {/* Log in */}
                  <button type="submit" className={`w-full p-3 flex flex-row items-center gap-2 justify-center text-center text-white rounded-full ${submitting ? "opacity-75" : "hover:opacity-75"} transition duration-100 bg-hostU-blue-gradient`}>
                    <p className="font-semibold">Log in</p>
                    {submitting &&
                      <ImSpinner8 className="animate-submit-spin" />
                    }
                  </button>
                </div>
              </Form>
            </Formik>

            {/* Already have an account */}
            <div className='w-full text-center pt-4 mx-auto'>
              <p>Don't have an account? <a href="/signup" className="opacity-100 font-bold">Sign up here</a></p>
            </div>
          </div>
        </div>

        {/* Right */}
        <div className="w-1/2 h-full lg:flex hidden lg:fixed right-0 items-center bg-hostU-blue-400">
          <div className="px-10 m-auto">
            <img src={HouseDesign} className="mx-auto w-[25rem]" alt="House" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
