import React, { useState, useRef, useEffect } from 'react'
import Logo from '@components/logo'
import { AiOutlineCloseCircle } from "react-icons/ai"

const navigation = [
  { name: "Sign up", href: "/signup" },
  { name: "Log in", href: "/login" },
]

function Banner(props) {

  return (
    <div className="bg-yellow-500 text-white py-6">
      <div className='w-full mx-auto px-6 sm:px-10 md:px-16 lg:px-16 max-w-[1300px]'>
        <div className='flex flex-row items-center justify-between'>
          <p className='font-light'>April 10, 2023</p>
          <button onClick={props.onClick}>
            <AiOutlineCloseCircle className='w-6 h-6'/>
          </button>
        </div>
        <p className='font-bold text-2xl'>Important Announcement</p>
        <p className="font-base">Currently we are only serving Chicago university students for both hosts and guests. We'll be expanding soon!</p>
      </div>
    </div>
  );
}

function Navbar(props) {

  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  const [whiteBg, setWhiteBg] = useState(false);

  useEffect(() => {
    // Enable white BG after scrolling past a little bit
    function handleScroll() {
      if (window.scrollY > 1) {
        console.log(true);
        setWhiteBg(true);
      } else {
        console.log(false);
        setWhiteBg(false);
      }
    }
    window.addEventListener('scroll', handleScroll);

    // Disable hamburger menu once screen is large enough
    function handleResize() {
      if (window.innerWidth > 640) {
        setHamburgerMenu(false);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>

      <header className="z-50 fixed w-full">
        <div className='z-50'>

          {/* Mobile Hamburger - Inside popup */}
          {hamburgerMenu &&

            <div className="p-6 z-40 bg-white h-screen">
              {/* Logo */}
              <a href="/" className='flex w-max' tabIndex={1}>
                <Logo textSize="text-2xl"
                      uColor="text-white"
                      bgWidth={9}/>
              </a>
              <div className='border-t font-semibold mt-6 pt-6'>
                <div className='w-max'>
                  <div className='flex flex-col text-2xl gap-6'>
                    {navigation.map((link, index) => (
                      <div className=''>
                        <a href={link.href} tabIndex={index + 2}>
                          <p>{link.name}</p>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          }

          {/* Mobile Hamburger - 3 lines */}
          <nav className={"sm:hidden h-[70px] px-6 flex items-center justify-between"}>

            {/* Logo */}
            <a href="/" tabIndex={1}>
              <Logo textSize="text-2xl"
                    uColor="text-white"
                    bgWidth={9}/>
            </a>

            <div className={`is-${
              hamburgerMenu ? "active" : "inactive"
            } cursor-pointer burger-menu w-[30px] absolute top-0 mt-6 right-0 mr-6 z-50 block`}
            onClick={() => setHamburgerMenu(!hamburgerMenu)}
            tabIndex={2}>
              <div className='block w-full rounded-sm h-[5px] mt-[3px] transition bg-hostU-blue-gradient'></div>
              <div className='block w-full rounded-sm h-[5px] mt-[3px] transition bg-hostU-blue-gradient'></div>
              <div className='block w-full rounded-sm h-[5px] mt-[3px] transition bg-hostU-blue-gradient'></div>
            </div>
          </nav>

          {/* Desktop Navbar */}
          <nav className="justify-between hidden max-w-[1300px] mx-auto font-semibold sm:flex h-[70px] sm:px-10 md:px-16 lg:px-16 items-center">
            {/* Logo */}
            <a href="/" tabIndex={1}>
              <Logo textSize="text-2xl"
                    uColor="text-white"
                    bgWidth={9}/>
            </a>
            <div className='flex flex-row gap-2'>
              <a href="/login">
                <div className="transition hover:opacity-50 duration-500 font-bold px-4 py-2 rounded-lg">
                  <p>Log in</p>
                </div>
              </a>
              <a href="/signup">
                <div className="transition hover:opacity-50 duration-500 bg-hostU-blue-gradient text-white font-bold px-4 py-2 rounded-full">
                  <p>Sign up</p>
                </div>
              </a>
            </div>
          </nav>
        </div>

        {/* White BG */}
        <div className={`w-full h-full top-0 absolute z-[-1] ${whiteBg ? "opacity-100" : "opacity-0"} transition-opacity duration-200 shadow-hostU-shadow-figma bg-white`}></div>
      </header>
    </>
  )
}

export default Navbar
