import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Headshot1 from '@images/Testimonial1.png'
import Ty from '@images/TyBerry.png'
import Grad from '@images/GradStudent.png'
import Headshot4 from '@images/Testimonial4.png'
import Grace from '@images/Testimonial5.png'

import ArrowRight from '@images/arrow-right.svg'
import ArrowLeft from '@images/arrow-left.svg'
import Star from '@images/star.svg'
import Check from '@images/check.png'

const testimonials = [
  {
    "name": "Jess H.",
    "headshot": Headshot4,
    "school": "Northwestern '25",
    "reasonForUse": "Needed NYC housing for a summer internship",
    "testimonial": "I received a last-minute internship offer but didn’t want to pay two rents for campus and NYC housing. hostU helped me find a student subletter for my space on short notice and I got cash."
  },
  {
    "name": "Jack L.",
    "headshot": Grad,
    "school": "Grad at UChicago Booth '23",
    "reasonForUse": "Needed NYC housing for summer internship",
    "testimonial": "I didn’t know anyone in the city I was interning in last summer, my apartment found through hostU was in an ideal location to meet other university students and people my age."
  },
  {
    "name": "Ty B.",
    "headshot": Ty,
    "school": "Northwestern '24",
    "reasonForUse": "Needed Chicago housing for summer training",
    "testimonial": "As a student-athlete, hostU made it easy for me to find affordable housing during summer training."
  },
  {
  "name": "Colby B.",
    "headshot": Headshot1,
    "school": "Columbia '24",
    "reasonForUse": "Subleased while abroad",
    "testimonial": "I was the only one in my house studying abroad, but my housemates felt weird about a random person living with them. hostU helped me find a student who they felt comfortable with and ended up loving."
  },
  {
    "name": "Grace H.",
    "headshot": Grace,
    "school": "Northwestern '25",
    "reasonForUse": "Needed Boston housing for a summer internship",
    "testimonial": "Easiest and fastest summer housing process. hostU did all the work and found listings that matched my criteria."
  },
]

const CustomPrevArrow = props => {
  const { onClick } = props;
  return (
    <button className='absolute cursor-pointer bg-white z-40 top-[45%] mt-24 md:mt-0 left-0 rounded-full md:rounded-3xl w-[40px] md:w-[55px] h-[40px] flex justify-center items-center shadow-hostU-shadow'
         onClick={onClick}
         tabIndex={10}>
      <img src={ArrowLeft} alt="<--" className="h-[40%]"/>
    </button>
  )
};

const CustomNextArrow = props => {
  const { onClick } = props;
  return (
    <button className='absolute cursor-pointer bg-white z-40 top-[45%] mt-24 md:mt-0 rounded-full md:rounded-3xl right-0 w-[40px] md:w-[55px] h-[40px] flex justify-center items-center  shadow-hostU-shadow'
         onClick={onClick}
         tabIndex={11}>
      <img src={ArrowRight} alt="-->" fill="black" className="h-[40%]"/>
    </button>
  )
}

const CustomDots = props => {
  const { onClick, children } = props;
  return (
    <ul className='slick-dots' onClick={onClick} tabIndex={-1}>
      {children}
    </ul>
  );
};

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 5000,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  // accessibility: false,
  appendDots: dots => <CustomDots>{dots}</CustomDots>
};

function Testimonials() {
  return (
    <div className="testimonials w-full px-4 lg:px-[10rem] my-12 py-6 flex flex-col bg-white justify-center">

      {/* Header */}
      <div className="text-center flex flex-col mx-auto w-[80%] gap-1">
        <p className="bg-hostU-blue-gradient font-bold bg-clip-text text-transparent">Testimonials</p>
        <p className='font-bold text-3xl'>Don't just take our word for it</p>
        <p className='text-gray-500 text-lg'>Discover how we are changing the game in student housing</p>
      </div>

      {/* Slider */}
      <Slider {...sliderSettings} tabIndex={-1} className="w-full text-base max-w-[1200px] mx-auto pt-8 md:pt-4 pb-6 md:pb-16 relative">

        {/* Render each testimonial in JSON */}
        {testimonials.map(testimonial => (
          <div className="relative md:py-6 px-10 md:px-[5rem]" key={testimonial["name"]}>
            <div className="mx-auto p-4 max-w-[750px]">
              <div className="flex flex-col md:flex-row items-center pl-0 md:pl-4 pb-8 md:pb-2">

                {/* Headshot with Verification Check */}
                <div className="m-4">
                  <img src={testimonial["headshot"]} alt={testimonial["name"]} className="w-[3rem] h-[3rem] object-cover rounded-[50%]"/>
                  <img src={Check} alt="Verified" className="w-[1rem] absolute translate-x-8 -translate-y-4"/>
                </div>

                {/* Header Info */}
                <div className='text-center md:text-left'>
                  <h3 className='font-bold'>{testimonial["name"]}</h3>
                  <div className='flex flex-col md:flex-row text-[0.9rem] md:gap-2 text-gray-500'>
                    <p>{testimonial["school"]}</p>
                    <div className='hidden md:flex'>
                      <img src={Star} alt="&#x2022;" className="w-[8px]" />
                    </div>
                    <p>{testimonial["reasonForUse"]}</p>
                  </div>
                </div>
              </div>

              {/* Triangle */}
              <div className="shadow-hostU-shadow flex justify-center md:inline-block rounded-3xl">
                <div className="bg-white w-6 h-6 transform flex md:translate-x-11 -translate-y-2 md:-translate-y-1 absolute rotate-45"></div>

                {/* Testimonial */}
                <div className="z-0 relative bg-white py-6 px-6 md:py-4 rounded-3xl">

                  {/* Text */}
                  <p>"{testimonial["testimonial"]}"</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Testimonials
