import React, { useEffect, useState } from 'react'
import { useFormSteps } from '../../formStepsContext'

function Price(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data, setData } = useFormSteps();
  const { page } = props;

  const [numberOfBedrooms, setNumberOfBedrooms] = useState(data.host.roomCounts.roomsToRent);
  const moreThanOneBedroom = numberOfBedrooms > 1;

  const [prices, setPrices] = useState(data.host.prices);

  // Update prices when data.host.prices changes
  useEffect(() => {
    setPrices(data.host.prices);
  }, [data.host.prices]);

  // Update prices array when numberOfBedrooms changes
  useEffect(() => {
    if (prices.length != numberOfBedrooms) {
      const newPrices = Array(numberOfBedrooms).fill().map((_, i) => ({ roomNumber: i+1, price: '', duration: 'month' }));
      setPrices(newPrices);
      setCanGoNext(false);
    }
  }, [numberOfBedrooms]);

  useEffect(() => {
    // Re-enable the back button
    if (currentPage == page) {
      setCanGoBack(true);
    }
  });

  // If fetched from firestore, update it
  useEffect(() => {
    if (data.host.prices && prices.length == numberOfBedrooms) {
      // Validate form
      let filledCompletely = data.host.prices.every((roomInfo) => {
        return roomInfo.price !== '';
      })
      console.log("Setting canGoNext to", filledCompletely);
      setCanGoNext(filledCompletely);
    }

    // Update the number of bedrooms
    if (data.host.roomCounts) {
      setNumberOfBedrooms(data.host.roomCounts.roomsToRent);
    }
  }, [data]);

  // Handle input change
  const handleRoomDetailChange = (index, field, value) => {
    const updatedRoomDetails = [...prices];
    console.log(typeof value);
    if (value && !value.includes("m") && !value.includes("w")) {
      value = Math.max(0, value);
    }
    updatedRoomDetails[index][field] = value;

    setPrices(updatedRoomDetails);
    setData({
      ...data,
      host: {
        ...data.host,
        prices: updatedRoomDetails,
      }
    })
  };

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center justify-start sm:justify-center mt-[80px] sm:mt-0 sm:pt-0 mb-[300px]'>
        <div className='w-full sm:w-[600px] pt-10 px-5 sm:px-0'>
          <div className='w-full text-left py-8'>
            <h2 className='text-[2rem] my-0 py-0 font-medium'>How much are you asking for?</h2>
            {moreThanOneBedroom ? (
              <p className='opacity-50 pb-3'>Select a price for each room you are listing</p>
              ) : (
              <p className='opacity-50 pb-3'>Select a price for your room</p>
            )}
          </div>
          <div className='w-full flex flex-col items-center sm:justify-start gap-2 justify-center overflow-y-auto max-h-[calc(100vh-300px)]'>
            {prices.map((room, index) => (
              <div className='w-full flex flex-row items-center text-xl justify-between' key={index}>
                <h3>Bedroom {room.roomNumber}:</h3>

                {/* Price */}
                <div className='px-8 py-3 gap-4 bg-white border items-center flex flex-row rounded-full border-black border-opacity-20'>
                  <div className='flex flex-row items-center gap-2'>
                    <p>$</p>
                    <input
                      type="number"
                      placeholder="1,000"
                      className='outline-none w-20 text-center p-2 border-b'
                      value={room.price}
                      onChange={(e) => handleRoomDetailChange(index, 'price', e.target.value)}
                    />
                  </div>

                  <p>/</p>

                  {/* Duration dropdown */}
                  <div className='flex items-center'>
                    <select
                      value={room.duration}
                      onChange={(e) => handleRoomDetailChange(index, 'duration', e.target.value)}
                      className="outline-none focus:outline pr-4 text-center"
                    >
                      <option value="month">month</option>
                      <option value="whole stay">whole stay</option>
                    </select>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Price
