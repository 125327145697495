import React, { useState } from 'react';
import Slider from 'react-slick';
import ArrowLeft from '@images/arrow-left.svg'
import ArrowRight from '@images/arrow-right.svg'
import GoogleMapReact from 'google-map-react';
import Logo from '@components/logo';
import Footer from '@components/footer';

import { BsFillCalendarEventFill, BsFillBuildingFill, BsFillPeopleFill } from 'react-icons/bs';
import { BiWalk } from 'react-icons/bi';
import { AiFillCar, AiFillDollarCircle } from 'react-icons/ai';
import { GrBike } from 'react-icons/gr'
import { IoMdTrain } from 'react-icons/io'

function matchImages(props) {

  const { imageLinks, guestName, hostName, hostCoords, hostSelfie, guestSelfie, address, path, guestsData, hostsData } = props;

  const mapProps = {
    center: {
      lat: hostCoords._lat,
      lng: hostCoords._long,
    },
    zoom: 11
  };

  // Set the configuration options for the slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    waitForAnimate: false,
    afterChange: (currentSlide) => {
      setSlideNumber(currentSlide + 1);
    },
  };

  // Define the custom arrow components for the slider
  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <button className="opacity-0 listing-arrow transition z-50 ml-2 bg-white w-10 h-10 shadow-hostU-shadow-small rounded-full absolute flex items-center justify-center top-1/2 left-0 transform -translate-y-1/2" onClick={() => {
        onClick();

        // Get new slide
        const newSlide = (slideNumber - 1) % (imageLinks.length + 1)
        if (newSlide == 0) {
          setSlideNumber(imageLinks.length);
        } else {
          setSlideNumber(newSlide);
        }
      }}>
        <img src={ArrowLeft} alt="<--" className="h-[40%]"/>
      </button>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <button className="opacity-0 listing-arrow transition z-50 mr-2 bg-white w-10 h-10 shadow-hostU-shadow-small rounded-full absolute flex items-center justify-center top-1/2 right-0 transform -translate-y-1/2" onClick={() => {
          onClick();

          // Get new slide
          const newSlide = (slideNumber + 1) % (imageLinks.length + 1)
          if (newSlide == 0) {
            setSlideNumber(1);
          } else {
            setSlideNumber(newSlide);
          }
        }}>
        <img src={ArrowRight} alt="-->" className="h-[40%]"/>
      </button>
      );
    }

  const Pin = () => (
    <div style={{
      color: 'white',
      display: 'inline-flex',
      justifyContent: 'center',
      top: '0',
      borderRadius: '100%',
      transform: 'translate(-50%, -100%)'
    }}>
      <img src="https://upload.wikimedia.org/wikipedia/commons/d/d1/Google_Maps_pin.svg" alt="Pin"/>
    </div>
  );

  // Get price
  let hostPrice = null;
  if (hostsData.price) {
    hostPrice = hostsData.price > 0 ? hostsData.price.toLocaleString() : null;
  }

  // Get date range
  const startMonth = new Date(hostsData.startDate.toDate()).toLocaleString("default", {month: "short"});
  const startDay = new Date(hostsData.startDate.toDate()).getDate();
  const startYear = new Date(hostsData.startDate.toDate()).getFullYear();
  const endMonth = new Date(hostsData.endDate.toDate()).toLocaleString("default", {month: "short"});
  const endDay = new Date(hostsData.endDate.toDate()).getDate();
  const endYear = new Date(hostsData.endDate.toDate()).getFullYear();
  const dateRange = `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;

  const [slideNumber, setSlideNumber] = useState(1);

  // Return the collage with the images in a slider with buttons
  return (
    <>
      <div className='w-screen'>

        {/* Navbar */}
        <div className='fixed h-[70px] w-full bg-white px-4 py-2 flex items-center justify-between z-50 border-b'>
          <a href="https://old.joinhostu.com/">
            <Logo textSize="text-2xl"
                  uColor="text-white"
                  bgWidth={9}/>
          </a>
          <div className='flex items-center gap-4 text-center'>
            <p>{guestName}</p>
            <div className='rounded-full overflow-hidden'>
              <img src={guestSelfie} className="object-cover w-10 h-10" alt="Profile Pic"/>
            </div>
          </div>
        </div>

        <div className='w-full'>
          <div className='flex flex-col w-full md:flex-row items-center'>

            {/* Left Side: User Data */}
            <div className='w-full h-full mb-10 mt-[70px] md:w-1/3 my-auto justify-center items-center flex flex-col'>

              {/* Slider */}
              <div className='z-0 border-b relative match-list w-full'>
                <Slider {...settings} className='flex w-full listings-slider items-center justify-center'>
                  {/* Images */}
                  {imageLinks.slice(0, 6).map((link, index) => (
                    <>
                      <div key={index} className=''>
                        <img className="mx-auto w-full md:min-h-[10rem] object-cover max-h-[40vh] lg:max-h-[40vh]" src={link} alt={`Image ${index + 1}`} />
                      </div>
                    </>
                  ))}
                </Slider>

                {/* Image count */}
                <div className='absolute w-max m-3 right-0 rounded-xl z-50 bottom-0 bg-black py-1 px-3 text-white' style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                  <p className="right-0">{slideNumber} of {imageLinks.length}</p>
                </div>
              </div>

              {/* Body Content */}
              <div className='py-4 mx-4 w-full px-2'>

                {/* Header */}
                <div className='flex flex-row gap-4 w-full px-4 py-2 items-center'>

                  {/* Selfie */}
                  <div className='rounded-full w-[5rem] h-[5rem] shadow-hostU-shadow overflow-hidden max-w-[5rem] max-h-[5rem]'>
                    <img className="rounded-full object-cover w-[5rem] h-[5rem]" src={hostSelfie} alt=""/>
                  </div>

                  {/* Name and Address */}
                  <div className="py-4 w-max">
                    <div className="pt-1 flex flex-col leading-6">
                      <h2 className='text-lg md:text-2xl font-bold'><span className='text-hostU-blue-400'>{hostName.split(" ")[0]}'s</span> Listing</h2>
                      <p>{address.replace(/,\s*USA$/, "")}</p>
                    </div>
                  </div>
                </div>

                {/* Listing Info */}
                <div className='mx-4 py-2'>

                  {/* Dates */}
                  <div className='w-full px-4 py-2 flex items-center gap-3 flex-row'>
                    <div className='p-4 rounded-full w-max h-max bg-gray-100'>
                      <BsFillCalendarEventFill className='w-4 h-4'/>
                    </div>
                    {dateRange}
                  </div>

                  {/* Price */}
                  {hostPrice &&
                    <div className='w-full px-4 py-2 flex items-center gap-3 flex-row'>
                      <div className='p-4 rounded-full w-max h-max bg-gray-100'>
                        <AiFillDollarCircle className='w-4 h-4'/>
                      </div>
                      <p>$ {hostPrice}<span>{hostsData.flexible ? " - Flexible" : ""}</span></p>
                    </div>
                  }

                  {/* Roommattes */}
                  <div className='w-full px-4 py-2 flex items-center gap-3 flex-row'>
                    <div className='p-4 rounded-full w-max h-max bg-gray-100'>
                      <BsFillPeopleFill className='w-4 h-4'/>
                    </div>
                    <div className=''>
                      {hostsData.roommates == 'Yes' &&
                        <p>Accepting roommates</p>
                      }
                      {hostsData.roommates == 'Maybe' &&
                        <p>Might be accepting roommates</p>
                      }
                      {hostsData.roommates == 'No' &&
                        <p>Not accepting roommates</p>
                      }
                    </div>
                  </div>

                  {/* Additional */}
                  {hostsData.additional != '' &&
                    <>
                      <div className='w-full px-4 py-2 flex gap-3 flex-row'>
                        <div className='p-4 rounded-full w-max h-max bg-gray-100'>
                          <BsFillBuildingFill className='w-4 h-4'/>
                        </div>
                        <div className='mt-2'>
                            {hostsData.additional}
                        </div>
                      </div>
                    </>
                  }
                </div>

                {/* Transportation */}
                {/* <div className='mx-4 py-2'>
                  <h3 className='w-full font-bold text-xl p-4'>Transportation</h3>

                  <div className='w-full px-4 py-2 flex items-center gap-3 flex-row'>
                    <div className='p-4 rounded-full w-max h-max bg-gray-100'>
                      <AiFillCar className='w-4 h-4'/>
                    </div>
                    <p>18 mins by car</p>
                  </div>

                  <div className='w-full px-4 py-2 flex items-center gap-3 flex-row'>
                    <div className='p-4 rounded-full w-max h-max bg-gray-100'>
                      <IoMdTrain className='w-4 h-4'/>
                    </div>
                    <p>32 mins by train</p>
                  </div>

                  <div className='w-full px-4 py-2 flex items-center gap-3 flex-row'>
                    <div className='p-4 rounded-full w-max h-max bg-gray-100'>
                      <GrBike className='w-4 h-4'/>
                    </div>
                    <p>46 mins by bike</p>
                  </div>

                  <div className='w-full px-4 py-2 flex items-center gap-3 flex-row'>
                    <div className='p-4 rounded-full w-max h-max bg-gray-100'>
                      <BiWalk className='w-4 h-4'/>
                    </div>
                    <p>58 mins by walk</p>
                  </div>
                </div> */}
              </div>

              {/* Request Contact Info Button */}
              <a href={`${path}/request`} className='z-50 shadow-hostU-shadow-small fixed mx-auto bottom-0 text-white bg-hostU-blue-400 rounded-3xl my-4 font-bold w-max px-4 py-2'>
                Request Contact
              </a>
            </div>


            {/* Right Side: Map */}
            <div className='w-full md:w-2/3 md:fixed bottom-0 md:right-0 h-screen'>
              <GoogleMapReact
                defaultCenter={mapProps.center}
                defaultZoom={mapProps.zoom}
              >
                <Pin
                  lat={mapProps.center.lat}
                  lng={mapProps.center.lng}
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </>
    );
  }

    export default matchImages
