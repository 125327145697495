import React, { useEffect, useState, useMemo, useContext } from 'react';
import { firestore } from '../../index.js'
import { useAuth } from '../auth/AuthContext'
import { doc, getDoc } from 'firebase/firestore'

import HostMatches from './pages/host/matches.jsx'
import HostYourListing from './pages/host/yourListing.jsx'

import GuestMatches from './pages/guest/matches.jsx'
import GuestYourProfile from './pages/guest/yourProfile.jsx'

const DashboardContext = React.createContext()

export function useDashboard() {
  const context = useContext(DashboardContext);

  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }

  return context;
}

export function DashboardProvider({ children }) {

  const hostTabs = [
    { name: "Matches", icon: "fa-building-user", component: HostMatches },
    { name: "Your Listing", icon: "fa-user", component: HostYourListing },
  ]

  const guestTabs = [
    { name: "Matches", icon: "fa-building-user", component: GuestMatches },
    { name: "Your Profile", icon: "fa-user", component: GuestYourProfile },
  ];

  const { currentUser } = useAuth();
  const [readyToRender, setReadyToRender] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userType, setUserType] = useState(null);
  const [currentTab, setCurrentTab] = useState(null);

  const tabs = useMemo(() => {
    return userType == "Host" ? hostTabs : guestTabs;
  }, [userType]);

  // Get user data
  useEffect(() => {
    async function fetchUserData() {
      const userRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data()
      setCurrentTab(userData.hostFormComplete ? hostTabs[0] : guestTabs[0]);
      setUserData(userData);
      setUserType(userData.hostFormComplete ? "Host" : "Guest");
    }

    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  const [availableTypes, setAvailableTypes] = useState([]);

  useEffect(() => {
    const setInitialData = async () => {
      if (userData) {
        if (userData.hostFormComplete) {
          if (!availableTypes.includes("Host")) {
            setAvailableTypes(prevTypes => [...prevTypes, "Host"]);
          }
        }
        if (userData.guestFormComplete) {
          if (!availableTypes.includes("Guest")) {
            setAvailableTypes(prevTypes => [...prevTypes, "Guest"]);
          }
        }
      }
    }
    if (currentUser) {
      setInitialData();
    }
  }, [currentUser, userData]);

  // Check if everything's loaded and we're ready to render
  useEffect(() => {
    setReadyToRender(true);
    // if (userType) {
    //   setInterval(() => {
    //     setReadyToRender(true);
    //   }, 1000);
    // }
  }, [userType]);

  const value = {
    tabs,
    currentTab,
    setCurrentTab,
    hostTabs,
    guestTabs,
    userType,
    userData,
    setUserType,
    readyToRender,
    setReadyToRender,
    availableTypes,
  }

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  )
};
