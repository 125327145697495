import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { updateProfile } from 'firebase/auth';
import * as Yup from 'yup';

import { useAuth } from './AuthContext'
import { setDoc, doc } from "firebase/firestore";

import { ImSpinner8 } from 'react-icons/im'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import HouseDesign from '@images/UndrawHome.svg'
import Logo from '@components/logo'

function SignUp({firestore}) {

  // Variable to show password
  const [showCreatePassword, setShowCreatePassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { signup, currentUser, getValidation } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [cannotSubmit, setCannotSubmit] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const [user, setUser] = useState(null);
  const [verified, setVerified] = useState(null);

  const PhoneInputField = ({ field, form, ...rest }) => {
    const { setFieldValue } = form;

    const handleChange = (value) => {
      setFieldValue(field.name, value);
    };

    return (
      <PhoneInput
        {...rest}
        {...field}
        inputProps={{ name: field.name, required: true }}
        onChange={handleChange}
      />
    );
  };

  // Generate a random 6-digit pin
  const generatePin = () => {
    // Generate a random integer between 100000 and 999999
    const pin = Math.floor(Math.random() * 900000) + 100000;
    // Convert the integer to a string
    return pin.toString();
  }

  useEffect(() => {
    async function checkValidation(uid) {
      const result = await getValidation(uid);
      setVerified(result);
      console.log("Setting verified to", result);
    }

    if (currentUser) {
      checkValidation(currentUser.uid);
    }
  }, [currentUser]);

  const handleSubmit = (values) => {

    // Set submitting to true
    setSubmitting(true);

    // Create account
    signup(values.email, values.password)
      .then(async (userCredential) => {

        const pin = generatePin();

        // Add user UUID to Firestore collection
        setDoc(doc(firestore, "users", userCredential.user.uid), {
            name: {
              firstName: values.firstName,
              lastName: values.lastName,
            },
            school: values.school,
            gradYear: values.gradYear,
            phone: values.phone,
            email: userCredential.user.email,
            createdAt: new Date(),
            verificationPin: pin,
            verified: false,
            hostFormComplete: false,
            guestFormComplete: false,
            furthestPageHost: 1,
            furthestPageGuest: 1,
          })
          .catch((error) => {
            console.error("Could not add user to database. Try again later");
            console.error(error);
          });

        // Update user with values
        updateProfile(userCredential.user, {
          displayName: values.firstName + " " + values.lastName,
        }).catch((error) => {
          console.error(error);
        });

        // Signed in
        setUser(userCredential.user);
        setCannotSubmit(false);
        setEmailInUse(false);
      })
      .catch((error) => {
        console.error("Error", error.code, error.message);
        setSubmitting(false);
        if (error.code == "auth/email-already-in-use") {
          setEmailInUse(true);
        } else {
          setCannotSubmit(true);
        }
      });
  };

  return (
    <>

      {/* Navigate on log in after submit OR already logged in */}
      {((currentUser || user) && verified == false) ? <Navigate to="/verifyemail"/> : <></>}

      <div className='w-screen h-screen flex flex-row'>

        {/* Left */}
        <div className="w-full lg:w-1/2 h-full p-10">

          {/* Logo */}
          <a className='absolute' href="/">
            <Logo textSize="text-2xl"
                  uColor="text-white"
                  bgWidth={9}/>
          </a>

          {/* Sign up content */}
          <div className='flex flex-col lg:px-16 py-24 pb-12 gap-2 items-center'>

            {/* Welcome Back */}
            <div className="relative flex justify-center p-2 text-center items-center">
              <h2 className="font-medium text-3xl">Create Account</h2>
            </div>

            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                school: '',
                gradYear: '',
                phone: '',
                password: '',
                passwordConf: '',
                terms: false,
              }}
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .required('Required'),
                lastName: Yup.string()
                  .required('Required'),
                email: Yup.string()
                  .email('Invalid email address')
                  .matches(/\.edu$/, 'Email address must end with .edu')
                  .required('Required'),
                school: Yup.string()
                  .required('Required'),
                gradYear: Yup.number()
                  .required('Required')
                  .positive()
                  .integer()
                  .min(2023, 'Graduation year must later than 2022')
                  .max(2030, 'Graduation year cannot be later than 2030'),
                phone: Yup.string()
                  .required('Required')
                  .min(10, 'Phone number is too short'),
                password: Yup.string()
                  .required('Required')
                  .min(8, 'Password must be at least 8 characters long')
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
                  )
                  .notOneOf(['password', '123456', 'qwerty'], 'Password is too common'),
                passwordConf: Yup.string()
                  .required('Required')
                  .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                terms: Yup.boolean()
                  .oneOf([true], 'You must accept our terms to sign up'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                handleSubmit(values);
              }}
            >
              <Form>
                {/* Inputs */}
                <div className='sm:w-[600px] lg:max-w-[450px] pt-10 px-0 sm:px-16 md:px-20 lg:px-4 flex flex-col gap-6'>

                  {/* Name */}
                  <div className='w-full flex flex-row gap-2 justify-between'>

                    {/* First Name */}
                    <div className='w-1/2'>
                      <div className='mb-2 border-b border-gray-400 border-opacity-60'>
                        <label htmlFor='firstName'>First Name</label>
                        <Field name="firstName"
                              type="text"
                              className='w-full outline-none py-2'
                              placeholder="Jane"
                              aria-label='Enter first name'/>
                      </div>
                      <ErrorMessage name="firstName">{msg => <p className='text-red-500'>{msg}</p>}</ErrorMessage>
                    </div>

                    {/* Last Name */}
                    <div className='w-1/2'>
                      <div className='mb-2 border-b border-gray-400 border-opacity-60'>
                        <label htmlFor='lastName'>Last Name</label>
                        <Field name="lastName"
                              type="text"
                              className='w-full outline-none py-2'
                              placeholder="Doe"
                              aria-label='Enter last name'/>
                      </div>
                      <ErrorMessage name="lastName">{msg => <p className='text-red-500'>{msg}</p>}</ErrorMessage>
                    </div>
                  </div>

                  {/* Email */}
                  <div className='w-full'>
                    <label htmlFor='email'>Email</label>
                    <div className="mb-2 relative border-b border-gray-400 border-opacity-60">
                      <Field name="email"
                            type="text"
                            className='w-full outline-none py-2'
                            placeholder='student@school.edu'
                            aria-label='Enter email'
                            />
                    </div>
                    <ErrorMessage name="email">{msg => <p className='text-red-500'>{msg}</p>}</ErrorMessage>
                  </div>

                  {/* College */}
                  <div className='w-full'>
                    <label htmlFor='school'>College or University</label>
                    <div className="relative mb-2 border-b border-gray-400 border-opacity-60">
                      <Field name="school"
                            type="text"
                            className='w-full outline-none py-2'
                            placeholder='School'
                            aria-label='Enter college or university'
                            />

                    </div>
                    <ErrorMessage name="school">{msg => <p className='text-red-500'>{msg}</p>}</ErrorMessage>
                  </div>

                  {/* Graduation Year */}
                  <div className='w-full'>
                    <label htmlFor='gradYear'>Graduation Year</label>
                    <div className="relative mb-2 border-b border-gray-400 border-opacity-60">
                      <Field name="gradYear"
                            type="number"
                            className='w-full outline-none py-2'
                            placeholder='2024'
                            aria-label='Enter graduation year'
                            />
                    </div>
                    <ErrorMessage name="gradYear">{msg => <p className='text-red-500'>{msg}</p>}</ErrorMessage>
                  </div>

                  {/* Phone */}
                  <div className='w-full'>
                    <div className="relative mb-2 border-b border-gray-400 border-opacity-60">
                      <Field
                        name="phone"
                        component={PhoneInputField}
                        containerClass="phone-input-container"
                        country="us"
                        countryCodeEditable={false}
                        inputClass="form-control w-full outline-none py-2"
                        placeholder='(123) 456-7890'
                      />
                    </div>
                    <ErrorMessage name="phone">{msg => <p className='text-red-500'>{msg}</p>}</ErrorMessage>
                  </div>

                  {/* Create Password */}
                  <div className='w-full'>
                    <label htmlFor='password'>Create Password</label>
                    <div className="relative mb-2 border-b border-gray-400 border-opacity-60">
                      <Field name="password"
                            type={showCreatePassword ? 'text' : 'password'}
                            className='w-full outline-none py-2'
                            placeholder='Password'
                            aria-label='Create Password'
                            aria-describedby='password-help'
                            />
                      <button
                        className='absolute top-0 right-0 h-full flex opacity-50 items-center mr-2 cursor-pointer'
                        onClick={() => setShowCreatePassword(prevState => !prevState)}
                        aria-label='Toggle Password Visibility'
                        type="button"
                        >
                        {showCreatePassword ? <FaEye /> : <FaEyeSlash />}
                      </button>

                      {/* Screen reader instructions */}
                      <div id='password-help' className='sr-only'>
                        Use at least 8 characters, including uppercase and lowercase letters, numbers, and symbols.
                      </div>
                    </div>
                    <ErrorMessage name="password">{msg => <p className='text-red-500'>{msg}</p>}</ErrorMessage>
                  </div>

                  {/* Confirm Password */}
                  <div className='w-full'>
                    <label htmlFor='passwordConf'>Confirm Password</label>
                    <div className="relative mb-2 border-b border-gray-400 border-opacity-60">
                      <Field name="passwordConf"
                            type={showConfirmPassword ? 'text' : 'password'}
                            className='w-full outline-none py-2'
                            placeholder='Password'
                            aria-label='Confirm Password'
                            />
                      <button
                        className='absolute top-0 right-0 h-full flex opacity-50 items-center mr-2 cursor-pointer'
                        onClick={() => setShowConfirmPassword(prevState => !prevState)}
                        type="button"
                        >
                        {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                    <ErrorMessage name="passwordConf">{msg => <p className='text-red-500'>{msg}</p>}</ErrorMessage>
                  </div>

                  {/* Terms of service checkbox */}
                  <div className='flex flex-col'>
                    <div className="flex flex-row items-center justify-center gap-2">
                      <Field name="terms"
                            type="checkbox"
                            className="w-4 h-4 text-hostU-blue-400 checked:bg-hostU-blue-400"
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  event.currentTarget.click();
                                }
                              }}/>
                      <label htmlFor='terms'>I agree to hostU's <a target="_blank" href="/terms" className='text-hostU-blue-400'>Terms of Service</a></label>
                    </div>
                    <ErrorMessage name="terms">{msg => <p className='w-full text-center text-red-500'>{msg}</p>}</ErrorMessage>
                  </div>
                  {cannotSubmit &&
                    <p className='w-full text-center text-red-500'>Cannot submit. Try again later</p>
                  }
                  {emailInUse &&
                    <p className='w-full text-center text-red-500'>It seems like you're already signed up</p>
                  }

                  {/* Sign up */}
                  <button type="submit" className='w-full p-3 flex text-white flex-row items-center gap-2 justify-center text-center rounded-full hover:opacity-75 transition duration-100 bg-hostU-blue-gradient'>
                    <p className="font-semibold">Sign up</p>
                    {submitting &&
                      <ImSpinner8 className="animate-submit-spin" />
                    }
                  </button>
                </div>
              </Form>
            </Formik>

            {/* Already have an account */}
            <div className='w-full text-center pt-4 mx-auto'>
              <p>Already have an account? <a href="/login" className="opacity-100 font-bold">Log in here</a></p>
            </div>
          </div>
        </div>

        {/* Right */}
        <div className="w-1/2 h-full lg:flex hidden lg:fixed right-0 items-center bg-hostU-blue-400">
          <div className="px-10 m-auto">
            <img src={HouseDesign} className="mx-auto w-[25rem]" alt="House" />
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUp
