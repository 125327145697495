import React, { useEffect } from 'react'
import Bella from '@images/BellaIcon.svg'
import Alexis from '@images/AlexisIcon.svg'
import Check from '@images/check.png'
import { motion } from 'framer-motion'
import LandingNavbar from '@components/landing/navbar'
import Graphic from '@images/HeroGraphic.svg'
// import Confetti from 'react-confetti'

function Hero(props) {

  useEffect(() => {
    const setHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--adjusted-height', `${vh * 100}px`);
    };

    setHeight();
    window.addEventListener('resize', setHeight);

    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  return (
    <section className="w-full bg-white bg-cover bg-bottom flex justify-center flex-col bg-no-repeat h-screen min-h-screen max-h-screen">

      <div className='top-0 absolute w-full'>
        <img src={Graphic} className="w-[250px] pointer-events-none lg:w-[30vw] lg:-translate-y-[50px] absolute" alt=""/>
        <LandingNavbar/>
      </div>

      <div className='flex items-center justify-center'>

        <div className="px-2 md:px-10 z-10 relative w-[1350px] items-center flex flex-wrap">

          {/* Text */}
          <div className="w-full lg:w-3/5 px-4 sm:px-8 lg:px-12 relative h-max">

            {/* Heading */}
            <motion.div className="lg:w-full flex font-bold flex-col pb-3 text-3xl sm:text-5xl lg:text-6xl"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}>
              <h1>The Curated Rental Platform for Student Housing in Chicago</h1>
            </motion.div>

            {/* Subheading */}
            <motion.div className='w-full flex flex-col text-gray-500 pb-6 text-lg'
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.25 }}>
              <h2>Rent your place or find your ideal sublease, and join the hostU network of verified university students.</h2>
            </motion.div>

            {/* Get Started Button */}
            <motion.button onClick={props.scrollToSection}
                          className="hover:opacity-80 inline-block bottom-0"
                          tabIndex={5}
                          initial={{ opacity: 0, y: 50 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.5, delay: 0.3 }}>
              <div className="transition duration-500 hostU-rotating-gradient text-white font-bold px-4 py-2 rounded-full">
                <p>Get Started</p>
              </div>
            </motion.button>
          </div>

          {/* Match Graphic */}
          <div className="lg:w-2/5 hidden lg:flex px-12 min-w-[210px] justify-center items-center">
            <div className="w-full rounded-3xl  p-6">
              <div className="p-2">

                {/* Top Person */}
                <div className="px-4 pb-2 relative w-full flex justify-end">
                  <div className="flex flex-row gap-4 relative w-max text-right">

                    {/* Info */}
                    <div className='flex items-center text-gray-500'>
                      <p>Summer Internship</p>
                    </div>

                    {/* Headshot */}
                    <div>
                      <img src={Bella} alt="Headshot" className="w-[50px] h-[50px] bg-gray-200 shadow-hostU-shadow-small rounded-full"/>
                      <img src={Check} className="absolute translate-x-8 -translate-y-4 w-[20px] h-[20px]" alt="Verified"/>
                    </div>
                  </div>
                </div>

                {/* It's a match! */}
                <div className="rounded-2xl w-full h-max shadow-hostU-shadow-small bg-white px-6 py-7 my-4 text-center">

                  {/* Dollar & Arrow */}
                  <div className='-translate-y-12'>
                    <div className="bg-hostU-green-gradient w-[5rem] ghost-float-top gap-1 flex items-center justify-evenly inline-block absolute px-4 py-[.6rem] shadow-hostU-shadow rounded-2xl">

                      {/* Dollar */}
                      <svg height="15" viewBox="0 0 327 201" fill="none" transform="rotate(-20)" xmlns="http://www.w3.org/2000/svg">
                        <path d="M290.276 0.743896H36.7243C16.6872 0.743896 0.294434 17.1367 0.294434 37.1738V164.314C0.294434 184.351 16.6872 200.744 36.7243 200.744H290.276C310.313 200.744 326.706 184.351 326.706 164.314V37.1738C326.706 16.7737 310.313 0.743896 290.276 0.743896ZM297.562 141.363C281.169 142.819 268.055 155.57 265.14 171.599H61.8609C58.9462 155.57 45.8311 143.184 29.4383 141.363V59.7596C45.8311 58.3036 58.9459 45.552 61.8609 29.5228H268.054C270.605 44.8234 282.625 56.4816 297.928 59.3966L297.925 141.364L297.562 141.363Z" fill="white"/>
                        <path d="M84.4476 61.9456C84.4476 68.987 78.7402 74.6948 71.6984 74.6948C64.6566 74.6948 58.9467 68.9874 58.9467 61.9456C58.9467 54.9038 64.6566 49.1938 71.6984 49.1938C78.7402 49.1938 84.4476 54.9038 84.4476 61.9456Z" fill="white"/>
                        <path d="M268.052 139.177C268.052 146.218 262.342 151.928 255.3 151.928C248.258 151.928 242.551 146.218 242.551 139.177C242.551 132.135 248.258 126.427 255.3 126.427C262.342 126.427 268.052 132.135 268.052 139.177Z" fill="white"/>
                        <path d="M163.502 38.6306C147.109 38.6306 131.443 45.1867 119.787 56.8456C108.128 68.5044 101.572 84.168 101.572 100.561C101.572 116.954 108.128 132.62 119.787 144.276C131.446 155.935 147.109 162.491 163.502 162.491C179.895 162.491 195.561 155.935 207.217 144.276C218.876 132.617 225.432 116.954 225.432 100.561C225.432 84.168 218.876 68.5018 207.217 56.8456C195.558 45.1867 179.895 38.6306 163.502 38.6306Z" fill="white"/>
                      </svg>

                      {/* Arrow */}
                      <svg height="18" viewBox="0 0 581 581" fill="current" xmlns="http://www.w3.org/2000/svg">
                        <path d="M290.547 508.375C296.92 508.124 304.288 505.473 308.932 501.059L460.461 357.008C469.146 347.249 472.728 328.528 462.687 317.62C452.795 306.875 433.62 307.195 423.692 317.896L317.288 419.18V99.6355C317.288 84.7207 305.313 72.625 290.546 72.625C275.78 72.625 263.805 84.7202 263.805 99.6355V419.18L157.401 317.896C148.262 308.677 128.403 306.975 118.406 317.62C108.403 328.258 111.598 347.645 120.638 357.008L272.167 501.059C277.352 505.988 283.434 508.387 290.547 508.375Z" fill="white" stroke="white"/>
                      </svg>
                    </div>
                  </div>

                  {/* Text */}
                  <p className="font-bold text-3xl">it's a match!</p>

                  {/* House & Arrow */}
                  <div className='translate-y-3'>
                    <div className="bg-hostU-blue-gradient right-0 ghost-float-bottom gap-1 w-[5rem] flex items-center justify-evenly inline-block absolute px-4 py-[.6rem] shadow-hostU-shadow rounded-2xl">

                      {/* House */}
                      <svg height="15" viewBox="0 0 209 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M204.996 97.5342L185.198 77.4175V17.7732C185.198 16.2132 184.588 14.717 183.502 13.6139C182.417 12.5108 180.944 11.8911 179.409 11.8911H156.253C154.718 11.8911 153.245 12.5108 152.16 13.6139C151.074 14.717 150.464 16.2132 150.464 17.7732V42.2427L112.373 3.42093C110.204 1.22985 107.269 0 104.21 0C101.152 0 98.2173 1.22985 96.0481 3.42093L3.42483 97.5342C1.79904 99.1726 0.6879 101.264 0.231903 103.544C-0.224095 105.824 -0.00446843 108.19 0.863013 110.344C1.73049 112.497 3.20687 114.34 5.10546 115.641C7.00404 116.942 9.23957 117.641 11.5294 117.651H23.1073V188.236C23.1073 191.356 24.3271 194.348 26.4984 196.554C28.6696 198.761 31.6145 200 34.6852 200H75.2078C76.7432 200 78.2156 199.38 79.3012 198.277C80.3869 197.174 80.9968 195.678 80.9968 194.118V135.297C80.9968 133.737 81.6067 132.241 82.6923 131.138C83.778 130.035 85.2504 129.415 86.7857 129.415H121.519C123.055 129.415 124.527 130.035 125.613 131.138C126.698 132.241 127.308 133.737 127.308 135.297V194.118C127.308 195.678 127.918 197.174 129.004 198.277C130.09 199.38 131.562 200 133.097 200H173.62C176.691 200 179.636 198.761 181.807 196.554C183.978 194.348 185.198 191.356 185.198 188.236V117.651H196.776C199.075 117.664 201.327 116.982 203.243 115.69C205.16 114.398 206.654 112.556 207.536 110.398C208.418 108.24 208.647 105.864 208.195 103.573C207.743 101.282 206.629 99.1796 204.996 97.5342Z" fill="white"/>
                      </svg>

                      {/* Arrow */}
                      <svg height="18" viewBox="0 0 581 581" fill="current" xmlns="http://www.w3.org/2000/svg">
                        <path d="M290.453 72.625C284.08 72.8762 276.712 75.5265 272.068 79.9413L120.539 223.992C111.854 233.751 108.272 252.472 118.313 263.38C128.205 274.125 147.38 273.805 157.308 263.104L263.712 161.82V481.364C263.712 496.279 275.687 508.375 290.454 508.375C305.22 508.375 317.195 496.28 317.195 481.364V161.82L423.599 263.104C432.738 272.323 452.597 274.025 462.594 263.38C472.597 252.742 469.402 233.355 460.362 223.992L308.833 79.9413C303.648 75.0116 297.566 72.6125 290.453 72.625Z" fill="white" stroke="white"/>
                      </svg>
                    </div>
                  </div>
                </div>

                {/* Bottom Person */}
                <div className="px-4 pt-2">
                  <div className="flex flex-row gap-4">

                    {/* Headshot */}
                    <div>
                      <img src={Alexis} alt="Headshot" className="w-[50px] bg-gray-200 shadow-hostU-shadow-small h-[50px] rounded-full"/>
                      <img src={Check} className="absolute translate-x-8 -translate-y-4 w-[20px] h-[20px]" alt="Verified"/>
                    </div>

                    {/* Info */}
                    <div className='flex items-center text-gray-500'>
                      <p>Wants to Sublease</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="gradient-overlay absolute z-0 bottom-0 w-full bg-gradient-to-b from-transparent h-1/3 to-gray-100"></div> */}
    </section>
  )
}

export default Hero
