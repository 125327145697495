import React, { useState, useEffect } from 'react'
import { useDashboard } from '../../DashboardContext'
import { useAuth } from '../../../auth/AuthContext'

function Matches() {

  // Get current user and logout from auth
  const { currentUser } = useAuth();

  return (
    <>
      <div className='w-max'>
        <h2 className='font-bold text-3xl'>Matches</h2>
        <p className='opacity-50'>Listings curated for you</p>
      </div>
    </>
  )
}

export default Matches
