import React from 'react'

import Cash from '@images/cash.png'
import Search from '@images/search.png'
import Privacy from '@images/privacy.png'
import GradCap from '@images/grad-cap.png'

const BenefitsInfo = [
  {
    "graphic": Search,
    "title": "Curated",
    "desc": "Skip the search. Get the work done for you."
  },
  {
    "graphic": Privacy,
    "title": "Private",
    "desc": "Only matches can see your information."
  },
  {
    "graphic": Cash,
    "title": "Easy Cash",
    "desc": "Don’t let your space go to waste."
  },
  {
    "graphic": GradCap,
    "title": "Your Peers",
    "desc": "Together, students beat the broker!"
  }
]

function benefits() {
  return (
    <div className="w-full mt-12 items-center md:mt-20 mb-20 px-2 flex justify-center">
      <div className="w-full max-w-[1200px] flex flex-wrap items-center justify-evenly">

        {/* Curated */}
        {Object.keys(BenefitsInfo).map((benefitId) => (
          <div key={benefitId} className="flex justify-center items-center flex-col text-center w-1/2 md:w-[175px]">
            <div className="flex justify-center items-center flex-col w-3/4 md:w-full">
              {/* Graphic */}
              <div className="hover:scale-110 transition duration-500">
                <img className="w-[50px] mb-4" src={BenefitsInfo[benefitId]["graphic"]} alt=""/>
              </div>

              {/* Title */}
              <p className="font-bold text-xl">{BenefitsInfo[benefitId]["title"]}</p>

              {/* Description */}
              <p className="text-gray-500">{BenefitsInfo[benefitId]["desc"]}</p>
            </div>
          </div>
        ))}

      </div>
    </div>
  )
}

export default benefits
