import React, { useEffect, useState } from 'react'
import { useFormSteps } from '../../formStepsContext'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

function When(props) {
  const { setCanGoNext, setCanGoBack, currentPage, data, setData } = useFormSteps();
  const { page } = props;

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    if (currentPage == page) {
      setCanGoBack(true);

      const today = dayjs().startOf('day')

      if (startDate == '' && endDate == '') {
        setCanGoNext(false);
      } else if (startDate != '' && endDate != '') {
        // Validate the dates here
        if (startDate.isAfter(endDate) || startDate.isBefore(today) || endDate.isBefore(today) || startDate.isSame(endDate)) {
          setCanGoNext(false); // Set to false if endDate is earlier than startDate, or either date is before today
        } else {
          setCanGoNext(true);
        }
      }
    }
  });

  useEffect(() => {
    if (data.host.dates.start) {
      if (data.host.dates.start instanceof Date) {
        setStartDate(dayjs(data.host.dates.start));
      } else {
        setStartDate(dayjs(new Date(data.host.dates.start.toDate())));
      }
    }
    if (data.host.dates.end) {
      if (data.host.dates.end instanceof Date) {
        setEndDate(dayjs(data.host.dates.end));
      } else {
        setEndDate(dayjs(new Date(data.host.dates.end.toDate())));
      }
    }
  }, [data]);

  const handleChange = (date, timeScope) => {
    let parsedDate = dayjs(date); // Parse the date using dayjs

    if (timeScope == "start") {
      setStartDate(date)
      setData({
        ...data,
        host: {
          ...data.host,
          dates: {
            ...data.host.dates,
            start: parsedDate.toDate(), // Convert the parsed date back to a JavaScript Date
          }
        },
      });
    } else {
      setEndDate(date)
      setData({
        ...data,
        host: {
          ...data.host,
          dates : {
            ...data.host.dates,
            end: parsedDate.toDate(),
          }
        },
      });
    }
  };

  return (
    <>
      <div className='w-screen h-screen flex flex-col items-center sm:justify-center justify-start pt-[80px] sm:pt-0 mb-[300px]'>
        <div className='w-full sm:w-[600px] pt-10 px-5 sm:px-0'>
          <div className='w-full text-center py-6'>
            <h2 className='text-[2rem] my-0 py-0 font-medium'>When are you hosting your place for?</h2>
            {/* <p className='opacity-50 pb-3'>You'll give us information on roommates later</p> */}
          </div>
          <div className='w-full flex flex-col sm:flex-row gap-4 items-center justify-center'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className='flex flex-col gap-2'>
                <p>Available starting</p>
                <DatePicker
                  type="text"
                  value={startDate}
                  onChange={(customDate) => handleChange(customDate, "start")}
                  name="startDate"
                  autoComplete='new-password'
                  error={null}
                  className='outline-none border-none'
                  minDate={dayjs()}
                  maxDate={endDate ? endDate : undefined}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "white",
                      border: "none",
                      color: "#9da3ae",
                      outline: "none",
                      borderRadius: "99rem",
                      width: "100%",
                    },
                    "& .MuiInputBase-input": {
                      color: (theme) => theme.palette.text.primary,
                      padding: "10px 10px 10px 20px",
                      fontFamily: "Circular Std, Arial, sans-serif",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#4BB6E3",
                    },
                    "& .Mui-selected:focus, \
                    & .Mui-selected:hover": {
                      backgroundColor: "#4BB6E3",
                    },
                  }}
                />
              </div>
              <div className='hidden sm:flex flex-col gap-2'>
                <p className='opacity-0 pointer-events-none'>-</p>
                <p>-</p>
              </div>
              <div className='flex flex-col gap-2'>
                <p>Available until</p>
                <DatePicker
                  type="text"
                  value={endDate}
                  onChange={(customDate) => handleChange(customDate, "end")}
                  name="endDate"
                  autoComplete='new-password'
                  error={null}
                  className='outline-none border-none'
                  minDate={startDate ? startDate : dayjs()}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "white",
                      border: "none",
                      color: "#9da3ae",
                      outline: "none",
                      borderRadius: "99rem",
                      width: "100%",
                    },
                    "& .MuiInputBase-input": {
                      color: (theme) => theme.palette.text.primary,
                      padding: "10px 10px 10px 20px",
                      fontFamily: "Circular Std, Arial, sans-serif",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#4BB6E3",
                    },
                    "& .Mui-selected:focus, \
                    & .Mui-selected:hover": {
                      backgroundColor: "#4BB6E3",
                    },
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </>
  );
}

export default When;
